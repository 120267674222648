import { ModeEditOutlineOutlined, Star, StarBorder } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import PaymentsIcon from '@mui/icons-material/CreditCard';
import VariablesIcon from '@mui/icons-material/DataObject';
import MemberIcon from '@mui/icons-material/GroupOutlined';
import UnitsIcon from '@mui/icons-material/HomeWorkOutlined';
import GuestIcon from '@mui/icons-material/HotelOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import AccountIcon from '@mui/icons-material/PersonOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import IntegratonsIcon from '@mui/icons-material/SettingsEthernetOutlined';
import GuestPortalIcon from '@mui/icons-material/VibrationOutlined';
import { Box, Button, IconButton, TextField } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { useMedia } from '@operto/ui';
import PageTitleBar from 'Common/TopBar/PageTitleBar';
import { patchProperty, patchPropertyFavourite } from 'property/state/propertyActions';
import { getProperty, isFavouriteProperty } from 'property/state/propertySelectors';
import * as React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Actions } from 'types/actions';
import { SlidebarType } from 'types/ui';
import HeaderBreadCrumbs from 'ui-library/Components/breadcrumbs/HeaderBreadCrumbs';
import { truncateCharacters } from 'ui-library/Helpers/textHelper';
import { IUser } from 'user/userTypes';
import { useTopBarStyles } from './useTopBarStyles';

export interface OpertoPageTitleBarProps {
  drawerWidth: number;
  open: boolean;
  toggle: () => void;
  children?: React.ReactNode;
  user: IUser;
}

type BreadCrumbArrayType = { name: string; href?: string; hide?: boolean }[];

const OpertoPageTitleBar = () => {
  const [editNameValue, setEditNameValue] = useState('');

  // contains curosr location to keep the cursor in the textfield. can be fixed with multiple layer
  // components but adds unnecessary complexity
  const [editNameCursor, setEditNameCursor] = useState<null | number>(null);

  const [isEditingUnit, setIsEditingUnit] = useState(false);

  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const pathArray = pathname.split('/');

  const { isDesktop, isMobile } = useMedia();
  const {
    mainHeaderIconStyles,
    headerContainerStyles,
    breadCrumbStyles,
    unitNameContainerStyles,
    headerTitleStyles,
  } = useTopBarStyles();

  const id: number | null =
    pathArray.length > 2 && pathArray[1] === 'units' ? parseInt(pathArray[2]) : null;
  const property = useAppSelector(getProperty(id));
  const isFavourite = useAppSelector(isFavouriteProperty(id));

  const saveEdit = () => {
    if (property?.id) {
      property.name = editNameValue;
      dispatch(patchProperty(id, { name: editNameValue }));
      dispatch({
        type: Actions.updateProperty,
        property,
      });
    }

    setIsEditingUnit(false);
  };

  const UnitEditFunction = () => {
    const propertyName = property?.name || id;
    if (isEditingUnit)
      return (
        <>
          <TextField
            sx={{ width: 'auto' }}
            size='small'
            value={editNameValue}
            onChange={handleChangeEvent}
            autoFocus
            onFocus={e => {
              e.target.selectionStart = editNameCursor;
              e.target.selectionEnd = editNameCursor;
            }}
            variant='standard'
            inputProps={{ sx: headerTitleStyles }}
          />
        </>
      );
    return isMobile ? `${truncateCharacters(propertyName as string, 10)}` : propertyName;
  };

  const handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditNameValue(e.currentTarget.value);
    setEditNameCursor(e.currentTarget.selectionStart);
  };

  const topBarText = () => {
    const breadCrumbsArray = [{ name: 'Home', href: '/' }] as BreadCrumbArrayType;
    if (pathArray.length > 0) {
      if (pathArray[1] === 'members') {
        breadCrumbsArray.push({ name: 'Members' });
      } else if (pathArray[1] === 'teams') {
        breadCrumbsArray.push({ name: 'Teams' });
      } else if (pathArray[1] === 'account') {
        breadCrumbsArray.push({ name: 'Account' });
      } else if (pathArray[1] === 'settings') {
        breadCrumbsArray.push({ name: 'Settings' });
        if (pathArray[2] === 'integrations') {
          breadCrumbsArray.push({ name: 'Integrations' });
        } else if (pathArray[2] === 'variables') {
          breadCrumbsArray.push({ name: 'Variables' });
        } else if (pathArray[2] === 'account') {
          breadCrumbsArray.push({ name: 'Account' });
        }
      } else if (pathArray[1] === 'messenger') {
        breadCrumbsArray.push({ name: 'Messenger' });
      } else if (pathArray[1] === 'guests') {
        breadCrumbsArray.push({ name: 'Guests' });
      } else if (pathArray[1] === 'guest-portal') {
        breadCrumbsArray.push({ name: 'Guest Portal', href: '/guest-portal' });
        if (pathArray.length > 1) {
          if (pathArray[2] === 'featured') {
            breadCrumbsArray.push({ name: 'Featured' });
          } else if (pathArray[2] === 'guides') {
            breadCrumbsArray.push({ name: 'Guides' });
          } else if (pathArray[2] === 'content') {
            breadCrumbsArray.push({ name: 'Content', href: '/guest-portal/content' });
            if (pathArray.length > 2) {
              if (pathArray[3] === 'global-text') {
                breadCrumbsArray.push({ name: 'Global Text' });
              } else if (pathArray[3] === 'branding') {
                breadCrumbsArray.push({ name: 'Branding' });
              } else if (pathArray[3] === 'address') {
                breadCrumbsArray.push({ name: 'Address' });
              } else if (pathArray[3] === 'host-details') {
                breadCrumbsArray.push({ name: 'Host Details' });
              }
            }
          } else if (pathArray[2] === 'settings') {
            breadCrumbsArray.push({ name: 'Settings' });
          }
        }
      } else if (pathArray[1] === 'expansion') {
        breadCrumbsArray.push({ name: 'Service Expansion' });
      }
    }
    return breadCrumbsArray;
  };

  const toggleFavorite = () => {
    if (id) {
      dispatch(patchPropertyFavourite(id, { status: !isFavourite }));
    }
  };

  const UnitContent = () => {
    const navigate = useNavigate();
    let isEditable = false;
    let showChevronIcon = false;

    const breadCrumbsArray = [{ name: 'Home', href: '/' }] as BreadCrumbArrayType;

    if (pathArray.length > 1 && pathArray[1] === 'units') {
      // we are on units page or children of units page
      breadCrumbsArray.push({ name: 'Units', href: '/units' });

      if (pathArray.length > 2) {
        isEditable = true;

        if (isMobile) {
          // Empty the showed items
          breadCrumbsArray[0].hide = true;
          breadCrumbsArray[1].hide = true;
          showChevronIcon = true;
        }
        if (pathArray[2] === 'favorites') {
          // Second layer should be here:
          breadCrumbsArray.push({ name: 'Favorites' });
          isEditable = false;
        }
      }
    } else if (pathArray.length > 1 && pathArray[1] === 'groups') {
      breadCrumbsArray.push({ name: 'Groups', href: '/groups' });
    } else if (pathArray.length > 1 && pathArray[1] === 'locations') {
      //  we are on locations page
      breadCrumbsArray.push({ name: 'Locations', href: '/locations' });
    } else if (pathArray.length > 1 && pathArray[1] === 'saltogroups') {
      //  we are on salto groups page
      breadCrumbsArray.push({ name: 'Salto Groups', href: '/saltogroups' });
    } else if (pathArray.length > 1 && pathArray[1] === 'room-types') {
      // We landed on room types
      breadCrumbsArray.push({ name: 'Room Types', href: '/room-types' });

      if (pathArray.length > 2 && pathArray[1] === 'room-types') {
        // we landed on one child below room types
        breadCrumbsArray.push({ name: pathArray[2] });
      }
    }

    return (
      <>
        {!showChevronIcon && (
          <Box sx={mainHeaderIconStyles}>
            <UnitsIcon />
          </Box>
        )}

        {showChevronIcon && (
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            data-testid='arrowIcon'
          >
            <ChevronLeftIcon />
          </IconButton>
        )}

        <Box sx={breadCrumbStyles}>
          <HeaderBreadCrumbs crumbs={breadCrumbsArray} />
          {isEditable && (
            <Box sx={unitNameContainerStyles}>
              {isEditingUnit ? (
                UnitEditFunction()
              ) : (
                <Typography
                  sx={{
                    marginRight: '16px',
                    flexShrink: '1',
                  }}
                  variant={isMobile ? 'subtitle2' : 'h3'}
                >
                  {property?.name}
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: isMobile ? 'flex-end' : 'flex-start',
                  flexGrow: '1',
                  marginRight: '24px',
                  gap: isMobile ? '10px' : '5px',
                }}
                id={'icon'}
              >
                <IconButton
                  size='small'
                  onClick={() => {
                    if (!isEditingUnit) {
                      setEditNameValue(property?.name);
                      setEditNameCursor(property?.name.length);
                    } else {
                      saveEdit();
                    }
                    setIsEditingUnit(!isEditingUnit);
                  }}
                >
                  {isEditingUnit ? <CheckIcon /> : <ModeEditOutlineOutlined />}
                </IconButton>

                {/* edit icon */}
                {isEditingUnit && (
                  <IconButton
                    size='small'
                    onClick={() => {
                      setIsEditingUnit(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}

                {!isEditingUnit && (
                  <IconButton
                    size='small'
                    onClick={toggleFavorite}
                    sx={theme => (isFavourite ? { color: theme.palette.error.main } : {})}
                  >
                    {isFavourite ? <Star /> : <StarBorder />}
                  </IconButton>
                )}
              </Box>
            </Box>
          )}
        </Box>
        {isDesktop && isEditable && (
          <Button
            sx={{
              textTransform: 'none',
              borderRadius: 8,
              margin: '8px 4px',
              fontWeight: 700,
            }}
            color='primary'
            variant='contained'
            onClick={() => {
              dispatch(toggleSlidebar(SlidebarType.ADD_NEW_RESERVATION, {}));
            }}
          >
            Create reservation
          </Button>
        )}
      </>
    );
  };

  const MemberBarContent = () => {
    return (
      <Box sx={headerContainerStyles}>
        <Box sx={mainHeaderIconStyles}>
          <MemberIcon />
        </Box>
        <HeaderBreadCrumbs crumbs={topBarText()} />
      </Box>
    );
  };

  const GuestBarContent = () => {
    return (
      <Box sx={headerContainerStyles}>
        <Box sx={mainHeaderIconStyles}>
          <GuestIcon />
        </Box>
        <HeaderBreadCrumbs crumbs={topBarText()} />
      </Box>
    );
  };

  const SettingsBarContent = () => {
    return (
      <Box sx={headerContainerStyles}>
        <Box sx={mainHeaderIconStyles}>
          <SettingsIcon />
        </Box>
        <HeaderBreadCrumbs crumbs={topBarText()} />
      </Box>
    );
  };

  const AccountBarContent = () => {
    return (
      <Box sx={headerContainerStyles}>
        <Box sx={mainHeaderIconStyles}>
          <AccountIcon />
        </Box>
        <HeaderBreadCrumbs crumbs={topBarText()} />
      </Box>
    );
  };

  const GuestPortalBarContent = () => {
    return (
      <Box sx={headerContainerStyles}>
        <Box sx={mainHeaderIconStyles}>
          <GuestPortalIcon />
        </Box>
        <HeaderBreadCrumbs crumbs={topBarText()} />
      </Box>
    );
  };

  const MessengerContent = () => {
    const content = 'Messenger';
    return (
      <Box sx={headerContainerStyles}>
        <Box sx={mainHeaderIconStyles}>
          <SvgIcon>
            <MessageOutlinedIcon />
          </SvgIcon>
        </Box>
        <Typography variant={isMobile ? 'h4' : 'h2'} noWrap>
          {content}
        </Typography>
      </Box>
    );
  };

  const IntegrationsContent = () => {
    return (
      <Box sx={headerContainerStyles}>
        <Box sx={mainHeaderIconStyles}>
          <IntegratonsIcon />
        </Box>
        <HeaderBreadCrumbs crumbs={topBarText()} />
      </Box>
    );
  };

  const VariablesBarContent = () => {
    return (
      <Box sx={headerContainerStyles}>
        <Box sx={mainHeaderIconStyles}>
          <VariablesIcon />
        </Box>
        <HeaderBreadCrumbs crumbs={topBarText()} />
      </Box>
    );
  };

  const PaymentsContent = () => {
    const content = 'Payments';
    return (
      <Box sx={headerContainerStyles}>
        <Box sx={mainHeaderIconStyles}>
          <PaymentsIcon />
        </Box>
        <Typography variant='h2' noWrap>
          {content}
        </Typography>
      </Box>
    );
  };

  return (
    pathname !== '/' && (
      <PageTitleBar>
        {(() => {
          if (
            ['units', 'locations', 'room-types', 'groups', 'saltogroups'].includes(pathArray[1])
          ) {
            return <UnitContent />;
          }
          if (['members', 'teams'].includes(pathArray[1])) {
            return <MemberBarContent />;
          }
          if (pathname.includes('guests')) {
            return <GuestBarContent />;
          }
          if (pathname.endsWith('settings/')) {
            return <SettingsBarContent />;
          }
          if (pathname.includes('account')) {
            return <AccountBarContent />;
          }
          if (pathname.includes('guest-portal')) {
            return <GuestPortalBarContent />;
          }
          if (pathname.includes('messenger')) {
            return <MessengerContent />;
          }
          if (pathname.includes('integrations')) {
            return <IntegrationsContent />;
          }
          if (pathname.includes('payments')) {
            return <PaymentsContent />;
          }
          if (pathname.includes('variables')) {
            return <VariablesBarContent />;
          }
          return null;
        })()}
      </PageTitleBar>
    )
  );
};

export default OpertoPageTitleBar;
