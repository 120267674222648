import { TRPCProvider } from '@operto/trpc-client';
import { MainContainer, PageContainer, useMedia } from '@operto/ui';
import Sidebar, { MenuType } from 'Common/Sidebar/Sidebar';
import RightSlidebar from 'Common/Slidebar/RightSlidebar';
import GlobalSnackBar from 'Common/Snackbar/GlobalSnackbar';
import PanelSnackBar from 'Common/Snackbar/PanelSnackbar';
import OpertoPageTitleBar from 'Common/TopBar/OpertoPageTitleBar';
import OpertoTopBar from 'Common/TopBar/OpertoTopBar';
import { useSharedLocalStorageReceiver } from 'hooks/useSharedLocalStorageReceiver';
import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { userSelector } from 'user/state/userSelectors';

const Home = () => {
  const location = useLocation();
  const userWithToken = useAppSelector(userSelector());
  const { isDesktop } = useMedia();

  const [isDrawerOpen, setIsDrawerOpen] = useState(isDesktop);
  const [drawerWidth, setDrawerWidth] = useState(256);

  const { pathname } = location;

  const settingsSection = pathname.includes('/settings/');

  const updateDrawerWidth = useCallback(() => {
    const opertoSideBar = document.getElementById('operto-sidebar-header');
    if (opertoSideBar) {
      setDrawerWidth(opertoSideBar.offsetWidth);
    }
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    updateDrawerWidth();
  }, [isDrawerOpen, updateDrawerWidth]);

  useEffect(() => {
    window.addEventListener('resize', updateDrawerWidth);

    return () => {
      window.removeEventListener('resize', updateDrawerWidth);
    };
  }, [updateDrawerWidth]);

  useSharedLocalStorageReceiver();

  return (
    <TRPCProvider
      baseAPIURL={process.env.REACT_APP_TRPC_BASE_URL}
      token={userWithToken.accessToken}
    >
      <GlobalSnackBar />
      <RightSlidebar />

      <Sidebar
        open={isDrawerOpen}
        toggle={toggleDrawer}
        menuType={settingsSection ? MenuType.SETTINGS : MenuType.MAIN}
      />

      <MainContainer>
        <OpertoTopBar open={isDrawerOpen} drawerWidth={drawerWidth} toggle={toggleDrawer} />
        <PanelSnackBar />
        <PageContainer sx={{ maxWidth: '100%', backgroundColor: 'background.default01' }}>
          <OpertoPageTitleBar />
          <Outlet />
        </PageContainer>
      </MainContainer>
    </TRPCProvider>
  );
};

export default Home;
