import CheckIcon from '@mui/icons-material/Check';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import GuestsUnitNameCell from 'Common/Tables/Guests/Components/GuestsUnitNameCell';
import { PMSDisconnectMessage } from 'Common/ToolTip/TooltipMessages';
import ReservationDropdownDrawer from 'Common/Widgets/Reservation/ReservationDropdownDrawer';
import {
  companySelector,
  guestVerificationEnabledSelector,
  roomTypeEnabledSelector,
} from 'company/state/companySelectors';
import isValid from 'date-fns/isValid';
import { FilterType, GuestsFilterKeys, IGuest, IPaymentInfo } from 'guest/guestType';
import { getGuests } from 'guest/state/guestActions';
import { guestByFilter } from 'guest/state/guestSelectors';
import { utcToTimeZone } from 'helper/date';
import useGuestsFilters from 'hooks/useGuestsFilters';
import { IntegrationsApiSystem } from 'integrations/integrationsTypes';
import { findEnabledIntegrationsApiSystemSelector } from 'integrations/state/integrationsSelectors';
import { useAppFeatures } from 'lib/app-features';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { toggleSlidebar } from 'redux/actions/ui';
import { useGuestPortal } from 'redux/guestportal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getPropertyReservations,
  getReservationAccessCode,
  updateGuestReservationVerification,
} from 'reservation/state/reservationActions';
import { SlidebarType } from 'types/ui';
import SecurityDepositCell from 'ui-library/Components/cell/SecurityDepositCell';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { showAccessCodeSelector } from 'user/state/userSelectors';
import { accessCodeColumn } from '../Common/AccessCodeTableCell';
import { GuestNameTableCell } from '../Common/GuestNameTableCell';
import { GuestTermsCell } from './Components/GuestTermsCell';
import { VerificationStatusView } from './VerificationStatusView';

export interface IGuestsTableProps {
  filterType: FilterType;
  propertyView?: boolean;
  roomType?: string;
  header?: React.ReactNode;
}
const accessCodeColDef = accessCodeColumn({
  code: 'access_code',
  status: 'code_status',
});

function GuestsTable({ propertyView = false, filterType, roomType, header }: IGuestsTableProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { fetchSettings } = useGuestPortal();
  const roomTypeEnabled = useAppSelector(roomTypeEnabledSelector());
  const { propertyId } = useParams<{ propertyId: string }>();
  const { guests, totalGuests, isGuestsLoading } = useAppSelector(
    guestByFilter(filterType, propertyView && Number(propertyId)),
  );
  const enabledIncidentalPayment = guests.some(guest => guest.incident_payment_enabled);
  const company = useAppSelector(companySelector());
  const showAccessCode = useAppSelector(showAccessCodeSelector());
  const guestVerificationEnabled = useAppSelector(guestVerificationEnabledSelector());
  const isPaymentSystemEnabled = useAppSelector(
    findEnabledIntegrationsApiSystemSelector(IntegrationsApiSystem.PAYMENT),
  );
  const guestsFilters = useGuestsFilters(propertyView);
  const [pagination, setPagination] = useState<{
    pageNum: number;
    numPerPage: number;
    keyword?: string;
  }>();
  const { settings } = useGuestPortal();
  const { guestTerms: guestTermsEnabled } = useAppFeatures();

  const handleFetch = useCallback((pageNum: number, numPerPage: number, searchValue?: string) => {
    setPagination({ pageNum, numPerPage, keyword: searchValue });
  }, []);

  const renderActions = (params?: GridRenderCellParams) => {
    // NOTE: this seems to be actually IGuest types rather than IReservation.
    const guest = params.row;
    const paymentInfo = guest.payment_info as IPaymentInfo;

    const handleEditReservation = async () => {
      if (!propertyView && propertyId === undefined) {
        // NOTE: for some reason we only cache reservations per property. we
        // need to fetch reservations whenever we select a particular property
        setLoading(true);
        await dispatch(getPropertyReservations(guest.property_id));
        await dispatch(getReservationAccessCode(guest.reservation_id));
        setLoading(false);

        // we can probably save/cache current fetched property reservations base
        // on the "property_id" so we dont need to re-fetch everytime
      }

      dispatch(
        toggleSlidebar(SlidebarType.EDIT_RESERVATIONS, {
          reservationId: guest.reservation_id,
        }),
      );
    };

    const handleVerify = async () => {
      setLoading(true);
      await dispatch(updateGuestReservationVerification(!guest.incident_payment_verified, guest));
      setLoading(false);
    };

    return (
      <>
        <ReservationDropdownDrawer
          paymentInfo={paymentInfo}
          showEdit
          onEdit={handleEditReservation}
          verifyOnClick={handleVerify}
          accessCode={guest.access_code}
          reservation={guest}
          verifyDisabled={showAccessCode}
        />
      </>
    );
  };

  const renderTz = (params: GridRenderCellParams) => {
    const yyyMMMDD = utcToTimeZone(params.row[params.field], params.row.timezone, 'yyyy MM dd');
    const hhMMa = utcToTimeZone(params.row[params.field], params.row.timezone, 'hh:mm a');

    return (
      <>
        <>{yyyMMMDD}</>
        <br></br>
        <>{hhMMa}</>
      </>
    );
  };

  const renderVerify = (params: GridRenderCellParams) => {
    const verified = params.row[params.field];

    return (
      verified && (
        <div style={{ marginLeft: '15px' }}>
          <CheckIcon fontSize='small' color='primary' data-testid='access-approved-icon' />
        </div>
      )
    );
  };

  const renderPdf = ({ row }: GridRenderCellParams<unknown, IGuest>) => {
    return (
      <GuestTermsCell
        guestId={row.registered_guest_id}
        reservationId={row.reservation_id}
        visible={isValid(row.terms_agreed_at)}
      />
    );
  };

  const renderSecurityDeposit = (params: GridRenderCellParams) => {
    const paymentInfo = params.row.payment_info as IPaymentInfo;
    return <SecurityDepositCell securityDeposit={paymentInfo} />;
  };

  const renderGuest = (params: GridRenderCellParams) => {
    const warningMessage = params.row?.over_written_duration ? PMSDisconnectMessage : undefined;
    return (
      <GuestNameTableCell
        warningMessage={warningMessage}
        guestName={params.row.name}
        guestId={params.row.reservation_id}
      />
    );
  };

  const renderPropertyName = (params: GridRenderCellParams) => {
    return (
      <GuestsUnitNameCell
        propertyName={params.row.property_name}
        propertyId={params.row.property_id}
      />
    );
  };

  const columnsDef: GridColDef[] = [
    {
      field: 'reservation_id',
      headerName: 'Guests',
      sortable: false,
      flex: 1,
      renderCell: renderGuest,
    },
    {
      field: 'property_name',
      headerName: 'Unit Name',
      flex: 1.5,
      sortable: false,
      renderCell: renderPropertyName,
    },
    {
      field: 'room_type',
      headerName: 'Room Type',
      flex: 1.5,
      sortable: false,
    },
    {
      field: 'check_in',
      headerName: 'Check-In',
      sortable: false,
      flex: 1,
      renderCell: renderTz,
    },
    {
      field: 'check_out',
      headerName: 'Check-Out',
      sortable: false,
      flex: 1,
      renderCell: renderTz,
    },
    accessCodeColDef,
    {
      field: 'booking_code',
      headerName: 'Booking Code',
      sortable: false,
      flex: 1,
    },
    {
      field: 'security_deposit',
      headerName: 'Security Deposit',
      sortable: false,
      flex: 2,
      renderCell: renderSecurityDeposit,
      headerAlign: 'center',
      align: 'center',
      maxWidth: 240,
    },
    {
      field: 'verification_status',
      headerName: 'Verification',
      width: 150,
      sortable: false,
      renderCell: VerificationStatusView,
    },
    {
      field: 'incident_payment_verified',
      headerName: 'Access approval',
      sortable: false,
      renderCell: renderVerify,
      align: 'left',
      flex: 1,
    },
    {
      field: 'terms_agreed',
      headerName: 'Guest terms',
      sortable: false,
      renderCell: renderPdf,
      align: 'left',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: renderActions,
      type: 'number',
      sortable: false,
      resizable: false,
    },
  ];

  const getVisibleColumns = () => {
    const visibleColumns = {
      reservation_id: true,
      property_name: true,
      check_in: true,
      check_out: true,
      action: true,
      access_code: !company.smwEnabled,
      booking_code: company.smwEnabled,
      security_deposit: isPaymentSystemEnabled,
      incident_payment_verified: enabledIncidentalPayment,
      verification_status: guestVerificationEnabled,
      room_type: roomTypeEnabled,
      terms_agreed: guestTermsEnabled && settings?.termsEnabled && company.newLoginEnabled,
    };

    if (isMobile) {
      visibleColumns.property_name = false;
      visibleColumns.check_in = false;
      visibleColumns.check_out = false;
      visibleColumns.security_deposit = false;
      visibleColumns.incident_payment_verified = false;
      visibleColumns.verification_status = false;
      visibleColumns.action = true;
    }

    if (propertyView) {
      visibleColumns.property_name = false;
      visibleColumns.room_type = false;
    }

    if (roomType) {
      visibleColumns.property_name = false;
      visibleColumns.access_code = false;
      visibleColumns.booking_code = false;
      visibleColumns.incident_payment_verified = false;
      visibleColumns.room_type = false;
    }

    return visibleColumns;
  };

  useEffect(() => {
    if (company.guestPortalStatus === 'active') {
      // NOTE: this fetch settings is more related to ReservationDropdownDrawer
      // to determine which menu actions are available.
      fetchSettings();
    }
  }, [company.guestPortalStatus, fetchSettings]);

  useEffect(() => {
    if (guestsFilters.isSynched && pagination) {
      const urlSearchParams = new URLSearchParams(guestsFilters.searchParams);
      urlSearchParams.set(GuestsFilterKeys.PAGE_NUMBER, `${pagination.pageNum}`);
      urlSearchParams.set(GuestsFilterKeys.NUMBER_PER_PAGE, `${pagination.numPerPage}`);
      if (pagination.keyword) {
        urlSearchParams.set(GuestsFilterKeys.KEYWORD, pagination.keyword);
      }

      // NOTE: use side effect to filter data. everytime there is a
      // change in any of the dependency, this will be triggered.
      dispatch(
        getGuests({
          propertyId: propertyView && propertyId,
          filterType,
          urlSearchParams,
        }),
      );
    }
  }, [
    dispatch,
    filterType,
    guestsFilters.isSynched,
    guestsFilters.searchParams,
    pagination,
    propertyId,
    propertyView,
  ]);

  if (!guestsFilters.isSynched) {
    return null; // TODO: skeleton loader would be nice
  }

  return (
    <PaginatedTable
      enableToolbar
      title={header ?? 'Guests'}
      initialState={{ pinnedColumns: { right: ['action'] } }}
      loading={loading || isGuestsLoading}
      rows={guests}
      rowCount={totalGuests}
      columns={columnsDef}
      columnVisibilityModel={getVisibleColumns()}
      onFetch={handleFetch}
      filtersToolbarProps={propertyId ? undefined : guestsFilters}
    />
  );
}

export default GuestsTable;
