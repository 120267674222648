import { Loading } from 'Common/Loading';
import React from 'react';
import { FilterGroup } from 'types/filter-type';
import { FiltersToolbarProps } from 'ui-library/Components/table/FiltersToolbar';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import DeliveryLogsTitlebar from './DeliveryLogsTitlebar';
import useDeliveryLogs from './useDeliveryLogs';
import useDeliveryLogsColumns from './useDeliveryLogsColumns';

const DeliveryLogsTable = () => {
  const { data: deliveryLogsData, isFetching } = useDeliveryLogs();

  const columns = useDeliveryLogsColumns();

  const filterGroups: FilterGroup[] = [
    {
      groupName: 'scheduled',
      label: 'Scheduled date',
      options: [
        {
          filterType: 'datepicker',
          label: '',
          name: 'scheduled',
          singleSelect: false,
        },
      ],
    },
    {
      groupName: 'status',
      label: 'Status',
      options: [
        { filterType: 'checkbox', label: 'Delivered', name: 'delivered' },
        { filterType: 'checkbox', label: 'Failed to deliver', name: 'failed to deliver' },
      ],
    },
    {
      groupName: 'channel',
      label: 'Channels',
      options: [
        { filterType: 'checkbox', label: 'Email', name: 'email' },
        { filterType: 'checkbox', label: 'SMS', name: 'sms' },
      ],
    },
  ];

  // TODO: Update filters in https://operto.atlassian.net/browse/OPN1-19707
  const deliveryLogsFilters: FiltersToolbarProps = {
    filterGroups,
    searchParams: new URLSearchParams(),
    isClearableFilters: () => true,
    onFilterChange: () => void 0,
    onFilterClear: () => void 0,
    updateChipLabel: () => {
      return '';
    },
  };

  if (isFetching) {
    return <Loading />;
  }

  return (
    <>
      <DeliveryLogsTitlebar />
      <PaginatedTable
        enableToolbar
        rows={deliveryLogsData ?? []}
        columns={columns}
        filtersToolbarProps={deliveryLogsFilters}
      />
    </>
  );
};

export default DeliveryLogsTable;
