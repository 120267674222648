import { setupAppFeatures } from '@operto/app-features';
import { isProduction } from './constants';

// declare your app features here
const appFeatures = {
  churnzero: {
    enabled: isProduction,
  },
  fullstory: {
    enabled: isProduction,
  },
  groups: {
    enabled: true,
  },
  tags: {
    enabled: true,
  },
  guestTerms: {
    enabled: true,
  },
  guestProfile: {
    enabled: true,
  },
  transactionsPage: {
    enabled: false,
  },
  marketplacePage: {
    enabled: !isProduction,
  },
  communicationsPage: {
    enabled: !isProduction,
  },
};

type AppFeatureFlags = keyof typeof appFeatures;

export const { useAppFeatures } = setupAppFeatures<AppFeatureFlags>(appFeatures);
