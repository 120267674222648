import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Stack, SxProps, Typography } from '@mui/material';
import { useMedia } from '@operto/ui';
import { SearchTextField } from 'Common/TextField/SearchTextField';
import React from 'react';
import TableTooltip from './TableTooltip';

export type SearchToolbarProps = {
  title: string | React.ReactNode;
  value?: string;
  onChange: (search: string) => void;
  onAdd?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  disableAdd?: boolean;
  containerSx?: SxProps;
  addTooltipValue?: string;
};

export const SearchToolbar = ({
  title,
  value,
  onChange,
  onAdd,
  disableAdd = false,
  containerSx,
  addTooltipValue,
}: SearchToolbarProps) => {
  const { isMobile } = useMedia();

  return (
    <Stack sx={containerSx ?? searchBarContainer(isMobile)}>
      {!isMobile &&
        (typeof title === 'string' ? <Typography variant='subtitle1'>{title}</Typography> : title)}
      <Box data-testid='spacer' flex={1} />
      <SearchTextField value={value} size='medium' onChange={onChange} data-testid='search-field' />
      {onAdd &&
        (disableAdd ? (
          <TableTooltip title={'Add restricted'}>
            <Box>
              <Fab disabled size='small' color='primary' sx={addButtonStyle}>
                <AddIcon />
              </Fab>
            </Box>
          </TableTooltip>
        ) : (
          <TableTooltip title={addTooltipValue}>
            <Fab size='small' color='primary' onClick={onAdd} sx={addButtonStyle}>
              <AddIcon />
            </Fab>
          </TableTooltip>
        ))}
    </Stack>
  );
};

const searchBarContainer = (isMobile: boolean): SxProps => ({
  paddingY: isMobile ? 1 : 2,
  paddingX: isMobile ? 1 : 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const addButtonStyle: SxProps = {
  marginLeft: 1,
};
