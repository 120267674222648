import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { Loading } from 'Common/Loading';
import { ChannelType, useAutomate } from 'hooks/useAutomate';
import useAutomateFilters from 'hooks/useAutomateFilters';
import React, { useEffect, useRef } from 'react';
import {
  getSortModelByUrl,
  PaginatedTable,
  sortTableByUrl,
} from 'ui-library/Components/table/PaginatedTable';
import AutomateTitlebar from './AutomateTitlebar';
import CreateTemplate from './CreateTemplate';
import CreateWorkflow from './CreateWorkflow';
import useAutomateColumns from './useAutomateColumns';

const AutomateTable = () => {
  const firstLoad = useRef(true);
  const automateFilters = useAutomateFilters();
  const columns = useAutomateColumns();
  const channelsSelected = automateFilters.searchParams.get('channel')?.split(',') as ChannelType[];

  const activeSelected = automateFilters.searchParams
    .get('active')
    ?.split(',')
    ?.map(value => value === 'true') as boolean[];

  const hasFilters = channelsSelected || activeSelected;

  const { templates, workflows, isLoading, isFetching } = useAutomate({
    channels: channelsSelected,
    running: activeSelected,
  });

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    }
  }, []);

  const renderContent = () => {
    if (firstLoad.current && isLoading) {
      return <Loading />;
    }

    if (!hasFilters && !isLoading && !templates?.length && !workflows?.length) {
      return <CreateTemplate />;
    }

    if (!hasFilters && !isLoading && !workflows?.length) {
      return <CreateWorkflow />;
    }

    return (
      <PaginatedTable
        enableToolbar
        rows={workflows ?? []}
        columns={columns}
        disableAdd={templates?.length === 0}
        initialState={tableInitialState}
        onSortModelChange={model =>
          automateFilters.onFilterChange(sortTableByUrl(model, automateFilters.searchParams))
        }
        sortModel={getSortModelByUrl(automateFilters.searchParams)}
        filtersToolbarProps={automateFilters}
        loading={!automateFilters.isSynched || (isFetching && (!templates || !workflows))}
      />
    );
  };

  return (
    <>
      <AutomateTitlebar displayAddButton={!isLoading && workflows?.length > 0} />

      {renderContent()}
    </>
  );
};

export default AutomateTable;

const tableInitialState: GridInitialStatePro = {
  sorting: {
    sortModel: [{ field: 'name', sort: 'asc' }],
  },
};
