import { RTKQapi } from 'redux/rtkq-api';
import { ResponseData } from 'redux/rtkq-utils';
import { UpsellTemplate } from './types';

const enhancedApi = RTKQapi.enhanceEndpoints({ addTagTypes: ['UpsellsService'] });

export const UpsellTemplatesApi = enhancedApi.injectEndpoints({
  endpoints: builder => ({
    getUpsellTemplates: builder.query<ResponseData<UpsellTemplate[]>, void>({
      query: () => ({
        url: `http://localhost:3900/dev/upsells/offerTemplateList`, // todo update to neo endpoint
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetUpsellTemplatesQuery } = UpsellTemplatesApi;
