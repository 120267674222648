import { Box, FormControlLabel, Radio, RadioGroup, Switch, Typography } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import useTranslation from 'hooks/useTranslation';
import React, { ChangeEvent } from 'react';
import renderTextField from './TextInput';

interface CalendarPickerSectionProps {
  header: string;
  datePickerType: string;
  active: boolean;
  setDates: (status: string | string[]) => void;
  setdatePickerType: (status: string) => void;
  setActive: (active: boolean) => void;
}

export const CalendarPickerSection = ({
  header,
  datePickerType,
  active,
  setDates,
  setdatePickerType,
  setActive,
}: CalendarPickerSectionProps) => {
  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDates(event.target.value);
  };

  const handleDateSelectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setdatePickerType(event.target.value);
  };

  const { t } = useTranslation();

  return (
    <AddCard
      title={t('calendar_picker')}
      action={<Switch checked={active} onChange={() => setActive(!active)} />}
    >
      {active && (
        <Box display='flex' flexDirection='column' gap={2}>
          <Typography>{t('date_header')}</Typography>
          {renderTextField(
            t('preferred_date_header'),
            t('preferred_date'),
            header,
            handleDateChange,
            50,
            true,
            1,
            false,
          )}
          <Typography>{t('date_picker')}</Typography>
          <RadioGroup value={datePickerType} onChange={handleDateSelectionChange}>
            <FormControlLabel
              value='singleDate'
              control={<Radio />}
              label={t('allow_guest_to_select_of_a_single_date')}
            />
            <FormControlLabel
              value='startEndDate'
              control={<Radio />}
              label={t('allow_guest_to_select_a_start_and_end_date_range')}
            />
          </RadioGroup>
        </Box>
      )}
    </AddCard>
  );
};
