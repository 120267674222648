import { trpc } from '@operto/trpc-client';
import { companySelector } from 'company/state/companySelectors';
import { useAppSelector } from 'redux/hooks';

export const useAutomate = (filters?: useAutomateProps) => {
  const company = useAppSelector(companySelector());
  const { channels, running } = filters || {};

  const {
    data: templates,
    isFetching: isTemplatesFetching,
    isError: isTemplatesFetchedError,
    isLoading: isTemplateLoading,
  } = trpc.communications.getTemplates.useQuery(
    { companyId: `${company.id}` },
    {
      retry(failureCount, error) {
        // retry if not unauth, forbidden or not found
        return failureCount < 3 && ![401, 403, 404].includes(error.data.httpStatus);
      },
    },
  );

  const {
    data: workflows,
    isFetching: isWorkflowsFetching,
    isError: isWorkflowsFetchedError,
    isLoading: isWorkflowsLoading,
  } = trpc.communications.getWorkflows.useQuery(
    { companyId: `${company.id}`, filterParams: { channels, running } },
    {
      retry(failureCount, error) {
        // retry if not unauth, forbidden or not found
        return failureCount < 3 && ![401, 403, 404].includes(error.data.httpStatus);
      },
    },
  );

  const isFetching = isTemplatesFetching || isWorkflowsFetching;
  const isLoading = isTemplateLoading || isWorkflowsLoading;
  const error = isTemplatesFetchedError || isWorkflowsFetchedError;

  return {
    templates,
    workflows,
    isFetching,
    error,
    isLoading,
  };
};

type useAutomateProps = { channels?: ChannelType[]; running?: boolean[] };
export type ChannelType = 'email' | 'sms';
