import { LogDataType } from '@operto/delivery-logs-shared';
import { trpc } from '@operto/trpc-client';
import { useMemo } from 'react';

export default function useDeliveryLogs() {
  const { data, isFetching, isLoading } = trpc.communications.getLogs.useQuery<LogDataType[]>(
    {},
    {
      retry(failureCount, error) {
        // retry if not unauth, forbidden or not found
        return failureCount < 3 && ![401, 403, 404].includes(error.data.httpStatus);
      },
    },
  );

  const deliveryLogs = useMemo(
    () =>
      data?.map((log: LogDataType) => {
        return {
          id: log.jobId,
          scheduledAt: new Date(log.scheduledAt),
          status: log.status,
          reservationId: log.guest.id,
          guestName: log.guest.name,
          recipient: log.recipient,
          unitId: log.unit.id,
          unitName: log.unit.name,
          timezone: log.unit.timezone,
          templateName: log.template.name,
          channelType: log.template.channelType,
        };
      }),
    [data],
  );

  return { data: deliveryLogs, isFetching, isLoading };
}
