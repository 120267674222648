import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';
import BottomNav from './BottomNav';
import OrderSummary from './OrderSummary';
import PaymentInfo from './PaymentInfo';
import TimeButton from './TimeButton';

const UpsellGuestPortalContainer = () => {
  const [value, setValue] = useState(2);
  const { t } = useTranslation();

  return (
    <Card sx={{ width: 375 }}>
      <CardContent>
        <Box display='flex' alignItems='center' mb={2}>
          <IconButton>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant='h6'>Early check-in</Typography>
        </Box>
        <Divider />
        <CardMedia
          component='img'
          height='140'
          image='https://via.placeholder.com/400x140'
          style={{ marginBottom: '16px' }}
        />
        <Typography variant='h6'>$20 USD</Typography>
        <Typography variant='subtitle2'>Limit</Typography>
        <Typography variant='body2' gutterBottom>
          Check in to your accommodation ahead of the standard check-in time.
        </Typography>
        <Typography variant='h6' gutterBottom>
          Select preferred time
        </Typography>
        <Grid container spacing={1} mb={2}>
          {['07:00 PM', '06:00 PM', '05:00 PM', '04:00 PM', '03:00 PM', '02:00 PM'].map(time => (
            <TimeButton key={time} time={time} />
          ))}
        </Grid>
        <TextField
          placeholder={t('special_requests_optional')}
          variant='outlined'
          fullWidth
          multiline
          margin='normal'
        />
        <OrderSummary />
        <PaymentInfo />
      </CardContent>
      <BottomNav value={value} setValue={setValue} />
    </Card>
  );
};

export default UpsellGuestPortalContainer;
