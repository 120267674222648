import { Add } from '@mui/icons-material';
import { Box, Fab, Stack } from '@mui/material';
import { Text } from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export default function AutomateCreateWorkflowDiagram({
  onClickBefore,
  onClickAfter,
}: AutomateCreateWorkflowDiagramProps) {
  const { t } = useTranslation();

  return (
    <Stack sx={rootContainerStyles}>
      <Stack sx={diagramContainerStyles}>
        <Fab sx={fabStyles} onClick={onClickBefore}>
          <Add />
        </Fab>

        <Box sx={typeContainerStyles}>
          <Box sx={horizontalLineStyles} />

          <Text variant='h4-300' style={typeStyles}>
            {t('before')}
          </Text>
        </Box>

        <Box sx={mainProcessContainerStyles}>
          <Text variant='h3-700'>{t('reservation_check_in')}</Text>
        </Box>

        <Box sx={typeContainerStyles}>
          <Box sx={horizontalLineStyles} />

          <Text variant='h4-300' style={typeStyles}>
            {t('at_or_after')}
          </Text>
        </Box>

        <Fab sx={fabStyles} onClick={onClickAfter}>
          <Add />
        </Fab>
      </Stack>

      <Box sx={messageContainerStyles}>
        <Text variant='body-lg-400'>{t('you_can_add_two_event_triggers')}</Text>
      </Box>
    </Stack>
  );
}

type AutomateCreateWorkflowDiagramProps = {
  onClickBefore: () => void;
  onClickAfter: () => void;
};

const rootContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  backgroundColor: '#EBEDF5',
  borderRadius: '16px',
  marginTop: '24px',
  marginBottom: '-8px',
  padding: '24px 0 24px 0',
};

const diagramContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
};

const messageContainerStyles = {
  display: 'flex',
  paddingTop: '24px',
  paddingBottom: '24px',
  justifyContent: 'center',
  alignItems: 'center',
};

const fabStyles = {
  backgroundColor: '#fff',
};

const typeContainerStyles = {
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const mainProcessContainerStyles = {
  width: '600px',
  display: 'flex',
  padding: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  border: '4px solid rgba(8, 22, 62, 0.23)',
  borderRadius: '28px',
};

const typeStyles = { backgroundColor: '#EBEDF5', zIndex: 1 };

const horizontalLineStyles = {
  borderRight: '2px solid black',
  height: '50px',
  position: 'absolute',
};
