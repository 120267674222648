import { Avatar, Box, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getCompanyRequests } from 'api/addOnAPI';
import { companySelector } from 'company/state/companySelectors';
import { format } from 'date-fns';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import OrderDetailsSidebar from './OrderDetailSidebar';

// TODO: use RequestListItemType from payments-shared
export type Order = {
  id: string;
  requestedDate: string;
  companyId: string;
  offerId: string;
  transactionId: string;
  imageUrl?: string;
  name?: string;
  guestName?: string;
  guestId?: string;
  unitName?: string;
  unitId?: string;
  totalPrice: number;
  /** @enum {string} */
  requestStatus: 'pending' | 'completed' | 'cancelled';
};

export const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'pending':
      return '#E4E965';
    case 'sent proposal':
      return '#D4BEEA';
    case 'purchased':
      return '#BCDFE4';
    case 'declined':
      return '#676668';
    case 'cancelled':
      return '#E7E3E4';
    default:
      return 'white';
  }
};

const OrdersTable = ({ title }: { title: string }) => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const company = useAppSelector(companySelector());

  const { t } = useTranslation();

  const fetchOrders = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getCompanyRequests(company?.id);
      const orders = response?.data?.data;
      setOrders(orders);
    } catch (error) {
      logger.error('Error fetching orders', error);
    } finally {
      setLoading(false);
    }
  }, [company?.id]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    if (orders.length) {
      setFilteredOrders(orders);
    }
  }, [orders]);

  const handleSearch = useCallback(
    (pageNum: number, numPerPage: number, searchString?: string) => {
      if (!searchString?.length) {
        return setFilteredOrders(orders);
      }
      const lowerCaseSearchString = searchString.toLowerCase();
      const filteredOrders = orders.filter((order: Order) => {
        return (
          order.requestStatus.toLowerCase().includes(lowerCaseSearchString) ||
          order.name?.toLowerCase().includes(lowerCaseSearchString) ||
          order.guestId?.toLowerCase().includes(lowerCaseSearchString)
        );
      });

      setFilteredOrders(filteredOrders);
    },
    [orders],
  );

  const handleExport = () => {
    const dataToExport = filteredOrders.map(order => ({
      ID: order.id,
      [t('status')]: order.requestStatus,
      [t('requested')]: order.requestedDate,
      [t('order_name')]: order.name,
      [t('quantity')]: 'n/a',
      [t('price')]: order.totalPrice,
      [t('unit')]: order.unitName,
      [t('unit_id')]: order.unitId,
      [t('guest_name')]: order.guestName,
      [t('guest_id')]: order.guestId,
    }));

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [
        `ID,${t('status')},${t('requested')},${t('order_name')},${t('order_name')},${t(
          'price',
        )},${t('unit')},${t('unit_id')},${t('guest_name')},${t('guest_id')}`,
      ]
        .concat(dataToExport.map(row => Object.values(row).join(',')))
        .join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'orders_export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columnsDef: GridColDef[] = [
    {
      field: 'requestStatus',
      headerName: t('status'),
      minWidth: 120,
      renderCell: ({ row }: GridRenderCellParams<Order>) => (
        <Box
          sx={{
            backgroundColor: getStatusColor(row.requestStatus),
            paddingVertical: '5px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '5px',
            fontWeight: 600,
            fontSize: '10px',
          }}
        >
          {row.requestStatus}
        </Box>
      ),
      flex: 1,
    },
    {
      field: 'requestedDate',
      headerName: t('requested'),
      minWidth: 120,
      renderCell: ({ row }: GridRenderCellParams<Order>) => (
        <Box sx={{ background: 'white' }}>
          <Typography variant='body2' noWrap>
            {format(new Date(row.requestedDate), 'yyyy-MM-dd')}
          </Typography>
          <Typography variant='caption' color='textSecondary' noWrap>
            {format(new Date(row.requestedDate), 'hh:mm aaaa')}
          </Typography>
        </Box>
      ),
      flex: 2,
      sortable: true,
    },
    {
      field: 'name',
      headerName: t('order'),
      minWidth: 250,
      renderCell: ({ row }: GridRenderCellParams<Order>) => (
        <Box
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', background: 'white' }}
          onClick={() => setSelectedOrder(row)}
        >
          <Avatar
            src={row.imageUrl}
            alt={row.name}
            variant='rounded'
            sx={{ marginRight: '10px', borderRadius: '8px' }}
          />
          <Box>
            <Typography
              variant='body2'
              noWrap
              sx={{
                color: 'primary.main',
                fontWeight: 600,
                cursor: 'pointer',
              }}
            >
              {row.name}
            </Typography>
            <Typography variant='caption' color='textSecondary' noWrap>
              {row.id}
            </Typography>
          </Box>
        </Box>
      ),
      flex: 3,
    },
    {
      field: 'pricing.maxQuantity',
      headerName: t('quantity'),
      minWidth: 92,
      flex: 1,
      sortable: true,
      cellClassName: 'white-cell',
    },
    {
      field: 'priceSticker',
      headerName: t('price'),
      minWidth: 235,
      flex: 1,
      sortable: true,
      cellClassName: 'white-cell',
    },
    {
      field: 'unitAssigned.units',
      headerName: t('unit'),
      minWidth: 235,
      renderCell: ({ row }: GridRenderCellParams<Order>) => (
        <Box sx={{ background: 'white' }}>
          <Typography variant='body2' noWrap>
            {row.unitName}
          </Typography>
          <Typography variant='caption' color='textSecondary' noWrap>
            {row.unitId}
          </Typography>
        </Box>
      ),
      flex: 2,
      sortable: true,
    },
    {
      field: 'guestId',
      headerName: t('guest'),
      minWidth: 235,
      renderCell: ({ row }: GridRenderCellParams<Order>) => (
        <Box sx={{ background: 'white' }}>{row.guestId}</Box>
      ),
      flex: 2,
      sortable: true,
    },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flex: 1, height: 400, width: '100%' }}>
        <PaginatedTable
          enableToolbar
          title={title}
          rows={filteredOrders}
          rowCount={filteredOrders.length}
          columns={columnsDef}
          loading={loading}
          onFetch={handleSearch}
          onExport={handleExport}
          sx={{ '& .MuiDataGrid-cell': { backgroundColor: 'white' } }}
        />
      </Box>
      {selectedOrder && (
        <OrderDetailsSidebar order={selectedOrder} onClose={() => setSelectedOrder(null)} />
      )}
    </Box>
  );
};

export default OrdersTable;
