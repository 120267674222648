import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import { Alert, AlertTitle, Box, Button, Collapse, IconButton, SxProps } from '@mui/material';
import { ILock } from 'device/deviceType';
import { getPropertyEvents } from 'event/state/eventActions';
import { lock, unlock } from 'lock/state/lockActions';
import * as React from 'react';
import { useAppDispatch } from 'redux/hooks';
import { FooterContainer, SmartCardIconButton } from 'ui-library/Styled/smartcard';

export interface LockFooterProps {
  onDetailsClick: () => void;
  onClosePrompt: () => void;
  onAPICall: () => void;
  onAPICallBack: (isSuccess: boolean) => void;
  openPrompt: boolean;

  device: ILock;
}

const LockFooter = ({
  onDetailsClick,
  openPrompt,
  onClosePrompt,
  device,
  onAPICall,
  onAPICallBack,
}: LockFooterProps) => {
  const dispatch = useAppDispatch();

  const callBack = (isSuccess: boolean) => {
    if (isSuccess && device.property_id) {
      dispatch(getPropertyEvents(device.property_id));
    }
    onAPICallBack(isSuccess);
  };

  const handleLockDevice = () => {
    if (device.is_locked) {
      dispatch(unlock(device.device_external_id, callBack));
    } else {
      dispatch(lock(device.device_external_id, callBack));
    }
    onAPICall();
    onClosePrompt();
  };
  return (
    <FooterContainer justify='space-between'>
      {openPrompt && (
        <Collapse sx={prompContainerStyles} in={openPrompt}>
          <Alert sx={customAlertStyles} variant='filled' severity='warning'>
            <AlertTitle>{device.is_locked ? 'Unlock' : 'Lock'} Device?</AlertTitle>
            <Box sx={buttonContainerStyles}>
              <IconButton data-testid='confirm-button' onClick={handleLockDevice} size='small'>
                <CheckIcon />
              </IconButton>
              <IconButton data-testid='cancel-button' size='small' onClick={onClosePrompt}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Alert>
        </Collapse>
      )}
      {!openPrompt && (
        <Button sx={{ color: 'white' }} onClick={onDetailsClick}>
          View Access
        </Button>
      )}
      <SmartCardIconButton>
        <HelpIcon />
      </SmartCardIconButton>
    </FooterContainer>
  );
};

const customAlertStyles: SxProps = {
  height: '48px',
  width: '100%',
  borderTopLeftRadius: '0',
  borderTopRightRadius: '0',
  '.MuiIconButton-root': {
    color: 'white',
  },
  '.MuiAlert-message': {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const buttonContainerStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
};

const prompContainerStyles: SxProps = {
  position: 'absolute',
  zIndex: 2,
  bottom: 0,
  width: '100%',
  left: 0,
};

export default LockFooter;
