import CloseIcon from '@mui/icons-material/Close';
import { Alert, Snackbar } from '@mui/material';
import ReservationsForm, { ReservationFormProps } from 'Common/Widgets/Reservation/ReservationForm';
import { trackEvent } from 'lib/analytics';
import { useAppFeatures } from 'lib/app-features';
import { logger } from 'lib/logger';
import React, { useEffect, useState } from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useGetGuestQuery } from 'redux/guest-service/api-slice';
import { GuestProfile } from 'redux/guest-service/types';
import { useAppDispatch } from 'redux/hooks';
import { useGetGuestIdQuery } from 'redux/portal-guest-reservation/api-slice';
import { IReservation } from 'reservation/reservationType';
import { updateReservation } from 'reservation/state/reservationActions';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';

type EditReservationsProps = Omit<ReservationFormProps, 'onSubmit'>;

const EditReservations = ({ handleClose, ...rest }: EditReservationsProps) => {
  const dispatch = useAppDispatch();
  const [saving, setSaving] = useState(false);
  const [guestProfile, setGuestProfile] = useState<GuestProfile | null>(null);
  const { guestProfile: guestProfileEnabled } = useAppFeatures();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const vertical = 'bottom';
  const horizontal = 'center';

  const reservationId = rest.reservationId;

  // Get guest id from core
  const {
    data: guestIdResponse,
    isSuccess: guestIdSuccess,
    isError: guestIdError,
    refetch: refetchGuestId,
  } = useGetGuestIdQuery(reservationId, { skip: !guestProfileEnabled });

  // Get guest data from guest service
  const guestId = guestIdResponse?.data?.guest_id;
  const {
    data: guestDataResponse,
    isLoading: guestDataIsLoading,
    isSuccess: guestDataIsSuccess,
  } = useGetGuestQuery(guestId, {
    skip: !guestIdSuccess || !guestId || !guestProfileEnabled,
  });

  const onSubmit = async (data: IReservation) => {
    let success = true;
    setSaving(true);

    try {
      await dispatch(updateReservation(data.id, data));
      handleClose();
      trackEvent({
        screen: 'Edit Reservations',
        event: 'SAVED',
      });
    } catch (error) {
      success = false;
      logger.error(`Error saving reservation: ${error}`);
    }

    setSaving(false);
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `Reservation was${success ? ' ' : ' not '}updated successfully.`,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };

  const onUnlinkSuccess = (message: string) => {
    refetchGuestId();
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    if (guestDataIsSuccess && guestDataResponse?.data) {
      setGuestProfile(guestDataResponse.data);
    }
    if (guestIdError) {
      setGuestProfile(null);
    }
  }, [guestDataIsSuccess, guestDataResponse?.data, guestIdError]);

  return (
    <LoadingContainer loading={saving || (guestDataIsLoading && guestProfileEnabled)}>
      <ReservationsForm
        submitting={saving}
        onSubmit={onSubmit}
        handleClose={handleClose}
        guestProfile={guestProfileEnabled ? guestProfile : null}
        onUnlinkSuccess={guestProfileEnabled ? onUnlinkSuccess : undefined}
        {...rest}
      />

      {guestProfileEnabled && (
        <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical, horizontal }}
          sx={{
            width: '75%',
          }}
        >
          <Alert
            variant='filled'
            icon={false}
            sx={{
              display: 'inline-flex',
              padding: '2px 16px',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '48',
              backgroundColor: '#323232',
              borderRadius: '4px',
            }}
            action={
              <CloseIcon
                fontSize='small'
                onClick={() => {
                  setOpenSnackbar(false);
                }}
                sx={{ cursor: 'pointer' }}
              />
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </LoadingContainer>
  );
};

export default EditReservations;
