import { Grid } from '@mui/material';
import React from 'react';
import { UpsellFields } from '../UpsellPage';
import { AffiliateLinkPricing } from './Pricing/AffiliateLinkPricing';
import { FreeItemPricing } from './Pricing/FreeItemPricing';
import { SingleItemPricing } from './Pricing/SingleItemPricing';

interface PricingProps {
  upsellFields: UpsellFields;
  updateUpsellFields: <K extends keyof UpsellFields>(field: K, value: UpsellFields[K]) => void;
}

export const Pricing = ({ upsellFields, updateUpsellFields }: PricingProps) => {
  const { pricing } = upsellFields;

  const updatePricingField = (
    field: keyof UpsellFields['pricing'],
    value: string | number | boolean | null,
  ) => {
    updateUpsellFields('pricing', { ...pricing, [field]: value });
  };

  return (
    <Grid item xs={12} md={9}>
      <SingleItemPricing
        price={pricing.price}
        taxRate={pricing.taxRate}
        confirmationMethod={pricing.confirmationMethod}
        paymentType={pricing.paymentType}
        quantityStepperEnabled={pricing.quantityStepperEnabled}
        maxQuantity={pricing.maxQuantity}
        quantityName={pricing.quantityName}
        setPrice={(price: number) => {
          updatePricingField('price', price);
          updateUpsellFields('priceSticker', `$${price.toString() ?? '$1'} USD`);
        }}
        setTaxRate={(taxRate: number) => updatePricingField('taxRate', taxRate)}
        setConfirmationMethod={(confirmationMethod: string) =>
          updatePricingField('confirmationMethod', confirmationMethod)
        }
        setPaymentType={(paymentType: string) => updatePricingField('paymentType', paymentType)}
        setQuantityStepperEnabled={(quantityStepperEnabled: boolean) =>
          updatePricingField('quantityStepperEnabled', quantityStepperEnabled)
        }
        setMaxQuantity={(maxQuantity: number) => updatePricingField('maxQuantity', maxQuantity)}
        setQuantityName={(quantityName: string) => updatePricingField('quantityName', quantityName)}
      />
      <AffiliateLinkPricing
        paymentType={pricing.paymentType}
        customButtonText={pricing.customButtonText}
        affiliateLink={pricing.affiliateLink}
        customAffiliateHeader={pricing.affiliateCustomHeader}
        customAffiliateHeaderEnabled={pricing.affiliateCustomHeaderEnabled}
        setCustomButtonText={(customButtonText: string) =>
          updatePricingField('customButtonText', customButtonText)
        }
        setAffiliateLink={(affiliateLink: string) =>
          updatePricingField('affiliateLink', affiliateLink)
        }
        setCustomAffiliateHeader={(affiliateCustomHeader: string) =>
          updatePricingField('affiliateCustomHeader', affiliateCustomHeader)
        }
        setCustomAffiliateHeaderEnabled={(affiliateCustomHeaderEnabled: boolean) =>
          updatePricingField('affiliateCustomHeaderEnabled', affiliateCustomHeaderEnabled)
        }
        setPaymentType={(paymentType: string) => updatePricingField('paymentType', paymentType)}
      />
      <FreeItemPricing
        paymentType={pricing.paymentType}
        confirmationMethod={pricing.confirmationMethod}
        quantityStepperEnabled={pricing.quantityStepperEnabled}
        maxQuantity={pricing.maxQuantity}
        quantityName={pricing.quantityName}
        setConfirmationMethod={(confirmationMethod: string) =>
          updatePricingField('confirmationMethod', confirmationMethod)
        }
        setPaymentType={(paymentType: string) => updatePricingField('paymentType', paymentType)}
        setQuantityStepperEnabled={(quantityStepperEnabled: boolean) =>
          updatePricingField('quantityStepperEnabled', quantityStepperEnabled)
        }
        setMaxQuantity={(maxQuantity: number) => updatePricingField('maxQuantity', maxQuantity)}
        setQuantityName={(quantityName: string) => updatePricingField('quantityName', quantityName)}
      />
    </Grid>
  );
};
