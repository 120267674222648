import { Add, ExpandMore, SellOutlined } from '@mui/icons-material';
import {
  TitlebarBreadCrumb,
  TitlebarBreadCrumbChild,
  TitlebarButtonExpanded,
  TitlebarRoot,
  TitlebarTitleIcon,
} from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUpsellTemplatesQuery } from 'redux/upsell-service/api-slice';
import { UpsellTemplate } from 'redux/upsell-service/types';
import UpsellNewMenu, { MenuOptionType } from './UpsellNewMenu';
import UpsellTemplateCard from './UpsellTemplate/UpsellTemplateCard';
import UpsellTemplateModal from './UpsellTemplate/UpsellTemplateModal';

export default function UpsellItemsTitlebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { data: upsellTemplatesResponse } = useGetUpsellTemplatesQuery();
  const [open, setOpen] = React.useState(false);

  const crumbs: TitlebarBreadCrumbChild[] = [
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('upsell_items'),
    },
  ];

  const handleNewMenuClose = (selectedOption?: MenuOptionType) => {
    if (selectedOption === 'template') {
      setOpen(!open);
    } else {
      navigate(`/upsell-items/${selectedOption}`);
    }

    setAnchorEl(null);
  };

  return (
    <TitlebarRoot>
      <TitlebarTitleIcon>
        <SellOutlined />
      </TitlebarTitleIcon>

      <TitlebarBreadCrumb crumbs={crumbs} onClick={crumb => navigate(crumb.href)} />
      <TitlebarButtonExpanded onClick={e => setAnchorEl(e.currentTarget)}>
        <Add />
        {t('offer')}
        <ExpandMore />
      </TitlebarButtonExpanded>
      <UpsellNewMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        handleClose={handleNewMenuClose}
      />
      {upsellTemplatesResponse && (
        <UpsellTemplateModal open={open} setOpen={setOpen}>
          {upsellTemplatesResponse.data?.map((child: UpsellTemplate) => (
            <UpsellTemplateCard
              key={child.id}
              id={child.id}
              title={child.name}
              description={child.description}
              category={child.category}
              image={child.imageUrl}
              price={child.price}
            />
          ))}
        </UpsellTemplateModal>
      )}
    </TitlebarRoot>
  );
}
