// @ts-nocheck
import { SideDrawer } from 'Common/Drawer/SideDrawer';
import { LinkAccount } from 'Pages/Account/LinkedAccounts/LinkAccount';
import AddPeople from 'Pages/AddPeople/AddPeople';
import AddNewPropertyForm from 'Pages/AddProperty/AddNewPropertyForm';
import AddReservation from 'Pages/AddReservation/AddReservation';
import PropertyAlertsSlide from 'Pages/Dashboard/PropertyAlertsSlide';
import EditAccessCodes from 'Pages/EditAccessCodes/EditAccessCodes';
import EditPeople from 'Pages/EditPeople/EditPeople';
import EditReservations from 'Pages/EditReservations/EditReservations';
import SendGPLinkForm from 'Pages/Guests/SendGPLinkForm';
import IntercomEditContainer from 'code/IntercomEditContainer';
import PropertyEventsContainer from 'event/PropertyEventsContainer';
import MemberListContainer from 'member/MemberListContainer';
import MemberUnitsSlider from 'member/SlideBars/MemberUnitsSlider';
import PropertyListContainer from 'property/PropertyListContainer';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { toggleSlidebar as toggleSlidebarAction } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ReservationListContainer from 'reservation/ReservationListContainer';
import { getRightSlider } from 'shared/state/sharedSelectors';
import { SlidebarType } from 'types/ui';

export const SlidebarWidth = {
  TWENTY: '20%',
  THIRTY: '30%',
  FORTY: '40%',
  SIXTY: '60%',
  EIGHTY: '80%',
  HUNDRED: '100%',
};

const RightSlidebar = () => {
  const sliderState = useAppSelector(getRightSlider);
  const [prevSliderState, setPrevSliderState] = React.useState<SlidebarType>(SlidebarType.CLOSE);
  const sliderAction: SlidebarType = Object.keys(sliderState)[0] as SlidebarType;
  const sliderContent = sliderState[sliderAction]?.content;
  const [dynamicSliderWidth, setDynamicSliderWidth] = React.useState<typeof SlidebarWidth>(
    SlidebarWidth.SIXTY,
  );

  const sliderCloser = sliderState[sliderAction]?.onClose;
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (sliderAction !== SlidebarType.CLOSE) {
      setPrevSliderState(sliderAction);
    }
  }, [sliderAction]);

  const SmallSideBar = [
    SlidebarType.PROPERTY_EVENTS,
    SlidebarType.CHECK_OUT_RESERVATIONS,
    SlidebarType.CHECK_IN_RESERVATIONS,
    SlidebarType.AVAILABLE_CLEANING,
    SlidebarType.SHOW_PROPERTY_MEMBERS,
  ];

  const handleSliderWidth = (width: typeof SlidebarWidth) => {
    setDynamicSliderWidth(width);
  };

  const sideDrawerWidth = (slideType: SlidebarType) => {
    if (isMobile) return SlidebarWidth.HUNDRED;
    switch (slideType) {
      case SlidebarType.PROPERTY_EVENTS:
        return SlidebarWidth.TWENTY;
      case SlidebarType.ADD_NEW_RESERVATION:
        return SlidebarWidth.THIRTY;
      case SlidebarType.EDIT_RESERVATIONS:
        return SlidebarWidth.THIRTY;
      case SlidebarType.SEND_GP_LINK:
        return SlidebarWidth.FORTY;
      case SlidebarType.SHOW_PROPERTY_ALERTS:
        return SlidebarWidth.SIXTY;
      case SlidebarType.AVAILABLE_CLEANING:
      case SlidebarType.CHECK_IN_RESERVATIONS:
      case SlidebarType.CHECK_OUT_RESERVATIONS:
      case SlidebarType.SHOW_PROPERTY_MEMBERS:
        return SlidebarWidth.EIGHTY;
      case SlidebarType.LINK_MULTIPLE_ACCOUNTS:
        return SlidebarWidth.THIRTY;
      case SlidebarType.CLOSE:
        return SmallSideBar.includes(prevSliderState) ? SlidebarWidth.TWENTY : SlidebarWidth.SIXTY;
      case SlidebarType.ADD_NEW_MEMBERS:
        return dynamicSliderWidth;
      default:
        return SlidebarWidth.SIXTY;
    }
  };

  const handleClose = () => {
    dispatch(toggleSlidebarAction(SlidebarType.CLOSE, ''));
    if (sliderCloser && typeof sliderCloser === 'function') {
      sliderCloser();
    }
  };

  const renderContent = () => {
    switch (sliderAction) {
      case SlidebarType.ASSIGN_MEMBERS_TO_UNITS:
        return <MemberUnitsSlider handleClose={handleClose} />;
      case SlidebarType.ADD_NEW_PROPERTY:
        return <AddNewPropertyForm handleClose={handleClose} />;
      case SlidebarType.SHOW_PROPERTY_ALERTS:
        return <PropertyAlertsSlide handleClose={handleClose} />;
      case SlidebarType.ADD_NEW_MEMBERS:
        return <AddPeople handleClose={handleClose} handleSliderWidth={handleSliderWidth} />;
      case SlidebarType.ADD_NEW_RESERVATION:
        return <AddReservation handleClose={handleClose} />;
      case SlidebarType.EDIT_RESERVATIONS:
        return (
          <EditReservations
            reservationId={sliderContent?.reservationId}
            handleClose={handleClose}
          />
        );
      case SlidebarType.EDIT_MEMBERS:
        return (
          <EditPeople
            memberId={sliderContent.id}
            unitOnly={sliderContent.unitOnly}
            tabNum={sliderContent.tabNum}
            handleClose={handleClose}
          />
        );
      case SlidebarType.CHECK_OUT_RESERVATIONS:
        return (
          <ReservationListContainer roomStatus='guest-checking-out' handleClose={handleClose} />
        );
      case SlidebarType.CHECK_IN_RESERVATIONS:
        return (
          <ReservationListContainer roomStatus='guest-checking-in' handleClose={handleClose} />
        );
      case SlidebarType.EDIT_INTERCOM:
        return (
          <IntercomEditContainer propertyId={sliderContent?.propertyId} handleClose={handleClose} />
        );
      case SlidebarType.SHOW_PROPERTY_MEMBERS:
        return (
          <MemberListContainer
            propertyId={sliderContent?.propertyId}
            memberId={sliderContent?.memberId}
            handleClose={handleClose}
          />
        );
      case SlidebarType.AVAILABLE_CLEANING:
        return <PropertyListContainer roomStatus='guest-checking-out' handleClose={handleClose} />;
      case SlidebarType.EDIT_ACCESS_CODES:
        return (
          <EditAccessCodes
            accessCodeId={sliderContent?.accessCodeId}
            propertyId={sliderContent?.propertyId}
            handleClose={handleClose}
          />
        );
      case SlidebarType.PROPERTY_EVENTS:
        return <PropertyEventsContainer propertyId={sliderContent?.propertyId} />;
      case SlidebarType.LINK_MULTIPLE_ACCOUNTS:
        return <LinkAccount handleClose={handleClose} />;
      case SlidebarType.SEND_GP_LINK:
        return (
          <SendGPLinkForm
            reservationId={sliderContent?.reservationId}
            gpLink={sliderContent?.gpLink}
            handleClose={handleClose}
          />
        );
      default:
    }
  };

  return (
    <SideDrawer
      PaperProps={{
        sx: slideDrawerStyle(sideDrawerWidth(sliderAction)),
      }}
      open={sliderAction !== SlidebarType.CLOSE && !!sliderAction}
      onClose={handleClose}
    >
      {renderContent()}
    </SideDrawer>
  );
};

const slideDrawerStyle = (width: string) => ({
  width,
  transition: 'all 0.2s ease-out !important',
});

export default RightSlidebar;
