// @ts-nocheck
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { SettingCard } from '@operto/ui-library';
import { ColorPicker, getValidColor } from 'Common/ColorPicker';
import ImageEditor from 'Common/Templates/Editors/ImageEditor';
import InlineTextEditor from 'Common/Templates/Editors/InlineTextEditor';
import InsertVariablesMenu from 'Pages/Variables/Common/InsertVariablesMenu';
import { getMessengerSetting } from 'company/state/companyAction';
import { messengerSettingSelector } from 'company/state/companySelectors';
import { domainIsProd } from 'helper/helper';
import React, { useEffect, useRef, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { IPage, useGuestPortal } from 'redux/guestportal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styled from 'styled-components';
import ImageManagerDialog from 'ui-library/Components/dialogs/ImageManagerDialog';
import ScriptDropDownMenu from 'ui-library/Components/drop-down/ScriptDropDownMenu';
import ImageThumbnails from 'ui-library/Components/misc/ImageThumbnails';
import { TextInsert } from 'ui-library/Helpers/textHelper';
import { GuidesDetailsPagesFormProps } from './GuidesDetailsPagesForm';
import { GuidesDetailsPagesFormTabEditMedia } from './GuidesDetailsPagesFormTabEditMedia';

export enum EditElements {
  None = 0,
  Media,
  Title,
  Description,
  Button,
}

type PreviewCardProps = GuidesDetailsPagesFormProps & {
  onElementClick: (element: EditElements) => void;
};
const PreviewCard: React.FC<PreviewCardProps> = props => {
  const { page, onPageChange, onElementClick } = props;
  const { button } = page;
  return (
    <MainContainer title='Page Builder' subtitle='Select an element to edit'>
      <GuidesDetailsPagesFormTabEditMedia {...props} />
      <Box
        sx={{ padding: 0 }}
        onClick={() => onElementClick(EditElements.Title)}
        style={{ marginTop: 30 }}
        data-testid='media-edit'
      >
        <Typography variant='h3'>
          {page.title.length > 0 ? page.title : "Click to edit your page's title or subtitle"}
        </Typography>
        <Typography variant='body1' style={{ marginTop: 10 }}>
          {page.subtitle?.length > 0 ? page.subtitle : 'Subtitle'}
        </Typography>
      </Box>
      <Box
        onClick={() => onElementClick(EditElements.Description)}
        style={{ marginTop: 30, width: '500px' }}
      >
        {page.description.length > 0 ? (
          // <InlineTextEditor editable={false} value={page.description} />
          <DescriptionPreview dangerouslySetInnerHTML={{ __html: page.description }} />
        ) : (
          <Typography variant='body2' style={{ marginTop: 20, minWidth: '500px' }}>
            Click to edit your page text. You may use Bold or Line breaks when editing text. You may
            add text as much as you need but your guests may not want to read more than 240
            characters. Try using video or images to enhance your content and reduce your word
            count.
          </Typography>
        )}
      </Box>
      <Grid container justifyContent='space-around' style={{ marginTop: 30 }}>
        <Grid item>
          {page.button.enabled && (
            <Button
              style={{
                backgroundColor: getValidColor(String(page.button.color)),
                color: 'white',
              }}
              variant='contained'
              size='large'
              onClick={() => onElementClick(EditElements.Button)}
            >
              {page.button.title && page.button.title.length > 0 ? page.button.title : 'Button'}
            </Button>
          )}
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={page.button.enabled}
                onChange={e => {
                  const currentEl = button.enabled ? null : EditElements.Button;
                  onElementClick(currentEl);
                  onPageChange?.({
                    ...page,
                    button: {
                      ...page.button,
                      enabled: e.target.checked,
                    },
                  });
                }}
                name='checkedB'
                color='primary'
              />
            }
            label='Add a button to this page'
          />
        </Grid>
      </Grid>
    </MainContainer>
  );
};

const EditElementsMediaCard: React.FC<GuidesDetailsPagesFormProps> = props => {
  const { page, onPageChange } = props;
  const { uploadGuideImages } = useGuestPortal();
  const [selectedImage, setSelectedImage] = useState<string>('');

  const handleOnDrop = async (pictureDataURLs: string) => {
    const urls = await uploadGuideImages([pictureDataURLs]);
    const combinedUrls = page.images_url ? [...page.images_url, ...urls] : urls;
    onPageChange({
      ...page,
      images_url: combinedUrls,
    });
  };

  return (
    <SettingCard
      title='Edit Elements'
      subtitle='Video'
      description='Paste your Youtube or Vimeo link here.'
      data-testid='edit-elements-media-card'
    >
      {selectedImage && (
        <ImageManagerDialog
          open={selectedImage !== ''}
          imageCropAspectRatio={1.6}
          imageUrl={selectedImage}
          onClose={() => setSelectedImage('')}
          onSave={async (imageData: string) => {
            const urls = await uploadGuideImages([imageData]);
            const imgIndex = page.images_url.indexOf(selectedImage);
            let imageArray = page.images_url.map((url: string) =>
              url === selectedImage ? urls[0] : url,
            );
            if (imgIndex === -1) {
              imageArray = [...page.images_url, ...urls];
            }
            onPageChange?.({
              ...page,
              images_url: imageArray,
            });
            setSelectedImage('');
          }}
        />
      )}

      <TextField
        style={{ marginTop: 20 }}
        label='Video URL'
        placeholder='Enter video link'
        variant='outlined'
        fullWidth
        value={page.video_url}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onPageChange?.({
            ...page,
            video_url: e.target.value,
          })
        }
      />
      <Typography variant='body2' align='left' style={{ marginTop: 20, marginBottom: 20 }}>
        Upload PNGs, JPG, JPEG, or GIF
      </Typography>
      <ImageEditor onDrop={handleOnDrop} />
      <ImageThumbnails
        images={page.images_url}
        onEdit={(url: string) => {
          setSelectedImage(url);

          // editImage(url, output => {
          //   const updatedFiles = [...files];

          //   // replace original image with new image
          //   updatedFiles[index] = output;

          //   // revoke preview URL for old image
          //   if (file.preview) URL.revokeObjectURL(file.preview);

          //   // set new preview URL
          //   Object.assign(output, {
          //     preview: URL.createObjectURL(output),
          //   });

          //   // update view
          //   setFiles(updatedFiles);
          // });
        }}
        onDelete={(url: string) => {
          onPageChange({
            ...page,
            images_url: page.images_url.filter(u => url !== u),
          });
        }}
      />
    </SettingCard>
  );
};

const EditElementsTitleCard: React.FC<GuidesDetailsPagesFormProps> = props => {
  const { page, onPageChange } = props;
  const [titleInputAnchorEl, setTitleInputAnchorEl] = useState<null | HTMLElement>(null);
  const [currentTitleCursorPos, setCurrentTitleCursorPos] = useState(0);
  const pageInputRef = useRef();

  const [subtitleInputAnchorEl, setSubtitleInputAnchorEl] = useState<null | HTMLElement>(null);
  const [currentSubtitleCursorPos, setCurrentsubTitleCursorPos] = useState(0);
  const subTitleInputRef = useRef();

  return (
    <MainContainer
      title='Edit Elements'
      subtitle='Page Title'
      description='Your title will be your menu label'
    >
      <TextContainer>
        <TextField
          inputRef={pageInputRef}
          style={{ marginTop: 20, paddingRight: '10px' }}
          label='Page Title'
          placeholder="Enter page's title"
          onBlur={e => {
            setCurrentTitleCursorPos(pageInputRef?.current?.selectionEnd);
            setTitleInputAnchorEl(e.currentTarget);
          }}
          onClick={e => {
            setCurrentTitleCursorPos(pageInputRef?.current?.selectionEnd);
            setTitleInputAnchorEl(e.currentTarget);
          }}
          variant='outlined'
          fullWidth
          value={page.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTitleInputAnchorEl(e.currentTarget);
            onPageChange?.({
              ...page,
              title: e.target.value,
            });
          }}
        />
        <ScriptDropDownMenu
          marginTop={20}
          onTextInsert={(text: string) => {
            onPageChange?.({
              ...page,
              title: TextInsert(page.title, text, currentTitleCursorPos),
            });
          }}
          addVariable
        />
        <InsertVariablesMenu
          anchorEl={titleInputAnchorEl ?? pageInputRef?.current}
          targetText={page.title}
          onVariableSelectedUpdate={(newText: string) =>
            onPageChange?.({
              ...page,
              title: newText,
            })
          }
          currentCursorPosition={currentTitleCursorPos}
        />
      </TextContainer>
      <Typography variant='body2' align='left' style={{ marginTop: 20 }}>
        Subtitle
      </Typography>
      <Typography variant='body2' align='left'>
        Leave blank if not needed
      </Typography>
      <TextContainer>
        <TextField
          style={{ marginTop: 20, paddingRight: '10px' }}
          label='Page Subtitle'
          placeholder="Enter page's subtitle"
          variant='outlined'
          fullWidth
          inputRef={subTitleInputRef}
          onBlur={e => {
            setSubtitleInputAnchorEl(e.currentTarget);
            setCurrentsubTitleCursorPos(subTitleInputRef?.current?.selectionEnd);
          }}
          value={page.subtitle}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSubtitleInputAnchorEl(e.currentTarget);
            onPageChange?.({
              ...page,
              subtitle: e.target.value,
            });
          }}
          onClick={e => {
            setSubtitleInputAnchorEl(e.currentTarget);
            setCurrentsubTitleCursorPos(subTitleInputRef?.current?.selectionEnd);
          }}
        />
        <ScriptDropDownMenu
          marginTop={20}
          onTextInsert={(text: string) => {
            onPageChange?.({
              ...page,
              subtitle: TextInsert(page.subtitle, text, currentSubtitleCursorPos),
            });
          }}
          addVariable
        />
        <InsertVariablesMenu
          anchorEl={subtitleInputAnchorEl ?? subTitleInputRef?.current}
          targetText={page.subtitle}
          onVariableSelectedUpdate={(newText: string) =>
            onPageChange?.({
              ...page,
              subtitle: newText,
            })
          }
          currentCursorPosition={currentSubtitleCursorPos}
        />
      </TextContainer>
    </MainContainer>
  );
};

const InlineTextEditorSxProps: SxProps = {
  minHeight: '510px',
  marginRight: '10px',
  paddingTop: '8px',
  '.DraftEditor-root': { minHeight: '490px' },
};

const EditElementsDescriptionCard: React.FC<GuidesDetailsPagesFormProps> = props => {
  const { page, onPageChange } = props;

  return (
    <SettingCard
      title='Edit Elements'
      subtitle='Main Text'
      description='Highlight to link, bold, bullet and more'
    >
      <InlineTextEditor
        value={page.description}
        onTextChange={text => {
          onPageChange?.({
            ...page,
            description: text,
          });
        }}
        style={InlineTextEditorSxProps}
      />
    </SettingCard>
  );
};

const EditElementsButtonCard: React.FC<GuidesDetailsPagesFormProps> = props => {
  const { page, onPageChange } = props;
  const messengerSetting = useAppSelector(messengerSettingSelector());
  const gpSmartButtonMessengerEnabled = messengerSetting.gp_smart_button_messenger_enabled;
  const [currentCursorPos, setCurrentCursorPos] = useState(0);
  const subjectInputRef = useRef();
  const btnTextInputRef = useRef();
  const autoReplyInputRef = useRef();
  return (
    <MainContainer title='Edit Elements' subtitle='Button Settings'>
      {!domainIsProd() && gpSmartButtonMessengerEnabled && (
        <FormControlLabel
          control={
            <Checkbox
              checked={page.button.messenger_enabled}
              onChange={e => {
                onPageChange?.({
                  ...page,
                  button: {
                    ...page.button,
                    messenger_enabled: e.target.checked,
                  },
                });
              }}
              name='checkedB'
              color='primary'
            />
          }
          label='Trigger Messenger'
        />
      )}

      {domainIsProd() && (
        <FormControlLabel
          control={
            <Checkbox
              checked={page.button.messenger_enabled}
              onChange={e => {
                onPageChange?.({
                  ...page,
                  button: {
                    ...page.button,
                    messenger_enabled: e.target.checked,
                  },
                });
              }}
              name='checkedB'
              color='primary'
            />
          }
          label='Trigger Messenger'
        />
      )}

      {page.button.messenger_enabled ? (
        <>
          <Typography variant='body2' align='left'>
            Provide a subject for this message.
            <br /> ie: Bike Rentals
          </Typography>
          <TextContainer style={{ padding: '10px 0px 5px 0px' }}>
            <TextField
              required
              inputRef={subjectInputRef}
              id='outlined-required'
              onBlur={() => setCurrentCursorPos(subjectInputRef?.current?.selectionEnd)}
              label='Request Subject'
              placeholder='Premium TV'
              variant='outlined'
              fullWidth
              style={{ padding: '0px 5px 0px 0px' }}
              value={page.button.subject}
              onChange={e => {
                onPageChange?.({
                  ...page,
                  button: {
                    ...page.button,
                    subject: e.target.value,
                  },
                });
              }}
            />
            <ScriptDropDownMenu
              marginTop={0}
              onTextInsert={(text: string) => {
                onPageChange?.({
                  ...page,
                  button: {
                    ...page.button,
                    subject: TextInsert(page.button.subject, text, currentCursorPos),
                  },
                });
              }}
            />
          </TextContainer>
          <TextContainer style={{ padding: '10px 0px 5px 0px' }}>
            <TextField
              required
              id='outlined-required'
              label='Button Text'
              inputRef={btnTextInputRef}
              placeholder='Order Premium TM'
              style={{ padding: '0px 5px 0px 0px' }}
              onBlur={() => setCurrentCursorPos(btnTextInputRef?.current?.selectionEnd)}
              variant='outlined'
              value={page.button.title}
              onChange={e => {
                onPageChange?.({
                  ...page,
                  button: {
                    ...page.button,
                    title: e.target.value,
                  },
                });
              }}
            />{' '}
            <ScriptDropDownMenu
              marginTop={0}
              onTextInsert={(text: string) => {
                onPageChange?.({
                  ...page,
                  button: {
                    ...page.button,
                    title: TextInsert(page.button.title, text, currentCursorPos),
                  },
                });
              }}
            />
          </TextContainer>
          <Typography variant='body2' align='left'>
            Your Auto Reply will be shown to the guest when they’ve sent their message.
          </Typography>
          <AutoReplyContainer style={{ padding: '10px 0px 5px 0px' }}>
            <TextField
              id='outlined-multiline-static'
              label='Auto Reply'
              multiline
              rows={6}
              fullWidth
              style={{ padding: '0px 5px 0px 0px' }}
              inputRef={autoReplyInputRef}
              onBlur={() => setCurrentCursorPos(autoReplyInputRef?.current?.selectionEnd)}
              placeholder='Your request for adding Premium Movies to your room has been received and will processed within 45 minutes.'
              variant='outlined'
              value={page.button.autoreply_message}
              onChange={e => {
                onPageChange?.({
                  ...page,
                  button: {
                    ...page.button,
                    autoreply_message: e.target.value,
                  },
                });
              }}
            />
            <ScriptDropDownMenu
              marginTop={0}
              onTextInsert={(text: string) => {
                onPageChange?.({
                  ...page,
                  button: {
                    ...page.button,
                    autoreply_message: TextInsert(
                      page.button.autoreply_message,
                      text,
                      currentCursorPos,
                    ),
                  },
                });
              }}
            />
          </AutoReplyContainer>
        </>
      ) : (
        <>
          <TextField
            style={{ marginTop: 20 }}
            label='Web / Email Address'
            placeholder='Enter your URL or Email'
            variant='outlined'
            fullWidth
            value={page.button.hyperlink}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onPageChange?.({
                ...page,
                button: {
                  ...page.button,
                  hyperlink: e.target.value,
                },
              })
            }
          />
          <TextField
            style={{ marginTop: 20, marginBottom: 20 }}
            label='Display Text'
            placeholder='Book Now'
            variant='outlined'
            fullWidth
            value={page.button.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onPageChange?.({
                ...page,
                button: {
                  ...page.button,
                  title: e.target.value,
                },
              })
            }
          />
        </>
      )}

      <Typography variant='body2' align='left'>
        Button Color
      </Typography>
      <ColorPicker
        value={String(page.button.color)}
        onChange={newValue =>
          onPageChange?.({
            ...page,
            button: {
              ...page.button,
              color: newValue,
            },
          })
        }
      />
    </MainContainer>
  );
};

type GuidesDetailsPagesFormTabEditProps = GuidesDetailsPagesFormProps & {
  page: IPage;
  onPageChange?: (page: IPage) => void;
};

const GuidesDetailsPagesFormTabEdit: React.FC<GuidesDetailsPagesFormTabEditProps> = props => {
  const [currentEl, setCurrentEl] = useState(EditElements.Title);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMessengerSetting());
  }, [dispatch]);

  return (
    <Grid container justifyContent='space-between' style={{ width: '100%', minWidth: '1366px' }}>
      <Grid item style={{ width: '40%' }}>
        <PreviewCard {...props} onElementClick={setCurrentEl} />
      </Grid>
      <Grid item style={{ width: '58%' }}>
        {currentEl === EditElements.Media && <EditElementsMediaCard {...props} />}
        {currentEl === EditElements.Title && <EditElementsTitleCard {...props} />}
        {currentEl === EditElements.Description && <EditElementsDescriptionCard {...props} />}
        {currentEl === EditElements.Button && <EditElementsButtonCard {...props} />}
      </Grid>
    </Grid>
  );
};

const MainContainer = styled(SettingCard)`
  && {
    width: 500px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;
const AutoReplyContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const DescriptionPreview = styled.div`
  width: 500px;
  padding-right: 20px;
  word-break: break-all;
  margin-right: 10px;
`;

export default GuidesDetailsPagesFormTabEdit;
