import { TemplateData, TemplateDataType } from '@operto/communications-shared';
import { trpc } from '@operto/trpc-client';
import { companySelector } from 'company/state/companySelectors';
import { addDays, format } from 'date-fns';
import { SupportedLocale } from 'hooks/useTranslation';
import { useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';

export type CommunicationType = {
  id: string;
  name: string;
  channelType: ChannelType;
  locales: SupportedLocale[];
  contentId: string;
};

export default function useCommunications(filters?: useCommunicationsProps) {
  const company = useAppSelector(companySelector());
  const trpcUtils = trpc.useUtils();
  const { channels, locales } = filters || {};

  const {
    data,
    isFetching,
    isLoading,
  }: { data?: TemplateDataType[]; isFetching: boolean; isLoading: boolean } =
    trpc.communications?.getTemplates.useQuery(
      { companyId: `${company.id}`, filterParams: { channels, locales } },
      {
        retry(failureCount, error) {
          // retry if not unauth, forbidden or not found
          return failureCount < 3 && ![401, 403, 404].includes(error.data.httpStatus);
        },
      },
    );

  const communications: CommunicationType[] = useMemo(
    () =>
      data?.map(communication => {
        const channelTemplate = communication?.contents?.[0];

        return {
          id: communication.id,
          name: communication?.name,
          channelType: channelTemplate?.channelType,
          locales: channelTemplate?.locales,
          contentId: channelTemplate?.id,
        };
      }),
    [data],
  );

  const createTemplateMutation = trpc?.communications?.createTemplates?.useMutation();
  const createTemplate = async (template: TemplateData) => {
    return await new Promise((resolve: (result: TemplateData) => void, reject) => {
      createTemplateMutation?.mutate(template, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const createContentsMutation = trpc?.communications?.createContents?.useMutation();
  const createContents = async (template: TemplateData) => {
    return await new Promise((resolve, reject) => {
      createContentsMutation?.mutate(template, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const updateTemplateMutation = trpc?.communications?.updateTemplate?.useMutation();
  const updateTemplate = async (template: TemplateData) => {
    return await new Promise((resolve, reject) => {
      updateTemplateMutation?.mutate(template, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const updateContentsMutation = trpc?.communications?.updateContent?.useMutation();
  const updateContents = async (template: TemplateData) => {
    return await new Promise((resolve, reject) => {
      updateContentsMutation?.mutate(template, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const communicationsRefetch = () =>
    trpcUtils.communications.getTemplates.refetch({ companyId: `${company.id}` });

  const templateRefetch = (templateId: string) =>
    trpcUtils.communications.getTemplate.refetch({
      companyId: `${company.id}`,
      templateId: templateId,
    });

  const getTemplate = (templateId: string): TemplateData =>
    trpc.communications.getTemplate.useQuery(
      {
        companyId: `${company.id}`,
        templateId: templateId,
      },
      { enabled: !!templateId },
    );

  return {
    isFetching,
    isLoading,
    data: communications,
    createTemplate,
    createContents,
    updateTemplate,
    updateContents,
    communicationsRefetch,
    getTemplate,
    templateRefetch,
  };
}

// Hard coded mock data for testing
export const sendPreviewMockVariables: Record<string, string> = {
  'Guest Name': 'Jane Doe',
  'Guest Email': 'guest@email.com,',
  'Guest Phone': '+1234567890',
  'Reservation check-in time': format(new Date(), 'h:mm a MMMM d, yyyy')
    .replace('am', 'AM')
    .replace('pm', 'PM'),
  'Reservation check-out time': format(addDays(new Date(), 3), 'h:mm a MMMM d, yyyy')
    .replace('am', 'AM')
    .replace('pm', 'PM'),
  'Property Name': 'Property Name',
  'Property Address': '1234 Main St',
  'Confirmation Code': 'BX-1234A',
  'Access Code': '1234',
  'Checkout Code': '11234',
  'Guest Portal URL': 'https://guest.operto.com/portal/BX-1234A',
  'Yourkey URL': 'https://guest.operto.com/yourkey/BX-1234A',
};

// Transform the message with the mock variables
export const transformMockVariables = (message: string): string => {
  let text = message;

  Object.keys(sendPreviewMockVariables).forEach(key => {
    text = text.replaceAll(`*{{${key}}}*`, sendPreviewMockVariables[key]);
    text = text.replaceAll(
      `*{{${key.replace(/\s+/g, '_').toLowerCase()}}}*`,
      sendPreviewMockVariables[key],
    );
  });

  return text;
};

type useCommunicationsProps = { channels?: ChannelType[]; locales?: SupportedLocale[] };
export type ChannelType = 'email' | 'sms';
