import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';

interface AutomateActionMenuProps {
  onClickEdit: () => void;
  onClickDelete: () => void;
}

const AutomateActionMenu = ({ onClickEdit, onClickDelete }: AutomateActionMenuProps) => {
  const [actionsMenuEl, setActionsMenuEl] = useState<HTMLButtonElement | null>(null);

  const { t } = useTranslation();

  return (
    <>
      <IconButton
        data-testid='action-icon-btn'
        onClick={e => setActionsMenuEl(e.currentTarget)}
        size='large'
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={actionsMenuEl}
        open={Boolean(actionsMenuEl)}
        onClose={() => setActionsMenuEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onClickEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t('edit_workflow')}</ListItemText>
        </MenuItem>

        <MenuItem onClick={onClickDelete}>
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>
          <ListItemText>{t('delete_workflow')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AutomateActionMenu;
