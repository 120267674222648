import { getOfferByID, getOfferTemplateByID } from 'api/addOnAPI';
import { getActiveAlerts, getCompanyInformation } from 'company/state/companyAction';
import { companySelector } from 'company/state/companySelectors';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import TabBar from 'ui-library/Components/tabBar/TabBar';
import UpsellDashboardContainer from './UpsellDashboardContainer';
import UpsellPageTitlebar from './UpsellPageTitlebar';

const api = process.env.REACT_APP_NEO_REST_API;

export interface UpsellFields {
  id: string;
  name: string | null;
  priceSticker: string;
  imageUrl: string;
  isActive: boolean;
  information: {
    name: string | null;
    header: string | null;
    subheader: string | null;
    description: string | null;
    category: string | null;
  };
  media: {
    files: string[];
  };
  date: {
    header: string;
    datePickerType: string;
    active: boolean;
  };
  preferredTime: {
    header: string;
    active: boolean;
  };
  serviceButton: {
    values: string;
  };
  postPurchase: {
    instructions: string | null;
    active: boolean;
  };
  pricing: {
    price: number | null;
    taxRate: number | null;
    paymentType: string; // free, single, link
    confirmationMethod: string; // cash, instant, request
    quantityStepperEnabled: boolean;
    maxQuantity: number | null;
    quantityName: string | null;
    affiliateLink: string | null;
    affiliateCustomHeader: string | null;
    affiliateCustomHeaderEnabled: boolean | null;
    customButtonText: string | null;
  };
  unitAssigned: {
    units: number[];
  };
  groupsAssigned: {
    groups: number[];
  };
  units: string[] | null;
  groups: string[] | null;
  isGloballyAssigned: boolean | null;
}

const upperTabItems = [
  {
    label: 'Content',
    value: 'content',
    isDropDown: false,
  },
  {
    label: 'Pricing and quantity',
    value: 'pricing',
    isDropDown: false,
  },
  {
    label: 'Unit assignment',
    value: 'unitAssignment',
    isDropDown: false,
  },
  {
    label: 'Post-purchase instructions',
    value: 'instructions',
    isDropDown: false,
  },
];

const isEqual = (obj1: UpsellFields, obj2: UpsellFields) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const UpsellsPage = () => {
  const { snackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [filterType, setFilterType] = useState(upperTabItems[0].value);
  const { id } = useParams<{ id: string }>();
  const company = useAppSelector(companySelector());
  const [currentUpsell, setCurrentUpsell] = useState<UpsellFields>(null);
  const [upsellFields, setUpsellFields] = useState<UpsellFields>({
    id,
    name: null,
    priceSticker: null,
    imageUrl: null,
    isActive: false,
    information: {
      name: null,
      header: null,
      subheader: null,
      description: null,
      category: null,
    },
    media: {
      files: [],
    },
    date: {
      header: '',
      datePickerType: 'singleDate',
      active: false,
    },
    preferredTime: {
      header: '',
      active: false,
    },
    serviceButton: {
      values: '',
    },
    postPurchase: {
      instructions: null,
      active: false,
    },
    pricing: {
      price: null,
      taxRate: null,
      paymentType: null,
      confirmationMethod: null,
      quantityStepperEnabled: false,
      maxQuantity: null,
      quantityName: null,
      affiliateLink: null,
      affiliateCustomHeader: null,
      affiliateCustomHeaderEnabled: null,
      customButtonText: null,
    },
    unitAssigned: {
      units: [],
    },
    groupsAssigned: {
      groups: [],
    },
    units: [],
    groups: [],
    isGloballyAssigned: null,
  });

  const fetchUpsellData = useCallback(async () => {
    try {
      // TODO: Replace with actual API endpoint
      const result = await getOfferByID(company?.id, id);
      const data = result?.data?.data;
      setCurrentUpsell(data);
    } catch (error) {
      console.error('Error fetching upsell data:', error);
    }
  }, [id, company?.id]);

  const fetchUpsellTemplateData = useCallback(async (templateId: string) => {
    try {
      const result = await getOfferTemplateByID(templateId);
      const data = result?.data?.data;
      setUpsellFields(data);
    } catch (error) {
      console.error('Error fetching template upsell data:', error);
    }
  }, []);

  useEffect(() => {
    if (id && id.endsWith('template')) {
      fetchUpsellTemplateData(id);
    } else if (id && id !== 'new') {
      fetchUpsellData();
    } else {
      // set default values for new upsell
      updateUpsellFields('isGloballyAssigned', false);
      // TODO: remove default image url when media upload working
      updateUpsellFields('imageUrl', 'https://picsum.photos/343/205');
    }
  }, [id, fetchUpsellData, fetchUpsellTemplateData]);

  useEffect(() => {
    if (currentUpsell) {
      setUpsellFields({
        id: currentUpsell?.id || id,
        name: currentUpsell?.name || null,
        priceSticker: currentUpsell?.priceSticker || '',
        imageUrl: currentUpsell?.imageUrl || '',
        isActive: currentUpsell?.isActive || false,
        information: {
          name: currentUpsell.information?.name || null,
          header: currentUpsell.information?.header || null,
          subheader: currentUpsell.information?.subheader || null,
          description: currentUpsell.information?.description || null,
          category: currentUpsell.information?.category || null,
        },
        media: {
          files: currentUpsell.media?.files || [],
        },
        date: {
          header: currentUpsell.date?.header || '',
          datePickerType: currentUpsell.date?.datePickerType || 'singleDate',
          active: currentUpsell.date?.active || false,
        },
        preferredTime: {
          header: currentUpsell.preferredTime?.header || '',
          active: currentUpsell.preferredTime?.active || false,
        },
        serviceButton: {
          values: currentUpsell.serviceButton?.values || '',
        },
        postPurchase: {
          instructions: currentUpsell.postPurchase?.instructions || null,
          active: currentUpsell.postPurchase?.active || false,
        },
        pricing: {
          price: currentUpsell.pricing?.price || null,
          taxRate: currentUpsell.pricing?.taxRate || null,
          paymentType: currentUpsell.pricing?.paymentType || null,
          confirmationMethod: currentUpsell.pricing?.confirmationMethod || null,
          quantityName: currentUpsell.pricing?.quantityName || null,
          quantityStepperEnabled: currentUpsell.pricing?.quantityStepperEnabled || null,
          maxQuantity: currentUpsell.pricing?.maxQuantity || null,
          affiliateLink: currentUpsell.pricing?.affiliateLink || null,
          affiliateCustomHeader: currentUpsell.pricing?.affiliateCustomHeader || null,
          affiliateCustomHeaderEnabled: currentUpsell.pricing?.affiliateCustomHeaderEnabled || null,
          customButtonText: currentUpsell.pricing?.customButtonText || null,
        },
        unitAssigned: {
          units: currentUpsell.unitAssigned?.units || [],
        },
        groupsAssigned: {
          groups: currentUpsell.groupsAssigned?.groups || [],
        },
        units: currentUpsell.units,
        groups: currentUpsell.groups,
        isGloballyAssigned: currentUpsell.isGloballyAssigned,
      });
    }
  }, [currentUpsell, id]);

  useEffect(() => {
    dispatch(getCompanyInformation());
    dispatch(getActiveAlerts());
  }, [dispatch]);

  const handleTabIndexChange = (value: number) => {
    setTabIndex(value);
    setFilterType(upperTabItems[value].value);
  };

  const updateUpsellFields = <K extends keyof UpsellFields>(field: K, value: UpsellFields[K]) => {
    setUpsellFields(prevFields => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const updateOffer = async () => {
    try {
      let path = `${api}/upsells/companies/${company?.id}/offers`;

      if (upsellFields.id !== 'new' && upsellFields.id !== 'template') {
        path += `/${upsellFields.id}`;
      }

      const response = await fetch(path, {
        method: upsellFields.id === 'new' || upsellFields.id === 'template' ? 'POST' : 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(upsellFields),
      });

      if (response.ok) {
        const data = await response.json();
        setCurrentUpsell(data.data);
        snackbar(t('changes_saved'));
      } else {
        const errorMessage = `Failed to ${
          upsellFields.id === 'new' || upsellFields.id === 'template' ? 'create' : 'save'
        } offer, status: ${response.status}`;
        logger.error(`errorMessage: ${errorMessage}, responseBody: ${response.body}`);
        snackbar(errorMessage);
      }
    } catch (error) {
      logger.error(`Failed to update offer, ${error}`);
      snackbar(t('failed_to_update_offer'));
    }
  };

  const updateUpsellName = (name: string) => {
    setUpsellFields(prevFields => ({
      ...prevFields,
      name,
    }));
  };

  const updateUpsellIsActive = (isActive: boolean) => {
    setUpsellFields(prevFields => ({
      ...prevFields,
      isActive,
    }));
  };

  return (
    <>
      <UpsellPageTitlebar
        title={upsellFields.name}
        isActive={upsellFields.isActive}
        onActiveChange={updateUpsellIsActive}
        onTitleChange={updateUpsellName}
        onSaveClick={updateOffer}
        saveDisabled={isEqual(upsellFields, currentUpsell)}
      />

      <TabBar tabItems={upperTabItems} tabIndex={tabIndex} onTabChange={handleTabIndexChange} />
      <UpsellDashboardContainer
        tabSelected={filterType}
        upsellFields={upsellFields}
        updateUpsellFields={updateUpsellFields}
      />
    </>
  );
};

export default UpsellsPage;
