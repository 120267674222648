import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  CardMedia,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  SxProps,
  Typography,
} from '@mui/material';
import { loadConnectAndInitialize, StripeConnectInstance } from '@stripe/connect-js';
import { ConnectComponentsProvider, ConnectPaymentDetails } from '@stripe/react-connect-js';
import { UpsellFields } from 'Pages/Marketplace/UpsellPage';
import { getOfferByID } from 'api/addOnAPI';
import { getCompanyData, getTransactionDetails } from 'api/paymentAPI';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useState } from 'react';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { getStatusColor, Order } from './OrdersTable';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const statusBoxStyle: SxProps = (status: string) => ({
  backgroundColor: getStatusColor(status),
  paddingVertical: '5px',
  paddingLeft: '8px',
  paddingRight: '8px',
  borderRadius: '5px',
  fontWeight: 600,
  fontSize: '10px',
});

type OrderDetailsSidebarProps = {
  order: Order;
  onClose: () => void;
};

// TODO: use transaction details type in payments-shared
type TransactionDetails = {
  transactionId: string;
  stripePaymentIntentId: string;
  stripeDestinationPaymentId: string;
  paymentMethodBrand: string;
  paymentMethodLastFour: string;
};

const OrderDetailsSidebar = ({ order, onClose }: OrderDetailsSidebarProps) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance | null>(
    null,
  );
  const [transactionDetails, setTransactionDetails] = useState<TransactionDetails | null>(null);
  const [transactionDetailsLoading, setTransactionDetailsLoading] = useState(false);
  const [transactionDetailsModalVisible, setTransactionDetailsModalVisible] = useState(false);

  const [offerDetail, setOfferDetail] = useState<UpsellFields | null>(null);
  const [offerDetailsLoading, setOfferDetailsLoading] = useState(false);

  const { snackbar } = useSnackbar();
  const { t } = useTranslation();

  // TODO: use zod client from payments-shared
  const fetchAccountSession = useCallback(async () => {
    if (!order) {
      return '';
    }

    const response = await getCompanyData(order.companyId, true);
    const clientSecret = String(response.data?.data?.stripe?.accountSessionSecret);

    if (clientSecret) {
      return clientSecret;
    } else {
      logger.error('Unable to fetch stripe account session secret for company.');
      return '';
    }
  }, [order]);

  // TODO: use zod client from payments-shared
  const fetchTransactionData = useCallback(async () => {
    if (!order) {
      return;
    }

    try {
      const response = await getTransactionDetails(order.companyId, order.transactionId);
      const transactionData = response.data.data;
      setTransactionDetails(transactionData);
    } catch (error) {
      logger.error('Error fetching transaction details', error);
    }

    setTransactionDetailsLoading(false);
  }, [order]);

  // TODO: use zod client from payments-shared
  const fetchOfferData = useCallback(async () => {
    const companyIdAsNumber = Number(order?.companyId);
    if (!order || !companyIdAsNumber) {
      return;
    }

    try {
      const response = await getOfferByID(companyIdAsNumber, order.offerId);
      const offerData = response.data.data;
      setOfferDetail(offerData);
    } catch (error) {
      logger.error('Error fetching offer details', error);
    }

    setOfferDetailsLoading(false);
  }, [order]);

  useEffect(() => {
    setStripeConnectInstance(
      loadConnectAndInitialize({
        publishableKey: publishableKey,
        fetchClientSecret: fetchAccountSession,
        appearance: {
          variables: {
            colorPrimary: '#228403', // optional appearance param,
          },
        },
      }),
    );
  }, [fetchAccountSession]);

  useEffect(() => {
    setOfferDetail(null);
    setOfferDetailsLoading(true);
    fetchOfferData();

    setTransactionDetails(null);
    setTransactionDetailsLoading(true);
    fetchTransactionData();
  }, [order, fetchOfferData, fetchTransactionData]);

  if (!order) return null;

  return (
    <Paper sx={containerStyle}>
      <Box sx={headerStyle}>
        <Typography variant='h6' sx={titleStyle}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
          {t('order_details')}
        </Typography>
      </Box>
      <Box sx={headerStyle}>
        <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
          {order?.name ?? 'N/A'}
        </Typography>
      </Box>
      <Box sx={sectionStyle}>
        <Button sx={{ width: '48%' }} variant='contained' color='primary'>
          {t('approve')}
        </Button>
        <Button sx={{ width: '48%' }} variant='outlined' color='error'>
          {t('decline')}
        </Button>
      </Box>
      <Box sx={headerStyle}>
        <Typography variant='h6'>Order</Typography>
        <Box sx={statusBoxStyle(order?.requestStatus ?? '')}>{order?.requestStatus ?? 'N/A'}</Box>
      </Box>

      <LoadingContainer loading={offerDetailsLoading}>
        <CardMedia
          component='img'
          height='140'
          image={offerDetail?.imageUrl || 'https://via.placeholder.com/400x140'}
          sx={{ width: '100%', borderRadius: 2, marginBottom: 2 }}
        />
      </LoadingContainer>

      <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
        {t('order_id')}
      </Typography>
      <Typography variant='body2' gutterBottom>
        {order?.id ?? 'N/A'}
      </Typography>
      <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
        {t('unit')}
      </Typography>
      <Typography variant='body2' gutterBottom>
        {order?.unitName ?? 'N/A'}
      </Typography>
      <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
        {t('description')}
      </Typography>

      <Typography variant='body2' gutterBottom>
        {offerDetail?.information?.description ?? '-'}
      </Typography>

      <Typography variant='subtitle1' sx={{ fontWeight: 'bold', marginTop: 2 }}>
        {t('highlights')}
      </Typography>
      <List dense>
        {offerDetail?.information?.subheader && (
          <ListItem>
            <ListItemText primary={offerDetail?.information?.subheader} />
          </ListItem>
        )}
      </List>

      <Typography variant='subtitle1' sx={{ fontWeight: 'bold', marginTop: 2 }}>
        {t('pricing')}
      </Typography>
      <List dense>
        <ListItem>
          <ListItemText primary={`Price: ${order?.totalPrice ?? 'N/A'}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Quantity: ${/* TODO: use order?.selectedQuantity ?? */ 'N/A'}`} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={`Payment Method: ${transactionDetails?.paymentMethodBrand ?? 'N/A'} ${
              transactionDetails?.paymentMethodLastFour ?? ''
            }`}
          />
        </ListItem>
      </List>

      <Typography variant='subtitle1' sx={{ fontWeight: 'bold', marginTop: 2 }}>
        {t('requested_on')}
      </Typography>
      <Typography variant='body2' gutterBottom>
        {order?.requestedDate ?? 'N/A'}
      </Typography>

      <Typography variant='subtitle1' sx={{ fontWeight: 'bold', marginTop: 2 }}>
        {t('selected_preferred_time')}
      </Typography>
      <Typography variant='body2' gutterBottom>
        {/* TODO: use order?.selectedTime ?? */ 'N/A'}
      </Typography>

      <Typography variant='subtitle1' sx={{ fontWeight: 'bold', marginTop: 2 }}>
        {t('guest')}
      </Typography>
      <Typography variant='body2' gutterBottom>
        {order?.guestName ?? 'N/A'}
      </Typography>

      <LoadingButton
        loading={transactionDetailsModalVisible || transactionDetailsLoading}
        variant='outlined'
        color='primary'
        onClick={() => {
          if (
            stripeConnectInstance !== null &&
            transactionDetails?.stripeDestinationPaymentId !== undefined &&
            transactionDetails?.stripeDestinationPaymentId !== ''
          ) {
            setTransactionDetailsModalVisible(true);
          } else {
            snackbar('No transaction details available for this request.');
            logger.info(
              `No transaction details for this request. transactionId: ${order.transactionId}, destinationPaymentId: ${transactionDetails?.stripeDestinationPaymentId}`,
            );
          }
        }}
        sx={{ marginTop: 2, width: '100%' }}
      >
        {t('view_transaction_details')}
      </LoadingButton>

      {transactionDetailsModalVisible ? (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectPaymentDetails
            onClose={() => setTransactionDetailsModalVisible(false)}
            payment={transactionDetails.stripeDestinationPaymentId}
          />
        </ConnectComponentsProvider>
      ) : null}
    </Paper>
  );
};

const containerStyle: SxProps = {
  width: 400,
  maxHeight: '100vh',
  overflow: 'auto',
  padding: 2,
  marginLeft: 2,
};

const headerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 2,
};

const titleStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
};

const sectionStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 2,
};

export default OrderDetailsSidebar;
