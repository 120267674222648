import { SellOutlined } from '@mui/icons-material';
import {
  TitlebarBreadCrumb,
  TitlebarBreadCrumbChild,
  TitlebarRoot,
  TitlebarTitleIcon,
} from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function UpsellItemsTitlebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const crumbs: TitlebarBreadCrumbChild[] = [
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('orders'),
    },
  ];

  return (
    <TitlebarRoot>
      <TitlebarTitleIcon>
        <SellOutlined />
      </TitlebarTitleIcon>

      <TitlebarBreadCrumb crumbs={crumbs} onClick={crumb => navigate(crumb.href)} />
    </TitlebarRoot>
  );
}
