import { Button, Divider, Typography } from '@mui/material';
import React from 'react';

const PaymentInfo = () => (
  <>
    <Divider sx={{ my: 2 }} />
    <Typography variant='body2' gutterBottom>
      American Express ••••2654
      <br />
      Expiration: 01/2030
    </Typography>
    <Button variant='contained' fullWidth color='primary'>
      Request
    </Button>
    <Typography variant='body2' textAlign='center' mt={1}>
      You wont be charged yet
    </Typography>
  </>
);

export default PaymentInfo;
