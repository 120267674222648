import { Box, Card, CardMedia, Chip } from '@mui/material';
import { Text } from '@operto/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface UpsellTemplateCardProps {
  title: string;
  description: string;
  category: string;
  image: string;
  id: number;
  price: number;
}

const UpsellTemplateCard = ({
  title,
  description,
  category,
  image,
  price,
  id,
}: UpsellTemplateCardProps) => {
  const navigate = useNavigate();

  const handleTemplateClick = () => {
    navigate(`/upsell-items/${id}-template`);
  };

  return (
    <Card onClick={handleTemplateClick} elevation={4} sx={{ width: 400, minWidth: 400 }}>
      <Box sx={{ padding: '14px' }}>
        <CardMedia component='img' style={{ borderRadius: '8px' }} height='200' image={image} />
      </Box>

      <Box sx={{ paddingInline: '14px' }}>
        <Chip
          label={category}
          variant='filled'
          size='small'
          style={{ flex: 1, backgroundColor: '#155EFF14', color: '#155EFF' }}
        />
      </Box>

      <Box sx={{ paddingInline: '14px', paddingTop: '10px' }}>
        <Text.H4Bold>{title}</Text.H4Bold>
      </Box>

      <Box sx={{ paddingInline: '14px', paddingTop: '10px' }}>
        <Text.BodyLargeSemiBold>{`$${price} USD`}</Text.BodyLargeSemiBold>
      </Box>

      <Box sx={{ paddingInline: '14px', paddingBottom: '10px', paddingTop: '10px' }}>
        <Text.BodySmall>{description}</Text.BodySmall>
      </Box>
    </Card>
  );
};

export default UpsellTemplateCard;
