import Typography from '@mui/material/Typography';
import { logger } from 'lib/logger';
import React from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import { grantPmsReservation } from 'reservation/state/reservationActions';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import ConfirmDialog from './ConfirmDialog';

export interface RegrantReservationDialogProps {
  onClose: () => void;
  open: boolean;
  reservation: IReservation;
}

const RegrantReservationDialog = ({
  onClose,
  open,
  reservation,
}: RegrantReservationDialogProps) => {
  const dispatch = useAppDispatch();

  const handleGrantReservaton = async () => {
    try {
      await dispatch(grantPmsReservation(reservation.id));
      onClose();
    } catch (error) {
      logger.error(`regranting reservation: ${reservation.id}, error: ${error}`);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Error Regranting Reservation',
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  return (
    <ConfirmDialog
      open={open}
      title='Regrant Reservation'
      onClose={onClose}
      submitButtonText='Regrant'
      submitButtonColor='error'
      onSubmit={handleGrantReservaton}
    >
      <Typography variant='body1'>Are you sure you want to regrant this reservation?</Typography>
    </ConfirmDialog>
  );
};

export default RegrantReservationDialog;
