import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Badge, Box, Switch, SxProps, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TriggerDataType } from '@operto/communications-shared';
import { theme } from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import AutomateActionMenu from './AutomateActionMenu';

export default function useAutomateColumns() {
  const { t } = useTranslation();

  const calculateWorkflowChannels = (triggers: TriggerDataType[]) => {
    let hasSms = false;
    let hasEmail = false;
    let hasErrorSms = false;
    let hasErrorEmail = false;
    let isRunnable = false;
    let isInitTrigger = true;

    // Iterate over each trigger and count non-empty sms and email templates
    triggers.forEach((trigger: TriggerDataType) => {
      const templates = trigger.templates;
      if (templates && templates.length !== 0) {
        isInitTrigger = false;
        if (templates.sms) {
          hasSms = true;

          if (templates.sms.length === 0) {
            hasErrorSms = true;
          } else {
            isRunnable = true;
          }
        }

        if (templates.email) {
          hasEmail = true;
          if (templates.email.length === 0) {
            hasErrorEmail = true;
          } else {
            isRunnable = true;
          }
        }
      }
    });

    if (!isRunnable && !isInitTrigger) {
      return (
        <Box sx={errorBoxStyle}>
          <Tooltip title={t('tooltip_missing_template')}>
            <ErrorIcon sx={errorIconStyle} />
          </Tooltip>
        </Box>
      );
    }

    return (
      <>
        {hasEmail && (
          <Badge
            badgeContent={hasErrorEmail && <ErrorIcon sx={{ ...errorIconStyle, fontSize: 18 }} />}
            sx={badgeStyle}
          >
            <Box sx={{ ...channelBoxStyle, backgroundColor: '#FFEA30' }}>
              <Tooltip title={hasErrorEmail ? t('workflow_minor_error') : t('email')}>
                <MailOutlineIcon />
              </Tooltip>
            </Box>
          </Badge>
        )}
        {hasSms && (
          <Badge
            badgeContent={hasErrorSms && <ErrorIcon sx={{ ...errorIconStyle, fontSize: 18 }} />}
            sx={badgeStyle}
          >
            <Box sx={{ ...channelBoxStyle, backgroundColor: '#259C72' }}>
              <Tooltip title={hasErrorSms ? t('workflow_minor_error') : t('sms')}>
                <ChatBubbleOutlineIcon />
              </Tooltip>
            </Box>
          </Badge>
        )}
      </>
    );
  };

  const renderEnabled = (params: GridRenderCellParams) => {
    const enabled = params.row.enabled;
    return (
      <Tooltip title={enabled ? t('on') : t('off')}>
        <Switch checked={enabled} />
      </Tooltip>
    );
  };

  const renderChannel = (params: GridRenderCellParams) => {
    const triggers = params.row.triggers;
    const channel = calculateWorkflowChannels(triggers);
    return channel;
  };

  const renderActions = () => {
    return (
      <AutomateActionMenu
        onClickEdit={() => console.log('click edit')}
        onClickDelete={() => console.log('click delete')}
      />
    );
  };

  return [
    {
      field: 'name',
      headerName: t('workflow_table_header'),
      sortable: true,
      flex: 5,
    },
    {
      field: 'triggers',
      headerName: t('workflow_channel'),
      sortable: false,
      flex: 5,
      renderCell: renderChannel,
    },
    {
      field: 'enabled',
      headerName: t('workflow_running'),
      sortable: false,
      flex: 3,
      renderCell: renderEnabled,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      flex: 1,
      type: 'number',
      renderCell: renderActions,
    },
  ];
}

const errorBoxStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  border: '2px dashed var(--light-other-outlined-border-23-p, rgba(8, 22, 62, 0.23))',
  borderRadius: '8px',
};

const errorIconStyle: SxProps = {
  color: theme.palette.error.main,
  backgroundColor: 'white',
  borderRadius: '500px',
  border: '1px',
};

const badgeStyle: SxProps = {
  '& .MuiBadge-badge': {
    top: '5px',
    right: '5px',
  },
  marginRight: '8px',
};

const channelBoxStyle: SxProps = {
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
};
