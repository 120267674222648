import { NotifiableError } from '@bugsnag/js';
import * as api from 'api/locks';
import { AxiosResponse } from 'axios';
import { OpertoLogger } from 'Logger/logger';
import { poll } from 'redux/poll';
import { ApplicationState } from 'redux/reducers';
import { AppDispatch } from 'redux/store';
import { Actions } from 'types/actions';

const RETRY_INTERVAL = 8000;
const MAX_ATTEMPTS = 15;

const validate = (previousLockStatus: boolean, dispatch: AppDispatch) => (data: AxiosResponse) => {
  const lockStatus = data.data.data;
  const statusChanged = previousLockStatus !== lockStatus.is_locked;
  if (statusChanged) {
    dispatch({
      type: Actions.updateLockStatus,
      device_id: lockStatus.device_id,
      status: lockStatus,
    });
  }
  return statusChanged;
};
export const unlock =
  (lockId: string, callBackFunc: (isSuccess: boolean) => void) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();

    api
      .unlock(lockId)
      .then(() => {
        const salto =
          state.company.accessCodeFormat && state.company.accessCodeFormat.includes('salto');
        const hasSalto = !!salto;

        if (!hasSalto) {
          poll({
            fn: () => api.getLockStatus(lockId),
            validate: validate(true, dispatch),
            interval: RETRY_INTERVAL,
            maxAttempts: MAX_ATTEMPTS,
          })
            .then(() => {
              callBackFunc(true);
            })
            .catch(() => {
              callBackFunc(false);
            });
        } else {
          // Salto auto locking after 6 seconds for non-public access mode
          setTimeout(() => callBackFunc(true), 6000);
        }
      })
      .catch((err: NotifiableError) => {
        callBackFunc(false);
        OpertoLogger.Log(err);
      });
  };

export const lock =
  (lockId: string, callBackFunc: (isSuccess: boolean) => void) => (dispatch: AppDispatch) => {
    api
      .lock(lockId)
      .then(() => {
        poll({
          fn: () => api.getLockStatus(lockId),
          validate: validate(false, dispatch),
          interval: RETRY_INTERVAL,
          maxAttempts: MAX_ATTEMPTS,
        })
          .then(() => {
            callBackFunc(true);
          })
          .catch(() => {
            callBackFunc(false);
          });
      })
      .catch((err: NotifiableError) => {
        callBackFunc(false);
        OpertoLogger.Log(err);
      });
  };
