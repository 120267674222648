import { SendOutlined } from '@mui/icons-material';
import NewServiceIcon from '@mui/icons-material/Add';
import AssignmentTurnedInOutlined from '@mui/icons-material/AssignmentTurnedInOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DataObjectIcon from '@mui/icons-material/DataObject';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import KnowledgeIcon from '@mui/icons-material/LibraryBooksOutlined';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountIcon from '@mui/icons-material/Person';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import HelpCenterIcon from '@mui/icons-material/SupportAgentOutlined';
import VibrationOutlinedIcon from '@mui/icons-material/VibrationOutlined';
import CampaigIcon from 'assets/img/icons/CONNECTICONS-GUEST-ICON-CAMPAIGNS_1.svg';
import { companySelector, roomTypeEnabledSelector } from 'company/state/companySelectors';
import useTranslation from 'hooks/useTranslation';
import { IntegrationsApiSystem } from 'integrations/integrationsTypes';
import { findEnabledIntegrationsApiSystemSelector } from 'integrations/state/integrationsSelectors';
import { hasProvider } from 'lib/analytics';
import { useAppFeatures } from 'lib/app-features';
import { isAdmin } from 'redux/actions/ui';
import { useAppSelector } from 'redux/hooks';
import { userStateSelector } from 'user/state/userSelectors';
import {
  IHeaderButton,
  IMenuItem,
  IMenuSection,
  ISimpleMenuItem,
  ISubItem,
} from './SideBarMenuTypes';

export const SideBarMenuData = () => {
  const { t } = useTranslation();
  const {
    groups: isGroupsEnabled,
    transactionsPage: transactionsPageEnabled,
    communicationsPage: isCommunicationsPageEnabled,
  } = useAppFeatures();
  const { marketplacePage: marketplacePageEnabled } = useAppFeatures();
  const userState = useAppSelector(userStateSelector());
  const company = useAppSelector(companySelector());
  const isMessengerEnabled = userState?.features?.mp_two_way_enabled;
  const isGuestPortalEnabled = userState?.features?.mp_guest_portal_onboarding_enabled;
  const isCampaignsEnabled = userState?.features?.mp_campaigns_enabled;
  const isPaymentSystemEnabled = useAppSelector(
    findEnabledIntegrationsApiSystemSelector(IntegrationsApiSystem.PAYMENT),
  );
  const isRoomTypeEnabled = useAppSelector(roomTypeEnabledSelector());
  const isSaltoEnabled = company.accessCodeFormat === 'salto:1';

  const headerButton: IHeaderButton = {
    label: 'Home',
    path: '/',
  };

  const accountItem: ISimpleMenuItem = {
    label: 'Account',
    icon: AccountIcon,
    path: '/settings/account',
    isAccessible: true,
  };

  const integrationItem: ISimpleMenuItem = {
    label: 'Integrations',
    icon: SettingsEthernetIcon,
    path: '/settings/integrations',
    isAccessible: true,
  };

  const variablesItem: ISimpleMenuItem = {
    label: 'Variables',
    icon: DataObjectIcon,
    path: '/settings/variables/system',
    isAccessible: true,
  };

  const settingsSection: IMenuSection = {
    label: 'Settings',
    topLine: false,
    items: [accountItem, integrationItem, variablesItem],
  };

  const homeItem: ISimpleMenuItem = {
    label: t('home'),
    path: '/',
    icon: HomeOutlinedIcon,
    isAccessible: true,
  };

  const messengerItem: ISimpleMenuItem = {
    label: t('messenger'),
    path: '/messenger',
    icon: MessageOutlinedIcon,
    isAccessible: isMessengerEnabled,
  };

  const transactionsItem: ISimpleMenuItem = {
    label: 'Transactions',
    path: '/transactions',
    icon: PaymentIcon,
    isAccessible: transactionsPageEnabled,
  };

  const propertiesSubItems: ISubItem[] = [
    { title: t('units'), url: '/units', isAccessible: true },
    { title: t('room_types'), url: '/room-types', isAccessible: isRoomTypeEnabled },
    { title: t('groups'), url: '/groups', isAccessible: isGroupsEnabled },
    { title: t('salto_groups'), url: '/saltogroups', isAccessible: isAdmin() && isSaltoEnabled },
    { title: t('favorites'), url: '/units/favorites', isAccessible: true },
  ];

  const peopleSubItems: ISubItem[] = [
    { title: t('members'), url: '/members', isAccessible: true },
    { title: t('add_member'), url: '/members/add', isAccessible: true },
  ];

  const marketplaceSubItems: ISubItem[] = [
    { title: t('upsell_items'), url: '/upsell-items', isAccessible: true },
    { title: t('orders'), url: '/orders', isAccessible: true },
  ];

  const guestSubItems: ISubItem[] = [
    { title: t('view_guests'), url: '/guests', isAccessible: true },
    { title: t('delivery_logs'), url: '/deliverylogs', isAccessible: isCommunicationsPageEnabled },
  ];

  const propertiesItem: IMenuItem = {
    label: t('properties'),
    icon: HomeWorkOutlinedIcon,
    subItems: propertiesSubItems,
    isAccessible: true,
  };

  const peopleItem: IMenuItem = {
    label: t('people'),
    icon: GroupOutlinedIcon,
    subItems: peopleSubItems,
    isAccessible: true,
  };

  const guestItem: IMenuItem = {
    label: t('guests'),
    icon: HotelOutlinedIcon,
    subItems: guestSubItems,
    isAccessible: true,
  };

  const marketplaceItem: IMenuItem = {
    label: t('marketplace'),
    icon: LocalOfferOutlinedIcon,
    subItems: marketplaceSubItems,
    isAccessible: marketplacePageEnabled,
  };

  const communicationItem: ISimpleMenuItem = {
    label: t('communications'),
    icon: SendOutlined,
    path: '/communications',
    isAccessible: isCommunicationsPageEnabled,
  };

  const automateItem: ISimpleMenuItem = {
    label: t('automate'),
    icon: LinearScaleIcon,
    path: '/automate',
    isAccessible: isCommunicationsPageEnabled,
  };

  const campaignSubItems: ISubItem[] = [
    { title: t('view_campaigns'), url: '/campaigns', isAccessible: isCampaignsEnabled },
    { title: t('add_campaign'), url: '/campaigns/add', isAccessible: isCampaignsEnabled },
  ];

  const campaignsItem: IMenuItem = {
    label: t('campaigns'),
    icon: CampaigIcon,
    subItems: campaignSubItems,
    isAccessible: isCampaignsEnabled,
  };

  const guestPortalItem: ISimpleMenuItem = {
    label: t('guest_portal'),
    icon: VibrationOutlinedIcon,
    path: '/guest-portal',
    isAccessible: isGuestPortalEnabled,
  };

  const paymentsItem: ISimpleMenuItem = {
    label: t('payments'),
    icon: CreditCardIcon,
    path: '/payments',
    isAccessible: isPaymentSystemEnabled,
  };

  const knowledgeBaseItem: ISimpleMenuItem = {
    label: t('knowledge_base'),
    icon: KnowledgeIcon,
    path: 'https://support.operto.com/s/',
    isAccessible: true,
  };

  const helpCenterItem: ISimpleMenuItem = {
    label: t('help_center'),
    icon: HelpCenterIcon,
    path: '/help-center',
    isAccessible: true,
  };

  const addNewServiceItem: ISimpleMenuItem = {
    label: t('add_new_service'),
    icon: NewServiceIcon,
    path: '/expansion',
    isAccessible: true,
  };

  const mainSection: IMenuSection = {
    topLine: false,
    items: [
      homeItem,
      messengerItem,
      transactionsItem,
      propertiesItem,
      peopleItem,
      guestItem,
      marketplaceItem,
      communicationItem,
      automateItem,
    ],
  };

  const addonsSection: IMenuSection = {
    label: t('add_ons'),
    topLine: true,
    items: [campaignsItem, guestPortalItem, paymentsItem],
  };

  let supportSectionItems: ISimpleMenuItem[] = [
    knowledgeBaseItem,
    helpCenterItem,
    addNewServiceItem,
  ];

  if (hasProvider('churnzero')) {
    const customerSuccessCenterItem: ISimpleMenuItem = {
      label: 'Success Center',
      icon: AssignmentTurnedInOutlined,
      path: '/success-center',
      isAccessible: true,
    };

    supportSectionItems = [customerSuccessCenterItem, ...supportSectionItems];
  }

  const supportSection: IMenuSection = {
    label: 'Support',
    topLine: true,
    items: supportSectionItems,
  };

  return {
    headerButton,
    settingsSection,
    mainSection,
    addonsSection,
    supportSection,
  };
};
