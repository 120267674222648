import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { Loading } from 'Common/Loading';
import useCommunications, { ChannelType } from 'hooks/useCommunications';
import useCommunicationsFilters from 'hooks/useCommunicationsFilters';
import { SupportedLocale } from 'hooks/useTranslation';
import React, { useEffect, useRef } from 'react';
import {
  getSortModelByUrl,
  PaginatedTable,
  sortTableByUrl,
} from 'ui-library/Components/table/PaginatedTable';
import CommunicationsBlankPagePanel from './CommunicationsBlankPagePanel';
import CommunicationsTitlebar from './CommunicationsTitlebar';
import useCommunicationsColumns from './useCommunicationsColumns';

export default function CommunicationsTable() {
  const firstLoad = useRef(true);
  const communicationsFilters = useCommunicationsFilters();

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    }
  }, []);

  const channelsSelected = communicationsFilters.searchParams
    .get('channel')
    ?.split(',') as ChannelType[];

  const localesSelected = communicationsFilters.searchParams
    .get('language')
    ?.split(',') as SupportedLocale[];

  const hasFilters = channelsSelected || localesSelected;

  const {
    data: communications,
    isLoading,
    isFetching,
  } = useCommunications({
    channels: channelsSelected,
    locales: localesSelected,
  });

  const columns = useCommunicationsColumns(communications);

  const renderContent = () => {
    if (firstLoad.current && isLoading) {
      return <Loading />;
    }

    if (!hasFilters && !isLoading && !communications?.length) {
      return <CommunicationsBlankPagePanel />;
    }

    return (
      <PaginatedTable
        enableToolbar
        columns={columns}
        rows={communications ?? []}
        initialState={tableInitialState}
        filtersToolbarProps={communicationsFilters}
        onSortModelChange={model =>
          communicationsFilters.onFilterChange(
            sortTableByUrl(model, communicationsFilters.searchParams),
          )
        }
        sortModel={getSortModelByUrl(communicationsFilters.searchParams)}
        loading={!communicationsFilters.isSynched || (isFetching && !communications)}
      />
    );
  };

  return (
    <>
      <CommunicationsTitlebar displayAddButton={!isLoading && communications?.length > 0} />

      {renderContent()}
    </>
  );
}

const tableInitialState: GridInitialStatePro = {
  sorting: {
    sortModel: [{ field: 'name', sort: 'asc' }],
  },
};
