import { ReactNode } from 'react';

// these three will be used in the future
type RoomStatus =
  | 'cleaning-complete'
  | 'guest-checking-in'
  | 'guest-checking-out'
  | 'clean-available'
  | 'cleaning-started';

export interface UnitsFilterOptionsState {
  options: UnitsFilterOption;
}

export type DeviceType = {
  [key: string]: string[] | string;
  lock: string[];
  lock_battery: string[];
  lock_keypad: string[];
};

export interface UnitsFilterOptionResponse {
  property_type: string[];
  devices: {
    keypad: string[];
    battery: string[];
    lock: string[];
  };
  unit_status: string[];
}
export interface UnitsFilterOption {
  [key: string]: string[] | string | number | boolean | DeviceType;
  type: string[];
  devices: {
    lock: string[];
    lock_battery: string[];
    lock_keypad: string[];
  };
  group: string[];
  room_type: string[];
  unit_status: RoomStatus[];
  sort_by: string[];
}

export type FilterGroup = {
  label: string;
  groupName: string;
  options: FilterDataType[];
  order?: number;
};

// Define a type for a filter sub-option
export type FilterDataType = {
  label: string;
  filterType: 'checkbox' | 'slidedown' | 'datepicker';
  name: string;
  slideDownData?: SlideDownDataType[];
  singleSelect?: boolean;
};

// Define a type for a filter sub-sub-option
export type SlideDownDataType = {
  label: string;
  filterType: 'checkbox';
  name: string;
  icon?: ReactNode;
  singleSelect?: boolean;
};

export interface GuestsFilterOptionResponse {
  guest_status: string[];
  access_code_status: string[];
  verification_status: string[];
  security_deposit_status: string[];
  access_approval: string[];
  check_in: string;
  check_out: string;
}

export const CHECK_IN = 'check_in';
export const CHECK_OUT = 'check_out';
export const dateRangeKeys = [
  'check_in_from',
  'check_in_until',
  'check_out_from',
  'check_out_until',
];

export const KEYWORD = 'keyword';
export const DEVICES = 'devices';
