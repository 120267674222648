import {
  CloseOutlined,
  DeleteOutlineOutlined,
  InfoOutlined,
  ModeEditOutlined,
  SendOutlined,
} from '@mui/icons-material';
import {
  TitlebarActionIcon,
  TitlebarActionIconContainer,
  TitlebarBreadCrumb,
  TitlebarBreadCrumbChild,
  TitlebarButton,
  TitlebarRoot,
  TitlebarTitle,
  TitlebarTitleButton,
  TitlebarTitleIcon,
} from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function CommunicationsTemplateTitlebar({
  title,
  onTitleChange,
  onSaveClick,
  deleteDisabled,
  saveDisabled,
  helperText,
  editMode,
  setEditMode,
  onSendClick,
  isSaving,
}: CommunicationsTemplateTitlebarProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [prevTitle, setPrevTitle] = React.useState(title);

  const crumbs: TitlebarBreadCrumbChild[] = [
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('communications'),
      href: '/communications',
    },
  ];

  return (
    <TitlebarRoot>
      <TitlebarTitleIcon>
        <SendOutlined />
      </TitlebarTitleIcon>

      <TitlebarBreadCrumb crumbs={crumbs} onClick={crumb => navigate(crumb.href)}>
        <TitlebarTitle
          title={title}
          editMode={editMode}
          helperText={helperText}
          onChange={e =>
            onTitleChange(
              (e as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>).target.value,
            )
          }
        >
          {editMode ? (
            <TitlebarTitleButton
              onClick={() => {
                onTitleChange(prevTitle);
                setEditMode(false);
              }}
            >
              <CloseOutlined />
            </TitlebarTitleButton>
          ) : (
            <TitlebarTitleButton
              onClick={() => {
                setEditMode(true);
                setPrevTitle(title);
              }}
            >
              <ModeEditOutlined />
            </TitlebarTitleButton>
          )}
        </TitlebarTitle>
      </TitlebarBreadCrumb>

      <TitlebarActionIconContainer>
        <TitlebarActionIcon tooltip={t('more_information')} onClick={() => void 0}>
          <InfoOutlined />
        </TitlebarActionIcon>

        <TitlebarActionIcon
          tooltip={t('delete_language')}
          onClick={() => void 0}
          disabled={deleteDisabled}
        >
          <DeleteOutlineOutlined />
        </TitlebarActionIcon>

        <TitlebarActionIcon
          tooltip={t('send_test')}
          onClick={onSendClick}
          data-testid='send-preview-btn'
        >
          <SendOutlined />
        </TitlebarActionIcon>
      </TitlebarActionIconContainer>

      <TitlebarButton onClick={onSaveClick} disabled={saveDisabled} loading={isSaving}>
        {t('save')}
      </TitlebarButton>
    </TitlebarRoot>
  );
}

type CommunicationsTemplateTitlebarProps = {
  title: string;
  onTitleChange: (title: string) => void;
  onSaveClick: () => void;
  deleteDisabled?: boolean;
  saveDisabled?: boolean;
  helperText?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
  onSendClick: () => void;
  isSaving?: boolean;
};
