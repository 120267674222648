import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Badge, Box, SxProps, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { utcToTimeZone } from 'helper/date';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { TableCell } from 'ui-library/Components/table/TableCell';

export default function useDeliveryLogsColumns() {
  const { t } = useTranslation();

  const renderScheduledAt = ({ row }: GridRenderCellParams) => {
    const scheduledAt = row.scheduledAt;
    const timezone = row.timezone;
    const yyyMMMDD = utcToTimeZone(scheduledAt, timezone, 'yyyy-MM-dd');
    const hhmmA = utcToTimeZone(scheduledAt, timezone, 'hh:mm aa zz');

    return <TableCell title={yyyMMMDD} description={hhmmA} flexDirection='column' />;
  };

  const renderStatus = ({ row }: GridRenderCellParams) => {
    if (row.status === 'delivered') {
      return (
        <Box marginLeft={5}>
          <Tooltip title={t('delivered')}>
            <CheckCircleOutlineIcon style={{ color: '#2E7D32' }} />
          </Tooltip>
        </Box>
      );
    } else {
      return (
        <Box marginLeft={5}>
          <Tooltip title={t('failed_to_deliver')}>
            <ErrorOutlineOutlinedIcon style={{ color: '#D32F2F' }} />
          </Tooltip>
        </Box>
      );
    }
  };

  const renderGuest = ({ row }: GridRenderCellParams) => {
    const reservationId = row.reservationId;
    const guestName = row.guestName;

    const TooltipTitle = (
      <>
        <span>{guestName}</span>
        <br />
        <span>{`Reservation ID ${reservationId}`}</span>
      </>
    );

    return (
      <TableCell
        sx={{ overflow: 'hidden' }}
        title={
          <Tooltip title={TooltipTitle}>
            <Typography variant='body-sm-400' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {guestName}
            </Typography>
          </Tooltip>
        }
        description={
          <Tooltip title={TooltipTitle}>
            <Typography
              variant='caption-lg-400'
              color='text.secondary'
            >{`ID ${reservationId}`}</Typography>
          </Tooltip>
        }
        flexDirection='column'
      />
    );
  };

  const renderUnit = ({ row }: GridRenderCellParams) => {
    const unitName = row.unitName;
    const unitId = row.unitId;

    const TooltipTitle = (
      <>
        <span>{unitName}</span>
        <br />
        <span>{`Unit ID ${unitId}`}</span>
      </>
    );

    return (
      <TableCell
        sx={{ overflow: 'hidden' }}
        title={
          <Tooltip title={TooltipTitle}>
            <Typography variant='body-sm-400' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {unitName}
            </Typography>
          </Tooltip>
        }
        description={
          <Tooltip title={TooltipTitle}>
            <Typography
              variant='caption-lg-400'
              color='text.secondary'
            >{`ID ${unitId}`}</Typography>
          </Tooltip>
        }
        flexDirection='column'
      />
    );
  };

  const renderTemplate = ({ row }: GridRenderCellParams) => {
    const channelType = row.channelType;
    if (channelType === 'email') {
      return (
        <>
          <Badge>
            <Box sx={{ ...channelBoxStyle, backgroundColor: '#FFEA30' }}>
              <MailOutlineIcon />
            </Box>
          </Badge>
          <Typography marginLeft='8px' fontSize={14}>
            {row.templateName}
          </Typography>
        </>
      );
    }

    if (channelType === 'sms') {
      return (
        <>
          <Badge>
            <Box sx={{ ...channelBoxStyle, backgroundColor: '#259C72' }}>
              <ChatBubbleOutlineIcon sx={{ color: 'white' }} />
            </Box>
          </Badge>
          <Typography marginLeft='8px' fontSize={14}>
            {row.templateName}
          </Typography>
        </>
      );
    }
  };

  return [
    {
      field: 'scheduledAt',
      headerName: 'Scheduled on',
      flex: 1,
      sortable: true,
      renderCell: renderScheduledAt,
    },
    {
      field: 'status',
      headerName: 'Delivery status',
      flex: 1,
      sortable: true,
      renderCell: renderStatus,
    },
    {
      field: 'guest',
      headerName: 'Guest',
      flex: 1,
      sortable: true,
      renderCell: renderGuest,
    },
    {
      field: 'recipient',
      headerName: 'Recipient',
      flex: 1,
      sortable: true,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      flex: 1,
      sortable: true,
      renderCell: renderUnit,
    },
    {
      field: 'template',
      headerName: 'Template',
      flex: 1,
      sortable: true,
      renderCell: renderTemplate,
    },
  ];
}

const channelBoxStyle: SxProps = {
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
};
