import { Grid } from '@mui/material';
import React from 'react';
import { UpsellFields } from '../UpsellPage';
import { InstructionSection } from './TabsComponents/InstructionsSection';

interface PostPurchaseInstructionsProps {
  upsellFields: UpsellFields;
  updateUpsellFields: <K extends keyof UpsellFields>(field: K, value: UpsellFields[K]) => void;
}

export const PostPurchaseInstructions = ({
  upsellFields,
  updateUpsellFields,
}: PostPurchaseInstructionsProps) => {
  const { postPurchase } = upsellFields;
  const updatePostPurchaseInstructionsField = (
    field: keyof UpsellFields['postPurchase'],
    value: string | boolean | null,
  ) => {
    updateUpsellFields('postPurchase', { ...postPurchase, [field]: value });
  };

  return (
    <Grid item xs={12} md={9}>
      <InstructionSection
        active={postPurchase.active}
        instructions={postPurchase.instructions}
        setInstructions={(instructions: string) =>
          updatePostPurchaseInstructionsField('instructions', instructions)
        }
        setActive={(active: boolean) => updatePostPurchaseInstructionsField('active', active)}
      />
    </Grid>
  );
};
