import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { Box, FormControlLabel, Radio, TextField, Typography } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import useTranslation from 'hooks/useTranslation';
import React, { ChangeEvent } from 'react';
import renderTextField from '../TabsComponents/TextInput';
interface AffiliateLinkPricingProps {
  paymentType: string;
  customButtonText: string;
  affiliateLink: string;
  customAffiliateHeader: string;
  customAffiliateHeaderEnabled: boolean;
  setPaymentType: (paymentType: string) => void;
  setCustomButtonText: (customButtonText: string) => void;
  setAffiliateLink: (affiliateLink: string) => void;
  setCustomAffiliateHeader: (customAffiliateHeader: string) => void;
  setCustomAffiliateHeaderEnabled: (customAffiliateHeaderEnabled: boolean) => void;
}

export const AffiliateLinkPricing = ({
  paymentType,
  affiliateLink,
  customButtonText,
  customAffiliateHeader,
  customAffiliateHeaderEnabled,
  setPaymentType,
  setCustomButtonText,
  setAffiliateLink,
  setCustomAffiliateHeader,
  setCustomAffiliateHeaderEnabled,
}: AffiliateLinkPricingProps) => {
  const maxLength = 30;
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomButtonText(event.target.value);
  };
  const handleCustomHeaderChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomAffiliateHeader(event.target.value);
  };
  const { t } = useTranslation();

  return (
    <>
      <AddCard
        title={
          <>
            <Box display='flex' flexDirection='row' justifyContent='flex-start'>
              <Radio checked={paymentType === 'link'} onChange={() => setPaymentType('link')} />
              <Box display='flex' flexDirection='column' justifyContent='space-between'>
                <Typography variant='subtitle1'>{t('affiliate_link')}</Typography>
                <Typography variant='body2'>{t('custom_text_for_button')}</Typography>
              </Box>
            </Box>
          </>
        }
      >
        {paymentType === 'link' && (
          <Box display='flex' flexDirection='column' justifyContent='space-between'>
            <Typography sx={{ paddingTop: '10px' }} variant='subtitle1'>
              {t('affiliate_header')}
            </Typography>
            <FormControlLabel
              control={
                <Radio
                  name='customAffiliateHeaderEnabled'
                  color='primary'
                  inputProps={{ maxLength: 30 }}
                  checked={!customAffiliateHeaderEnabled}
                  onChange={() => setCustomAffiliateHeaderEnabled(!customAffiliateHeaderEnabled)}
                />
              }
              label={t('pricing_varies')}
              sx={{ paddingTop: '10px' }}
            />

            <FormControlLabel
              control={
                <Radio
                  name='customAffiliateHeaderEnabled'
                  color='primary'
                  inputProps={{ maxLength: 30 }}
                  checked={customAffiliateHeaderEnabled}
                  onChange={() => setCustomAffiliateHeaderEnabled(!customAffiliateHeaderEnabled)}
                />
              }
              label={t('custom_text')}
              sx={{ paddingTop: '10px' }}
            />

            {customAffiliateHeaderEnabled && (
              <>
                {renderTextField(
                  t('custom_text'),
                  t('custom_text_hint'),
                  customAffiliateHeader,
                  handleCustomHeaderChange,
                  maxLength,
                  true,
                  1,
                  false,
                )}
              </>
            )}

            <Typography sx={{ paddingTop: '10px' }} variant='subtitle1'>
              {t('service_button')}
            </Typography>

            <Typography sx={{ paddingTop: '10px', paddingBottom: '10px' }} variant='body2'>
              {t('enter_custom_button_text_and_url')}
            </Typography>

            {renderTextField(
              t('custom_text'),
              t('custom_text'),
              customButtonText,
              handleNameChange,
              maxLength,
              true,
              1,
              false,
            )}

            <TextField
              sx={{ input: { color: '#155EFF', fontWeight: 'bold' } }}
              label={t('affiliate_link')}
              variant='outlined'
              fullWidth
              required
              value={affiliateLink ? affiliateLink : ''}
              onChange={event => setAffiliateLink(event.target.value)}
              InputProps={{
                startAdornment: <InsertLinkIcon sx={{ paddingRight: '5px' }} />,
              }}
            />
          </Box>
        )}
      </AddCard>
    </>
  );
};
