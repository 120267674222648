import { Avatar, Box, Button, Switch } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getCompanyOffers, getOfferByID, getUnitsGroups, updateOffer } from 'api/addOnAPI';
import { companySelector } from 'company/state/companySelectors';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { TableCell } from 'ui-library/Components/table/TableCell';

type UpsellsTableProps = {
  title: string | React.ReactNode;
};

type Unit = {
  id: number;
  name: string;
};

type Group = {
  id: number;
  name: string;
};

type Upsell = {
  id: string;
  name: string;
  priceSticker: string;
  imageUrl: string;
  isActive: boolean;
  information: {
    name: string;
    header: null | string;
    subheader: null | string;
    description: string;
    category: string;
  };
  media: {
    files: string[];
  };
  date: {
    header: string;
    datePickerType: string;
  };
  preferredTime: {
    value: string;
  };
  serviceButton: {
    values: string;
  };
  postPurchase: {
    instructions: string;
    active: boolean;
  };
  pricing: {
    price: number;
    taxRate: number;
    paymentType: string;
    confirmationMethod: string;
    quantityName: string;
    quantityStepperEnabled: boolean;
    maxQuantity: number;
  };
  unitsAssigned: Unit[];
  groupsAssigned: Group[];
  units: number[] | undefined;
  groups: number[] | undefined;
};

const UpsellsTable = ({ title }: UpsellsTableProps) => {
  const navigate = useNavigate();
  const company = useAppSelector(companySelector());
  const { snackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState<Upsell[]>([]);
  const { t } = useTranslation();

  const updateOfferActive = (upsellId: string, isActive: boolean) => async () => {
    try {
      setLoading(true);
      const result = await getOfferByID(company?.id, upsellId);
      const data = result?.data?.data;
      data.isActive = isActive;
      const response = await updateOffer(company?.id, data);

      if (response.status === 200) {
        snackbar(t('changes_saved'));
        fetchOffers();
      } else {
        const errorMessage = `Failed to save offer, status: ${response.status}`;
        logger.error(`errorMessage: ${errorMessage}, responseBody: ${response.data.body}`);
        snackbar(errorMessage);
      }
    } catch (error) {
      logger.error(`Failed to update offer, ${error}`);
      snackbar(t('failed_to_update_offer'));
    }
  };

  const fetchOffers = useCallback(async () => {
    try {
      setLoading(true);
      const offersData = await getCompanyOffers(company?.id);
      const offers = offersData?.data?.data;

      const unitsGroupsData = await getUnitsGroups(company?.id);
      const companyUnitsGroups = unitsGroupsData?.data?.data;

      const unitsMap = new Map(companyUnitsGroups.units?.map((unit: Unit) => [unit.id, unit.name]));
      const groupsMap = new Map(
        companyUnitsGroups.groups.map((group: Group) => [group.id, group.name]),
      );

      offers.forEach((upsell: Upsell) => {
        upsell.unitsAssigned = upsell?.units?.map((unitId: number) => {
          const unitName = unitsMap.get(unitId) || 'Unknown';
          return { id: unitId, name: unitName as string };
        });

        upsell.groupsAssigned = upsell?.groups?.map((groupId: number) => {
          const groupName = groupsMap.get(groupId) || 'Unknown';
          return { id: groupId, name: groupName as string };
        });
      });

      setOffers(offers);
      setLoading(false);
    } catch (error) {
      logger.error(`Error fetching upsell offers: ${error}`);
    }
  }, [company?.id]);

  const handleSearch = useCallback(
    (pageNum: number, numPerPage: number, searchString?: string) => {
      if (!searchString?.length) {
        return setFilteredOffers(offers);
      }
      const lowerCaseSearchString = searchString.toLowerCase();
      const filteredOffers = offers.filter((upsell: Upsell) => {
        // filter by active/inactive status
        const isActiveInput = lowerCaseSearchString === 'active';
        const isInactiveInput = lowerCaseSearchString === 'inactive';
        const matchesActiveStatus = isActiveInput
          ? upsell.isActive
          : isInactiveInput
          ? !upsell.isActive
          : false;

        return (
          upsell.name?.toLowerCase().includes(lowerCaseSearchString) ||
          upsell?.information?.category?.toLowerCase().includes(lowerCaseSearchString) ||
          upsell.unitsAssigned.some(
            (unit: Unit) =>
              unit?.name?.toLowerCase().includes(lowerCaseSearchString) ||
              unit?.id.toString().includes(lowerCaseSearchString),
          ) ||
          matchesActiveStatus
        );
      });

      setFilteredOffers(filteredOffers);
    },
    [offers],
  );

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  const handleRowTitleClick = useCallback(
    (id: number) => {
      navigate(`/upsell-items/${id}`);
    },
    [navigate],
  );

  const renderCategory = ({ row }: GridRenderCellParams<Upsell>) => {
    return <Box>{row?.information?.category}</Box>;
  };

  const renderUpsells = ({ row }: GridRenderCellParams<Upsell>) => {
    return (
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          src={row.imageUrl}
          alt={row.name}
          variant='rounded'
          sx={{ marginRight: '10px', borderRadius: '8px' }}
        />
        <Box onClick={() => handleRowTitleClick(row.id)} style={{ cursor: 'pointer' }}>
          <TableCell title={row.name} description={row.description} />
        </Box>
      </Box>
    );
  };

  const renderUnits = ({ row }: GridRenderCellParams<Upsell>) => {
    return (
      <>
        <Button>{row?.unitAssigned?.units.length ?? 0}</Button>
      </>
    );
  };

  const renderGroups = ({ row }: GridRenderCellParams<Upsell>) => {
    return <Button>{row?.groupsAssigned?.groups.length ?? 0}</Button>;
  };

  const renderSwitch = ({ row }: GridRenderCellParams<Upsell>) => (
    <Switch
      checked={row.isActive}
      onChange={updateOfferActive(row.id, !row.isActive)}
      color='primary'
    />
  );

  const columnsDef: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Offer',
      renderCell: renderUpsells,
      flex: 4,
      sortable: true,
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 3,
      sortable: false,
      renderCell: renderCategory,
    },
    { field: 'priceSticker', headerName: 'Price', flex: 3, sortable: false },
    {
      field: 'units',
      headerName: 'Units',
      renderCell: renderUnits,
      sortable: false,
    },
    {
      field: 'groups',
      headerName: 'Unit groups',
      renderCell: renderGroups,
      sortable: false,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      renderCell: renderSwitch,
      width: 100,
      sortable: false,
      flex: 1,
    },
  ];

  return (
    <PaginatedTable
      enableToolbar
      title={title}
      rows={filteredOffers}
      rowCount={filteredOffers.length}
      columns={columnsDef}
      loading={loading}
      onFetch={handleSearch}
    />
  );
};

export default UpsellsTable;
