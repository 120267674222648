import { Box, Switch, Typography } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import useTranslation from 'hooks/useTranslation';
import React, { ChangeEvent } from 'react';
import renderTextField from './TextInput';

interface PreferredTimeProps {
  header: string;
  active: boolean;
  setHeader: (header: string) => void;
  setActive: (active: boolean) => void;
}

export const PreferredTime = ({ header, active, setHeader, setActive }: PreferredTimeProps) => {
  const handlePreferedTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHeader(event.target.value);
  };

  const { t } = useTranslation();

  return (
    <AddCard
      title={t('preferred_time')}
      action={<Switch checked={active} onChange={() => setActive(!active)} />}
    >
      {active && (
        <Box display='flex' flexDirection='column' gap={2}>
          <Typography>
            {t(
              'guests_can_select_am_or_pm_time_slots_from_a_dropdown_menu_covering_the_entire_day',
            )}
          </Typography>
          {renderTextField(
            t('preferred_time_header'),
            t('preferred_time'),
            header,
            handlePreferedTimeChange,
            50,
            true,
            1,
            false,
          )}
        </Box>
      )}
    </AddCard>
  );
};
