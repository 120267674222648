import { MoreVertOutlined } from '@mui/icons-material';
import { IconButton, PopoverOrigin } from '@mui/material';
import { CommunicationType } from 'hooks/useCommunications';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CommunicationsMoreMenu, { MENU_OPTIONS } from './CommunicationsMoreMenu';
import { MenuOptionType } from './CommunicationsNewMenu';

export default function CommunicationsTableAction({
  templateId,
  channelType,
  communications,
}: {
  templateId: string;
  channelType: string;
  communications: CommunicationType[];
}) {
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const menuAnchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  };

  const menuTransformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMoreClose = (selectedOption?: MenuOptionType) => {
    if (selectedOption === MENU_OPTIONS.EDIT) {
      navigate(`/communications/edit/${channelType}/${templateId}`);
    }

    if (selectedOption === MENU_OPTIONS.DELETE) {
      navigate(`/communications/delete/${channelType}/${templateId}`);
    }

    setMenuAnchorEl(null);
  };

  const handleNewLanguageSelect = (newLanguage: string) => {
    navigate(`/communications/create/${channelType}/${templateId}/${newLanguage}`);
  };

  return (
    <>
      <IconButton size='large' onClick={handleMoreClick} role='button' data-testid='more-button'>
        <MoreVertOutlined />
      </IconButton>

      <CommunicationsMoreMenu
        templateId={templateId}
        anchorEl={menuAnchorEl}
        open={menuOpen}
        handleClose={handleMoreClose}
        anchorOrigin={menuAnchorOrigin}
        transformOrigin={menuTransformOrigin}
        onSelectNewLanguage={handleNewLanguageSelect}
        communications={communications}
      />
    </>
  );
}
