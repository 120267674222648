import { Grid } from '@mui/material';
import * as React from 'react';
import UpsellGuestPortalContainer from './UpsellGuestPortalView/UpsellGuestPortalContainer';
import { UpsellFields } from './UpsellPage';
import { Content } from './UpsellTabs/Content';
import { PostPurchaseInstructions } from './UpsellTabs/PostPurchaseInstructions';
import { Pricing } from './UpsellTabs/Pricing';
import { Unit } from './UpsellTabs/Unit';

export interface UpsellDashboardContainerProps {
  tabSelected: string;
  upsellFields: UpsellFields;
  updateUpsellFields: <K extends keyof UpsellFields>(field: K, value: UpsellFields[K]) => void;
}

const selectedTabComponent = (tabSelected: string) => {
  switch (tabSelected) {
    case 'content':
      return Content;
    case 'pricing':
      return Pricing;
    case 'unitAssignment':
      return Unit;
    case 'instructions':
      return PostPurchaseInstructions;
    default:
      return null;
  }
};

const UpsellDashboardContainer = ({
  tabSelected,
  upsellFields,
  updateUpsellFields,
}: UpsellDashboardContainerProps) => {
  const SelectedComponent = selectedTabComponent(tabSelected);
  return (
    <Grid container spacing={2}>
      {SelectedComponent && (
        <SelectedComponent upsellFields={upsellFields} updateUpsellFields={updateUpsellFields} />
      )}
      {tabSelected !== 'unitAssignment' && (
        <Grid item xs={12} md={3}>
          <UpsellGuestPortalContainer />
        </Grid>
      )}
    </Grid>
  );
};

export default UpsellDashboardContainer;
