import { createSelector } from '@reduxjs/toolkit';
import { FilterType, GuestState, IGuest, RecordTrack } from 'guest/guestType';
import { ApplicationState } from 'redux/reducers';

export const getGuests = (state: ApplicationState) => state.guests;

export const guestsByIdSelector = (guestID: number) => {
  return createSelector([getGuests], (guests: GuestState) => {
    const guestsById = guests.byId[guestID];
    return guestsById;
  });
};

export const guestSelector = () => {
  return createSelector([getGuests], (guests: GuestState) => {
    const guestsById = guests.list.map((each: number) => guests.byId[each]);
    return guestsById;
  });
};

export const guestByFilter = (filter: FilterType, propertyId?: number) => {
  return createSelector([getGuests], ({ list, byId, meta }) => {
    const guests: IGuest[] = [];
    list.forEach(id => {
      const guest = byId[id];
      if (propertyId && propertyId !== guest.property_id) {
        return;
      }

      if (filter === FilterType.ALL_GUESTS && guest.type === FilterType.BLOCKED) {
        return;
      }

      if (filter === FilterType.ALL_GUESTS || guest.type === filter) {
        return guests.push(guest);
      }
    });

    return {
      guests,
      isGuestsLoading: meta.isLoading,
      totalGuests: meta[filter].total_records,
    };
  });
};

export const guestsTotalCountSelector = (type: FilterType) =>
  createSelector([getGuests], (guests: GuestState): RecordTrack => guests.meta[type]);

export const guestsMetaSelector = (type?: FilterType) =>
  createSelector([getGuests], (guests: GuestState) => guests.meta[type]);

export default guestsByIdSelector;
