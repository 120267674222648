import {
  CollectionsBookmarkOutlined as CollectionsBookmarkOutlinedIcon,
  Inbox as InboxIcon,
  LocalOffer as LocalOfferIcon,
  LuggageOutlined as LuggageOutlinedIcon,
  Place as PlaceIcon,
} from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

interface BottomNavProps {
  value: number;
  setValue: (value: number) => void;
}

const BottomNav = ({ value, setValue }: BottomNavProps) => {
  const { t } = useTranslation();

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      sx={{ width: '100%' }}
    >
      <BottomNavigationAction disabled label={t('booking')} icon={<LuggageOutlinedIcon />} />
      <BottomNavigationAction
        disabled
        label={t('guides')}
        icon={<CollectionsBookmarkOutlinedIcon />}
      />
      <BottomNavigationAction label={t('offers')} icon={<LocalOfferIcon />} />
      <BottomNavigationAction disabled label={t('local')} icon={<PlaceIcon />} />
      <BottomNavigationAction disabled label={t('inbox')} icon={<InboxIcon />} />
    </BottomNavigation>
  );
};

export default BottomNav;
