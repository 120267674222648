import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { useMedia } from '@operto/ui';
import SwipeableDrawer from 'Common/Drawer/SwipeableDrawer';
import FabIcon from 'Common/Icons/FabIcon';
import { OpertoLogger } from 'Logger/logger';
import { notifyGuestAction } from 'Pages/Messaging/MessagingActions';
import SettingsTabPage from 'Pages/Messaging/Settings/index';
import { getMessengerSetting } from 'company/state/companyAction';
import { messengerSettingSelector } from 'company/state/companySelectors';
import { ClientContext } from 'helper/streamChatHelper';
import { cloneDeep } from 'lodash';
import { getCurrentMember } from 'member/state/memberActions';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ChannelFilters, ChannelSort, Channel as TChannel } from 'stream-chat';
import { Chat } from 'stream-chat-react';
import TabBar from 'ui-library/Components/tabBar/TabBar';
import { userSelector, userStateSelector } from 'user/state/userSelectors';
import ChannelMessagesList from './ChannelMessagesList';
import ChatMessages from './ChatMessages';
import CustomSearchAndFilter from './CustomSearchAndFilter';
import MessagePeopleListChatContainer from './MessagePeopleListChatContainer';
import './MessageStyles.css';
import { MIN_MESSAGES_DATE } from './MessagingConstants';

export enum MessageFilterType {
  GUESTS = 'Guests',
  MEMBERS = 'Members',
}

export enum MessageFilterIndex {
  GUESTS = 0,
  MEMBERS = 1,
  SETTINGS = 2,
}

const labelName = {
  GUESTS: 'Guest',
  MEMBERS: 'Members',
  SETTINGS: 'Settings',
};

export const MessagingPage = () => {
  const { channelId } = useParams<{ channelId: string }>();
  const location = useLocation();
  const isMemberSection = location.pathname.includes('/messenger/members');
  const [isNewChat, setIsNewChat] = useState(false);
  const [currentChannel, setCurrentChannel] = useState(undefined);
  const [filterIndex, setFilterIndex] = useState(
    isMemberSection ? MessageFilterIndex.MEMBERS : MessageFilterIndex.GUESTS,
  );

  const { isDesktop, isTablet, isMobile } = useMedia();

  const featuresSelector = useAppSelector(userStateSelector());

  const [previousChannelId, setPreviousChannelId] = useState(undefined);

  const [searchValue, setSearchValue] = useState(undefined);

  const [selectedChips, setSelectedChips] = useState([]);

  const [showFullSearchBar, setShowFullSearchBar] = useState(!isMobile);

  const [showMessagesTitle, setShowMessagesTitle] = useState(true);

  const navigate = useNavigate();

  const messengerSetting = useAppSelector(messengerSettingSelector());
  const guestMessengerEnabled = messengerSetting.mp_guest_messenger_enabled;
  const memberMessengerEnabled = messengerSetting.mp_member_messenger_enabled;

  const showFloatingFab = !isDesktop && !currentChannel;

  const isMobileChatOpened = isMobile && currentChannel;

  const isMessengerEnabled =
    (filterIndex === MessageFilterIndex.GUESTS && guestMessengerEnabled) ||
    (filterIndex === MessageFilterIndex.MEMBERS && memberMessengerEnabled);

  const onSelectedChipsChange = (chips: string[]) => {
    setSelectedChips(chips);
  };

  const handleNewChat = (onNewChat: boolean) => {
    setIsNewChat(onNewChat);
  };

  const setTabIndex = (value: number) => {
    setFilterIndex(value);
    setCurrentChannel(undefined);
  };

  const dispatch = useAppDispatch();

  const loggedInMember = useAppSelector(userSelector());

  const handleChannelOnClick = (channel: TChannel) => {
    navigate(`/messenger/${channel.id}`);
  };

  const contextType = ClientContext;
  const { clientObject } = useContext(contextType);
  const { streamChatClient } = clientObject;

  const isMemberChannel = (channelId: string) => channelId.includes('members');

  const queryForChannel = useCallback(
    async (channelId: string) => {
      const sort: ChannelSort = [{ last_updated: -1 }];
      const filter = {
        id: { $eq: channelId },
      };
      const channels = await streamChatClient.queryChannels(filter, sort, {
        watch: true,
        state: true,
      });
      return channels[0];
    },
    [streamChatClient],
  );

  useEffect(() => {
    dispatch(getCurrentMember());
    dispatch(getMessengerSetting());
  }, [dispatch]);

  useEffect(() => {
    if (channelId !== undefined && channelId !== previousChannelId && streamChatClient?.user?.id) {
      queryForChannel(channelId)
        .then(data => {
          setCurrentChannel(data);
          if (isMemberChannel(channelId)) {
            setFilterIndex(MessageFilterIndex.MEMBERS);
          } else {
            setFilterIndex(MessageFilterIndex.GUESTS);
          }

          data?.on(chatEvent => {
            if (chatEvent.type === 'message.new' && chatEvent.user?.id?.includes('mid')) {
              const reservationId = channelId?.split('-')?.[1];
              const chatMessage = chatEvent?.message?.text;
              if (reservationId && chatMessage)
                dispatch(notifyGuestAction(reservationId, chatMessage));
            }
          });
        })
        .catch(err => OpertoLogger.Log(err));
    }
    if (channelId !== previousChannelId) setPreviousChannelId(channelId);
  }, [
    loggedInMember?.getStreamUser?.id,
    channelId,
    previousChannelId,
    streamChatClient?.user?.id,
    dispatch,
    queryForChannel,
  ]);

  if (!streamChatClient?.user?.id) return null;

  let guestFilters: ChannelFilters = {
    members: { $in: [loggedInMember?.getStreamUser?.id] },
    channel_data: 'guests',
    last_message_at: { $gt: MIN_MESSAGES_DATE },
  };
  if (searchValue) {
    guestFilters = {
      ...guestFilters,
      $or: [
        { name: { $autocomplete: searchValue } },
        { property_name: { $autocomplete: searchValue } },
        { property_id: { $eq: searchValue } },
      ],
    };
  }

  const memberFilters = {
    members: { $in: [loggedInMember?.getStreamUser?.id] },
    channel_data: 'members',
    name: searchValue ? { $autocomplete: searchValue } : undefined,
    last_message_at: { $gt: MIN_MESSAGES_DATE },
  };

  const addChipFilters = (filters: object) => {
    let chipFilters = cloneDeep(filters);

    if (selectedChips.includes('Unread')) {
      chipFilters = { ...chipFilters, unread: true };
    }
    if (selectedChips.includes('Read')) {
      chipFilters = { ...chipFilters, members: { $in: [loggedInMember?.getStreamUser?.id] } };
    }
    if (selectedChips.includes('Favourites')) {
      chipFilters = { ...chipFilters, favourite: true };
    }

    return chipFilters;
  };

  const handleFilterToggle = (filterIndex: MessageFilterIndex) => {
    if (filterIndex === MessageFilterIndex.GUESTS) {
      return addChipFilters(guestFilters);
    } else {
      return addChipFilters(memberFilters);
    }
  };

  let upperTabItems;

  if (featuresSelector.features?.mp_guest_portal_onboarding_enabled) {
    upperTabItems = [
      { label: labelName.GUESTS, value: MessageFilterIndex.GUESTS, isDropDown: false },
      { label: labelName.MEMBERS, value: MessageFilterIndex.MEMBERS, isDropDown: false },
      {
        label: labelName.SETTINGS,
        value: MessageFilterIndex.SETTINGS,
        isDropDown: false,
        icon: <SettingsIcon />,
      },
    ];
  } else {
    upperTabItems = [
      { label: labelName.MEMBERS, value: MessageFilterIndex.MEMBERS, isDropDown: false },
      {
        label: labelName.SETTINGS,
        value: MessageFilterIndex.SETTINGS,
        isDropDown: false,
        icon: <SettingsIcon />,
      },
    ];
  }

  const handleSearch = (search: string) => {
    setSearchValue(search);
  };

  const chipFilters = ['Unread', 'Read', 'Favourites'];

  const onCloseDrawer = () => {
    handleNewChat(false);
  };

  const renderDesktopHeader = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRight: theme => `1px solid ${theme.palette.divider}`,
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '16px',
            padding: '12px 16px 12px 16px',
          }}
        >
          <Typography variant='h4'>
            {`${filterIndex === MessageFilterIndex.GUESTS ? 'Guest' : 'Member'} messages`}
          </Typography>

          {isMessengerEnabled && (
            <FabIcon
              icon={<EditIcon />}
              size='small'
              color='primary'
              ariaLabel='add'
              onClick={() => handleNewChat(true)}
            />
          )}
        </Box>

        <Box sx={{ paddingLeft: '8px', paddingRight: '15px' }}>
          <CustomSearchAndFilter
            onSelectedChipsChange={onSelectedChipsChange}
            selectedChips={selectedChips}
            onSearchChange={handleSearch}
            searchValue={searchValue}
            chipFilters={chipFilters}
            showFullSearchBar={showFullSearchBar}
            onSearchIconClick={(value: boolean) => {
              setShowFullSearchBar(value);
            }}
            showMessagesTitle={(value: boolean) => {
              setShowMessagesTitle(value);
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderMobileAndTabletHeader = () => {
    return (
      <Box
        sx={{
          padding: '12px 16px',
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {showMessagesTitle && (
            <Box>
              <Typography variant='subtitle1'>
                {isTablet && 'All messages'}
                {isMobile &&
                  `${filterIndex === MessageFilterIndex.GUESTS ? 'Guest' : 'Member'} messages`}
              </Typography>
            </Box>
          )}
          <Box sx={{ width: !showMessagesTitle ? '100%' : 'inherit' }}>
            <CustomSearchAndFilter
              onSelectedChipsChange={onSelectedChipsChange}
              selectedChips={selectedChips}
              onSearchChange={handleSearch}
              searchValue={searchValue}
              chipFilters={chipFilters}
              showFullSearchBar={showFullSearchBar}
              onSearchIconClick={(value: boolean) => {
                setShowFullSearchBar(value);
              }}
              showMessagesTitle={(value: boolean) => {
                setShowMessagesTitle(value);
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {!isMobileChatOpened && (
        <TabBar tabItems={upperTabItems} tabIndex={filterIndex} onTabChange={setTabIndex} />
      )}

      {isMessengerEnabled && (
        <Paper
          sx={{
            width: '100%',
            height: 'calc(100vh - 235px)',
            boxShadow: !isDesktop && 'none',
          }}
        >
          {!isDesktop && !isMobileChatOpened && renderMobileAndTabletHeader()}
          <Stack direction='row' height='100%'>
            <Chat client={streamChatClient}>
              <ChannelMessagesList
                previousChannelId={previousChannelId}
                currentChannel={currentChannel}
                handleNewChat={handleNewChat}
                handleChannelOnClick={handleChannelOnClick}
                handleFilterToggle={handleFilterToggle}
                filterIndex={filterIndex}
              >
                {isDesktop && renderDesktopHeader()}
              </ChannelMessagesList>

              <ChatMessages
                currentChannel={currentChannel}
                setCurrentChannel={setCurrentChannel}
                handleNewChat={handleNewChat}
                handleChannelOnClick={handleChannelOnClick}
                isNewChat={isNewChat}
                filterIndex={filterIndex}
                isMessengerEnabled={isMessengerEnabled}
              />

              {isNewChat && !isDesktop && (
                <SwipeableDrawer anchor='bottom' onClose={onCloseDrawer}>
                  <MessagePeopleListChatContainer
                    onNewChat={handleNewChat}
                    peopleType={filterIndex}
                    user={loggedInMember}
                    lastClickedOnChannel={handleChannelOnClick}
                  />
                </SwipeableDrawer>
              )}
            </Chat>
          </Stack>
        </Paper>
      )}

      {filterIndex === MessageFilterIndex.SETTINGS && <SettingsTabPage active />}

      {showFloatingFab && (
        <FabIcon
          icon={<EditIcon />}
          size='large'
          color='primary'
          ariaLabel='add'
          onClick={() => handleNewChat(true)}
          styles={{ position: 'fixed', bottom: '16px', right: '24px', zIndex: 1000 }}
        />
      )}
    </>
  );
};

export default MessagingPage;
