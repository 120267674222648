import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertColor, Button, Snackbar } from '@mui/material';
import { useMedia } from '@operto/ui';
import React from 'react';

export interface ActionSnackbarProps {
  onClose: () => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  severity: AlertColor;
  actionButtonText: string;
  snackbarMessage: string;
}
const ActionSnackbar = ({
  onClose,
  open,
  setOpen,
  severity,
  actionButtonText,
  snackbarMessage,
}: ActionSnackbarProps) => {
  const vertical = 'top';
  const horizontal = 'center';
  const { isMobile } = useMedia();
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical, horizontal }}
      sx={{ width: isMobile ? '100%' : '75%' }}
    >
      <Alert
        variant='filled'
        elevation={6}
        severity={severity}
        action={
          <>
            <Button color='inherit' size='small' onClick={onClose}>
              {actionButtonText}
            </Button>
            <CloseIcon
              onClick={() => {
                setOpen(false);
              }}
              sx={{ cursor: 'pointer' }}
            />
          </>
        }
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default ActionSnackbar;
