import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ApplicationState } from './reducers';

export const NEO_REST_API = process.env.REACT_APP_NEO_REST_API;

const opertoFetchBaseQuery = (baseUrl: string) => {
  return fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const appState: ApplicationState = getState() as ApplicationState;
      const { accessToken } = appState.user;
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
  });
};

export const RTKQapi = createApi({
  baseQuery: opertoFetchBaseQuery(process.env.REACT_APP_API_URL),
  refetchOnFocus: false,
  refetchOnReconnect: true,
  endpoints: () => ({}),
});

export const NeoRestRTKQapi = createApi({
  baseQuery: opertoFetchBaseQuery(NEO_REST_API),
  reducerPath: 'NeoRestApi',
  refetchOnFocus: false,
  refetchOnReconnect: true,
  endpoints: () => ({}),
});
