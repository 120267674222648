import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Box, Button, Chip } from '@mui/material';
import { Text } from '@operto/ui';
import UnlinkAccountDialog from 'Common/Dialog/UnlinkAccountDialog';
import { companySelector } from 'company/state/companySelectors';
import { useAppFeatures } from 'lib/app-features';
import React from 'react';
import { useForm } from 'react-hook-form';
import { GuestProfile } from 'redux/guest-service/types';
import { useAppSelector } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import AddCard from 'ui-library/Components/card/AdderCard';
import {
  FormTextField,
  FormTextFieldRegexEmail,
  FormTextFieldRegexPhone,
} from 'ui-library/Components/input/FormTextField';

interface GuestProfileCardProps {
  guestProfile: GuestProfile;
  reservation: IReservation;
  onUnlinkSuccess: (message: string) => void;
}

const GuestProfileCard = ({
  guestProfile,
  reservation,
  onUnlinkSuccess,
}: GuestProfileCardProps) => {
  const { control } = useForm<IReservation>({ mode: 'all' });
  const [openUnlinkDialog, setOpenUnlinkDialog] = React.useState(false);
  const company = useAppSelector(companySelector());
  const { guestProfile: guestProfileEnabled } = useAppFeatures();
  const handleUnlinkAccount = () => {
    setOpenUnlinkDialog(true);
  };

  const renderCompletedChip = () => {
    return (
      <Chip
        data-testid='check-icon'
        size='small'
        icon={<CheckCircleIcon style={{ fontSize: 14 }} />}
        label='Completed'
        sx={{
          fontSize: 10,
          color: '#216E4E',
          backgroundColor: '#BBF3DA',
          borderRadius: '4px',
          '& .MuiChip-icon': { color: '#216E4E' },
        }}
      />
    );
  };

  if (!(guestProfileEnabled && company?.newLoginEnabled)) {
    return null;
  }

  return (
    <AddCard title='Account' subtitle={renderCompletedChip()}>
      <FormTextField
        field='guest_registration_first_name'
        label='First name'
        value={guestProfile.givenName}
        disabled={true}
        control={control}
      />
      <FormTextField
        field='guest_registration_preferred_name'
        label='Preferred name (optional)'
        value={guestProfile.preferredName}
        disabled={true}
        control={control}
      />
      <FormTextField
        field='guest_registration_last_name'
        label='Last name'
        value={guestProfile.familyName}
        disabled={true}
        control={control}
      />
      <FormTextField
        rules={{
          pattern: {
            value: FormTextFieldRegexEmail,
            message: 'Valid email address format is required',
          },
        }}
        field='guest_registration_email'
        type='email'
        label='Registration email'
        value={guestProfile.emailPrimary}
        disabled={true}
        control={control}
      />
      <FormTextField
        rules={{
          pattern: {
            value: FormTextFieldRegexPhone,
            message: 'Valid phone number format is required',
          },
        }}
        field='guest_registration_phone'
        type='tel'
        label='Phone number (optional)'
        value={guestProfile.phonePrimary}
        disabled={true}
        control={control}
      />
      <Box display='flex' justifyContent='flex-end'>
        <Button variant='outlined' onClick={handleUnlinkAccount}>
          <LinkOffIcon />
          <Text style={{ marginLeft: 1, fontSize: 12, fontWeight: 'bold' }}>Unlink account</Text>
        </Button>
      </Box>
      <UnlinkAccountDialog
        onClose={() => setOpenUnlinkDialog(false)}
        open={openUnlinkDialog}
        reservation={reservation}
        guestId={guestProfile.id}
        onUnlinkSuccess={onUnlinkSuccess}
      />
    </AddCard>
  );
};

export default GuestProfileCard;
