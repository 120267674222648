import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

const OrderSummary = () => (
  <>
    <Typography variant='h6' gutterBottom>
      Order summary
    </Typography>
    {['Subtotal', 'Delivery', 'Service fee', 'Estimated tax'].map(item => (
      <Box display='flex' justifyContent='space-between' key={item}>
        <Typography variant='body2'>{item}</Typography>
        <Typography variant='body2'>$0.00</Typography>
      </Box>
    ))}
    <Divider sx={{ my: 2 }} />
    <Typography variant='h6'>Total $0.00</Typography>
    <Typography variant='body2' color='primary' gutterBottom>
      Lorem ipsum <a href='#'>Upsell Terms</a>.
    </Typography>
  </>
);

export default OrderSummary;
