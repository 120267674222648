import { useEffect, useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';
import { FilterGroup } from 'types/filter-type';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';
import { useFilterLocalStorage } from './useFilterLocalStorage';
import { useSyncFilters } from './useSyncFilters';
import useTranslation from './useTranslation';

export default function useAutomateFilters() {
  const loggedInMember: UserState = useAppSelector(userSelector());
  const { getFilters, setFilters } = useFilterLocalStorage();
  const { t } = useTranslation();

  const filterGroupsDefinition: FilterGroup[] = useMemo(
    () => [
      {
        groupName: 'channel',
        label: 'Channel',
        options: [
          { filterType: 'checkbox', label: 'Email', name: 'email' },
          { filterType: 'checkbox', label: 'SMS', name: 'sms' },
        ],
      },
      {
        groupName: 'active',
        label: 'Active',
        options: [
          { filterType: 'checkbox', label: t('active'), name: 'true' },
          { filterType: 'checkbox', label: t('inactive'), name: 'false' },
        ],
      },
    ],
    [t],
  );

  const keyStorage = `${loggedInMember.user.id}_${STORAGE_KEY}`;

  const {
    syncStatus,
    searchParams,
    filterGroups = [],
    syncFilters,
    addFilterGroups,
    setSyncStatus,
  } = useSyncFilters({
    key: keyStorage,
    persistFilters: setFilters,
    hydrateFilters: getFilters,
  });

  const onFilterChange = (params: URLSearchParams) => {
    syncFilters(params);
  };

  const onFilterClear = (filterGroupKey: string) => {
    searchParams.delete(filterGroupKey);

    syncFilters(searchParams);
  };

  const isClearableFilters = (filterGroupKey: string) => {
    return searchParams.get(filterGroupKey)?.length > 0;
  };

  const updateChipLabel = (filterGroup: string) => {
    let count = 0;
    let updatedLabel = '';

    const filterValue = searchParams.get(filterGroup)?.split(',') ?? [];

    const selectedFilterGroup = filterGroups.find(
      filterOption => filterOption.groupName === filterGroup,
    );
    const selectedFilter = selectedFilterGroup?.options.find(
      option => option.name === filterValue[0],
    );

    updatedLabel = filterValue.length != 0 ? selectedFilter.label : updatedLabel;
    count = filterValue.length - 1 > 0 ? filterValue.length - 1 : 0;

    return updatedLabel !== '' ? `${updatedLabel} ${count > 0 ? `+${count}` : ''}` : '';
  };

  const updateTotalFilterLabelMobile = () => {
    return Array.from(searchParams).length > 0 ? Array.from(searchParams).length : '';
  };

  useEffect(() => {
    // if filters are not synched, add filter groups and sync filters
    if (syncStatus === 'unsynched') {
      setSyncStatus('synching');
      addFilterGroups(filterGroupsDefinition);
      syncFilters(getFilters(keyStorage));
    }
  }, [
    addFilterGroups,
    setSyncStatus,
    syncStatus,
    getFilters,
    keyStorage,
    syncFilters,
    filterGroupsDefinition,
  ]);

  return {
    isSynched: syncStatus === 'synched',
    filterGroups,
    searchParams,
    onFilterChange,
    onFilterClear,
    isClearableFilters,
    updateChipLabel,
    updateTotalFilterLabelMobile,
  };
}

const STORAGE_KEY = 'automate';
