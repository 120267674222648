import { Box, Switch, Typography } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import useTranslation from 'hooks/useTranslation';
import React, { ChangeEvent } from 'react';
import renderTextField from './TextInput';

interface InstructionSectionProps {
  instructions: string;
  active: boolean;
  setInstructions: (instructions: string) => void;
  setActive: (active: boolean) => void;
}

export const InstructionSection = ({
  instructions,
  active,
  setInstructions,
  setActive,
}: InstructionSectionProps) => {
  const maxLength = 1000;
  const handleInstructionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInstructions(event.target.value);
  };
  const { t } = useTranslation();

  return (
    <AddCard
      title={
        <>
          <Box display='flex' flexDirection='row' justifyContent='flex-start'>
            <Box display='flex' flexDirection='column' justifyContent='space-between'>
              <Typography variant='subtitle1'>{t('post_purchase_instructions')}</Typography>
              <Typography variant='body2'>
                {t('guests_can_view_instructions_after_purchase')}
              </Typography>
            </Box>
          </Box>
        </>
      }
      action={<Switch checked={active} onChange={() => setActive(!active)} />}
    >
      {active && (
        <Box display='flex' flexDirection='column' gap={2}>
          {renderTextField(
            t('instructions'),
            t('instructions'),
            instructions,
            handleInstructionsChange,
            maxLength,
            false,
            30,
            false,
          )}
        </Box>
      )}
    </AddCard>
  );
};
