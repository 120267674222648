import { useAutomate } from 'hooks/useAutomate';
import useTranslation from 'hooks/useTranslation';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import AutomateCreateWorkflowDiagram from './AutomateCreateWorkflowDiagram';
import AutomateCreateWorkflowTitlebar from './AutomateCreateWorkflowTitlebar';
import AutomateCreateWorkflowUnits from './AutomateCreateWorkflowUnits';

const initialName = 'Untitled flow';
const MAX_NAME_LENGTH = 50;

export default function AutomateCreateWorkflowPage() {
  const { templateId } = useParams();
  const { workflows } = useAutomate();
  const { t } = useTranslation();

  const [selectedUnits, setSelectedUnits] = useState<number[]>([]);
  const [workflow, setWorkflow] = useState({
    id: templateId,
    name: initialName,
    enabled: false,
  });

  // TODO: remove this line once we use variable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isNameDirty, setIsNameDirty] = useState(false);
  const [errorName, setErrorName] = useState<string>();
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [assignToUnitsError, setAssignToUnitsError] = useState(false);

  const invalidWorkflow = !!errorName;

  const validateNameChange = useCallback(
    (name: string) => {
      const trimmedName = name.trim();

      if (workflows?.some(c => c.name.trim() === trimmedName && c.id !== workflow.id)) {
        setErrorName(t('name_taken'));
        return false;
      }

      if (trimmedName.length === 0) {
        setErrorName(t('name_required'));
        return false;
      }

      if (trimmedName.length > MAX_NAME_LENGTH) {
        setErrorName(`${trimmedName.length}/${MAX_NAME_LENGTH}`);
        return false;
      }

      setErrorName(undefined);
      return true;
    },
    [workflows, workflow.id, t],
  );

  const handleSaveConfirm = async () => {
    // if failed validation, show errors and do not proceed
    if (!validateNameChange(workflow.name) || invalidWorkflow) {
      return;
    }

    setIsSaving(true);

    // TODO: adapt to new API
    // const onSuccess = async (navigateTo: string) => {
    //   setIsRefetching(true);

    //   await templateRefetch(template.id);
    //   await communicationsRefetch();
    //   navigate(navigateTo);

    //   snackbar(t('changes_saved'));

    //   setIsSaving(false);
    //   setIsRefetching(false);
    //   setIsMessageDirty(false);
    //   setIsNameDirty(false);
    //   setEditMode(false);
    // };

    // const onError = () => {
    //   snackbar(t('error_saving'));
    // };

    // try {
    //   if (isCreate) {
    //     // Create default template
    //     if (!language) {
    //       const newRecord = await createTemplate({
    //         name: template.name,
    //         defaultLocale: template.locale,
    //         contents: [
    //           {
    //             data: [
    //               {
    //                 locale: template.locale,
    //                 body: template.message,
    //               },
    //             ],
    //             channelType: template.channelType,
    //           },
    //         ],
    //       });

    //       onSuccess(`/communications/edit/sms/${newRecord.id}`);
    //     }

    //     // Create template with language
    //     if (language) {
    //       // Update name
    //       if (isNameDirty) {
    //         await updateTemplate({
    //           templateId: template.id,
    //           name: template.name,
    //         });
    //       }

    //       await createContents({
    //         templateId: template.id,
    //         contentId: template.contentId,
    //         content: {
    //           locale: template.locale,
    //           body: template.message,
    //         },
    //       });

    //       onSuccess(`/communications/edit/sms/${template.id}/${template.locale}`);
    //     }
    //   } else {
    //     // Update name
    //     if (isNameDirty) {
    //       await updateTemplate({
    //         templateId: template.id,
    //         name: template.name,
    //       });
    //     }

    //     // Update content
    //     if (isMessageDirty) {
    //       await updateContents({
    //         contentId: template.contentId,
    //         templateId: template.id,
    //         locale: template.locale,
    //         body: template.message,
    //       });
    //     }

    //     onSuccess(
    //       `/communications/edit/sms/${template.id}/${
    //         template.locale === 'en' ? '' : template.locale
    //       }`,
    //     );
    //   }
    // } catch (error) {
    //   logger.debug(error);
    //   onError();
    // }
  };

  const handleEnableWorkflowClick = async () => {
    if (selectedUnits.length === 0) {
      setAssignToUnitsError(true);
      return;
    }

    setAssignToUnitsError(false);
    setWorkflow(workflow => ({ ...workflow, enabled: !workflow.enabled }));
  };

  const handleSelectingUnits = useCallback((units: number[]) => {
    setWorkflow(workflow => ({ ...workflow, enabled: false }));
    setSelectedUnits(units);
    setAssignToUnitsError(false);
  }, []);

  return (
    <>
      <AutomateCreateWorkflowTitlebar
        title={workflow.name}
        onTitleChange={name => {
          setIsNameDirty(true);
          validateNameChange(name);
          setWorkflow({ ...workflow, name, enabled: false });
        }}
        onSaveClick={handleSaveConfirm}
        deleteDisabled={!workflow.id}
        helperText={errorName}
        editMode={editMode}
        setEditMode={setEditMode}
        onEnableWorkflowClick={handleEnableWorkflowClick}
        isSaving={isSaving}
        isWorkflowEnabled={workflow.enabled}
      />

      <AutomateCreateWorkflowUnits
        selectedUnits={selectedUnits}
        setSelectedUnits={handleSelectingUnits}
        assignToUnitsError={assignToUnitsError}
      />

      <AutomateCreateWorkflowDiagram onClickBefore={() => void 0} onClickAfter={() => void 0} />
    </>
  );
}
