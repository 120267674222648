import axios from 'axios';

const api = process.env.REACT_APP_API_URL;

export const lock = (lockId: string) =>
  axios.post(`${api}/v2/locks/${lockId}/lock`);

export const unlock = (lockId: string) =>
  axios.post(`${api}/v2/locks/${lockId}/unlock`);

export const getLocks = (propertyId: string) =>
  axios.get(`${api}/v1/locks?property_id=${propertyId}`);

export const getLockStatus = (lockId: string) =>
  axios.get(`${api}/v2/locks/${lockId}/status`);
