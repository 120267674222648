import { Grid } from '@mui/material';
import { ContentData } from '@operto/communications-shared';
import { isPossiblePhoneNumber, isValidPhoneNumber, TextFieldPhone } from '@operto/ui';
import { Loading } from 'Common/Loading';
import useCommunications, { ChannelType, transformMockVariables } from 'hooks/useCommunications';
import useNotifications from 'hooks/useNotifications';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation, { SupportedLocale } from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CommunicationsSendConfirmDialog from '../CommunicationsSendConfirmDialog';
import CommunicationsTemplateTitlebar from '../CommunicationsTemplateTitlebar';
import CommunicationsSMSTemplateEdit from './CommunicationsSMSTemplateEdit';
import CommunicationsSMSTemplatePreview from './CommunicationsSMSTemplatePreview';
import { defaultTemplate } from './defaultTemplate';

const initialName = 'Untitled SMS';
export const MAX_SMS_LENGTH = 310;
const MAX_NAME_LENGTH = 50;

export type SMSTemplateType = {
  id?: string;
  name: string;
  channelType: ChannelType;
  locale: SupportedLocale;
  message: string;
  contentId?: string;
};

export default function CommunicationsSMSTemplatePage() {
  const navigate = useNavigate();
  const { t, locale } = useTranslation();
  const { sendSms } = useNotifications();
  const { snackbar } = useSnackbar();

  const {
    data: communications,
    createTemplate,
    createContents,
    updateTemplate,
    updateContents,
    communicationsRefetch,
    getTemplate,
    templateRefetch,
  } = useCommunications();

  const { templateId, language } = useParams();
  const { pathname } = useLocation();
  const isCreate = pathname.includes('create');

  const [isSendingTest, setIsSendingTest] = useState(false);
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [sendDialogPhone, setSendDialogPhone] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const [isMessageDirty, setIsMessageDirty] = useState(false);
  const [isNameDirty, setIsNameDirty] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorName, setErrorName] = useState<string>();
  const [template, setTemplate] = useState<SMSTemplateType>({
    id: templateId,
    name: initialName,
    channelType: 'sms',
    locale: (language as SupportedLocale) ?? 'en',
    message: defaultTemplate,
  });

  const isValidPhone =
    !sendDialogPhone ||
    (isPossiblePhoneNumber(sendDialogPhone) && isValidPhoneNumber(sendDialogPhone));
  const sendDialogDisabled = !sendDialogPhone || !isValidPhone;

  const { data: templateData, isLoading } = getTemplate(templateId);

  const invalidTemplate =
    !template.message || template.message.length > MAX_SMS_LENGTH || !!errorName;

  const handleTemplateChange = (newTemplate: SMSTemplateType) => {
    setTemplate(newTemplate);
    setIsMessageDirty(true);
  };

  const validateNameChange = useCallback(
    (name: string) => {
      const trimmedName = name.trim();

      if (communications?.some(c => c.name.trim() === trimmedName && c.id !== template.id)) {
        setErrorName(t('name_taken'));
        return false;
      }

      if (trimmedName.length === 0) {
        setErrorName(t('name_required'));
        return false;
      }

      if (trimmedName.length > MAX_NAME_LENGTH) {
        setErrorName(`${trimmedName.length}/${MAX_NAME_LENGTH}`);
        return false;
      }

      setErrorName(undefined);
      return true;
    },
    [communications, template.id, t],
  );

  const handleSaveConfirm = async () => {
    // if failed validation, show errors and do not proceed
    if (!validateNameChange(template.name) || invalidTemplate) {
      return;
    }

    setIsSaving(true);

    const onSuccess = async (navigateTo: string) => {
      setIsRefetching(true);

      await templateRefetch(template.id);
      await communicationsRefetch();
      navigate(navigateTo);

      snackbar(t('changes_saved'));

      setIsSaving(false);
      setIsRefetching(false);
      setIsMessageDirty(false);
      setIsNameDirty(false);
      setEditMode(false);
    };

    const onError = () => {
      snackbar(t('error_saving'));
    };

    try {
      if (isCreate) {
        // Create default template
        if (!language) {
          const newRecord = await createTemplate({
            name: template.name,
            defaultLocale: template.locale,
            contents: [
              {
                data: [
                  {
                    locale: template.locale,
                    body: template.message,
                  },
                ],
                channelType: template.channelType,
              },
            ],
          });

          onSuccess(`/communications/edit/sms/${newRecord.id}`);
        }

        // Create template with language
        if (language) {
          // Update name
          if (isNameDirty) {
            await updateTemplate({
              templateId: template.id,
              name: template.name,
            });
          }

          await createContents({
            templateId: template.id,
            contentId: template.contentId,
            content: {
              locale: template.locale,
              body: template.message,
            },
          });

          onSuccess(`/communications/edit/sms/${template.id}/${template.locale}`);
        }
      } else {
        // Update name
        if (isNameDirty) {
          await updateTemplate({
            templateId: template.id,
            name: template.name,
          });
        }

        // Update content
        if (isMessageDirty) {
          await updateContents({
            contentId: template.contentId,
            templateId: template.id,
            locale: template.locale,
            body: template.message,
          });
        }

        onSuccess(
          `/communications/edit/sms/${template.id}/${
            template.locale === 'en' ? '' : template.locale
          }`,
        );
      }
    } catch (error) {
      logger.debug(error);
      onError();
    }
  };

  const handleSendClick = () => {
    setSendDialogOpen(true);
  };

  const handleSendDialog = async () => {
    setIsSendingTest(true);

    const message = transformMockVariables(template.message);

    try {
      await sendSms({
        body: message,
        to: sendDialogPhone,
        messagingServiceSid: process.env.REACT_APP_COMM_TWILIO_SID,
      });

      setSendDialogPhone('');
      setSendDialogOpen(false);
      setIsSendingTest(false);
      snackbar(t('test_sent'));
    } catch (error) {
      snackbar(t('error_sending'));
      setIsSendingTest(false);
      logger.debug(error);
    }
  };

  useEffect(() => {
    if (isRefetching) return;

    if (!isCreate && templateData) {
      const { id: templateId, name, contents } = templateData;

      const { channelType, data, id } = contents[0] as {
        id: string;
        channelType: ChannelType;
        data: ContentData[];
      };

      const { locale, body } = data.find(d => d.locale === (language ?? 'en')) ?? data[0];

      setTemplate({
        id: templateId,
        name,
        message: body,
        locale: locale,
        channelType,
        contentId: id,
      });

      setIsMessageDirty(false);
      setIsNameDirty(false);
      validateNameChange(name);
    }

    if (isCreate && templateData) {
      const templateName = templateData?.name;
      const contents = templateData?.contents;
      const { data, id } = contents[0] as {
        id: string;
        channelType: ChannelType;
        data: ContentData[];
      };

      // Set default english message as new localized template message
      const defaultMessage = data.find(d => d.locale === 'en')?.body ?? data[0].body;

      setTemplate(template => ({
        ...template,
        id: templateId,
        name: templateName ?? initialName,
        locale: (language as SupportedLocale) ?? 'en',
        message: defaultMessage,
        contentId: id,
      }));

      setIsMessageDirty(false);
      setIsNameDirty(false);
      validateNameChange(templateName ?? initialName);
    }
  }, [templateData, validateNameChange, isCreate, templateId, language, isRefetching]);

  if (templateId && isLoading) {
    return <Loading />;
  }

  return (
    <>
      <CommunicationsTemplateTitlebar
        title={template.name}
        onTitleChange={name => {
          setIsNameDirty(true);
          validateNameChange(name);
          setTemplate({ ...template, name });
        }}
        onSaveClick={handleSaveConfirm}
        deleteDisabled={!template.id}
        helperText={errorName}
        editMode={editMode}
        setEditMode={setEditMode}
        onSendClick={handleSendClick}
        isSaving={isSaving}
      />

      <Grid container flex={1} gap={'24px'} overflow={'hidden'}>
        <Grid item flex={1} display={'flex'} maxHeight={'100%'}>
          <CommunicationsSMSTemplateEdit
            template={template}
            onChange={handleTemplateChange}
            isTemplateDirty={isMessageDirty || isNameDirty}
          />
        </Grid>

        <Grid item display={'flex'} maxHeight={'100%'}>
          <CommunicationsSMSTemplatePreview message={template?.message} />
        </Grid>
      </Grid>

      <CommunicationsSendConfirmDialog
        open={sendDialogOpen}
        onClose={() => {
          setSendDialogPhone('');
          setSendDialogOpen(false);
        }}
        onSend={handleSendDialog}
        sendDisabled={sendDialogDisabled}
        loading={isSendingTest}
      >
        <TextFieldPhone
          useFlag
          value={sendDialogPhone}
          onPhoneChange={setSendDialogPhone}
          helperText={!isValidPhone ? t('phone_is_invalid') : undefined}
          locale={locale}
          defaultCountry='US'
        />
      </CommunicationsSendConfirmDialog>
    </>
  );
}
