import { Card, CardActionArea, CardMedia, Stack, SxProps, Typography } from '@mui/material';
import React from 'react';

export default function CommunicationsBlankPagePanelCard({
  onClick,
  image,
  titleIcon,
  title,
  subtitle,
  description,
}: {
  onClick: () => void;
  image: string;
  titleIcon: React.ReactNode;
  title: string;
  subtitle: string;
  description: string;
}) {
  return (
    <Card elevation={6}>
      <CardActionArea onClick={() => onClick()} sx={cardStyles} role='button'>
        <CardMedia sx={cardImageStyles} image={image} />

        <Typography variant='h3-700' display={'flex'} gap={'8px'} alignItems={'center'}>
          {titleIcon}
          {title}
        </Typography>

        <Stack flexDirection='column'>
          <Typography variant='body-lg-600'>{subtitle}</Typography>
          <Typography variant='body-lg-400'>{description}</Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
}

const cardStyles: SxProps = {
  width: '350px',
  height: '380px',
  borderRadius: '16px',
  padding: '16px',
  gap: '16px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  alignItems: 'flex-start',
};

const cardImageStyles: SxProps = {
  width: '318px',
  height: '200px',
};
