import { Box } from '@mui/material';
import { ICompany } from 'company/companyType';
import { IMember } from 'member/memberType';
import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import TabBar from 'ui-library/Components/tabBar/TabBar';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';
import LinkedAccounts from './LinkedAccounts/LinkedAccounts';
import BusinessInfoContainer from './TabContainers/BusinessInfoContainer';
import PaymentInfoContainer from './TabContainers/PaymentContainer';
import PreferencesContainer from './TabContainers/PreferencesContainer';

export interface AccountContainerProps {
  member: IMember;
  company: ICompany;
}

export const BUSINESS_INFO = 0;
export const PAYMENT_INFO = 1;
export const PEREFERENCES = 2;
export const MULTIPLE_ACCOUNTS = 3;

const tabItems = [
  { label: 'Business Info', value: BUSINESS_INFO },
  { label: 'Payment Info', value: PAYMENT_INFO },
  { label: 'Preferences', value: PEREFERENCES },
  { label: 'Linked Accounts', value: MULTIPLE_ACCOUNTS },
];

const AccountContainer = ({ company, member }: AccountContainerProps) => {
  const [tabIdx, setTabIdx] = useState(BUSINESS_INFO);
  const loggedInMember: UserState = useAppSelector(userSelector());

  const getActiveTabs = () => {
    if (loggedInMember.role === 'account-owner' || loggedInMember.role === 'global-manager') {
      return tabItems;
    }

    return tabItems.filter(i => i.value !== PAYMENT_INFO);
  };

  const activeTab = getActiveTabs()[tabIdx].value;

  return (
    <>
      <TabBar tabItems={getActiveTabs()} tabIndex={tabIdx} onTabChange={setTabIdx} />
      <Box maxWidth='838px' marginX='auto'>
        {activeTab === BUSINESS_INFO && <BusinessInfoContainer company={company} member={member} />}
        {activeTab === PAYMENT_INFO && <PaymentInfoContainer />}
        {activeTab === PEREFERENCES && <PreferencesContainer />}
        {activeTab === MULTIPLE_ACCOUNTS && <LinkedAccounts />}
      </Box>
    </>
  );
};

export default AccountContainer;
