import axios from 'axios';
import fromUnixTime from 'date-fns/fromUnixTime';
import isBefore from 'date-fns/isBefore';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { logger } from 'lib/logger';
import { Store } from 'redux';
import { Actions } from '../types/actions';

const AMAZON_AWS = 'amazonaws';

//UserClaims are setup in the pretoken generator in monorepo, /auth-service/src/triggers/cognito/pretoken-generation/operto/handler.ts
//It takes the userClaims from the S3 bucket and adds it to the token
interface UserClaims {
  memberId: string;
  companyId: string;
}

export interface CustomizedJwtPayload extends JwtPayload {
  //the following fields are needed by core side in php code
  mid?: string;
  cid?: string;

  //the following fields are attached from cognito
  product?: string;
  userClaims?: string;
}

//decode the token and check if it is expired
const auth = (store: Store) => {
  const { accessToken } = store.getState().user;
  if (accessToken && accessToken.access_token) {
    return true;
  }
  const authToken = localStorage.getItem('mpAuth');
  if (authToken) {
    try {
      const auth = JSON.parse(authToken);
      if (auth === '') return false;

      axios.defaults.headers.common.Authorization = `Bearer ${auth}`;
      const decode: CustomizedJwtPayload = jwtDecode(auth);

      if (decode.product?.includes('connect')) {
        parseUserClaims(decode);
      }

      if (isBefore(fromUnixTime(decode.exp), new Date())) {
        return false;
      }

      store.dispatch({
        type: Actions.storeToken,
        token: auth,
        memberId: decode.mid,
        companyId: decode.cid,
      });

      return true;
    } catch (error) {
      logger.error(error);
      return false;
    }
  }

  return false;
};

//check if the token's issuer is from cognito
export const isCognitoToken = (token: string) => {
  if (token === '') {
    return false;
  }
  const decode = jwtDecode<JwtPayload>(token);
  return decode.iss.includes(AMAZON_AWS);
};

export const parseUserClaims = (decoded: CustomizedJwtPayload) => {
  if (decoded.iss?.includes(AMAZON_AWS)) {
    const userClaims = decoded.userClaims;
    if (userClaims) {
      const userClaimsObj: UserClaims = JSON.parse(userClaims);
      if (userClaimsObj) {
        decoded.mid = userClaimsObj.memberId;
        decoded.cid = userClaimsObj.companyId;
      }
    }
  }
  return decoded;
};

export default auth;
