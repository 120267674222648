import { ChatBubbleOutlineOutlined, EmailOutlined } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import React from 'react';

export const EmailChannelIcon = ({ tooltip }: { tooltip?: string }) => {
  return (
    <Box sx={[containerStyles, emailStyles]}>
      <Tooltip title={tooltip}>
        <EmailOutlined fontSize='small' />
      </Tooltip>
    </Box>
  );
};

export const SmsChannelIcon = ({ tooltip }: { tooltip?: string }) => {
  return (
    <Box sx={[containerStyles, smsStyles]}>
      <Tooltip title={tooltip}>
        <ChatBubbleOutlineOutlined fontSize='small' />
      </Tooltip>
    </Box>
  );
};

const containerStyles = {
  borderRadius: '8px',
  padding: '10px',
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
};

const emailStyles = {
  backgroundColor: '#FFEA30',
};

const smsStyles = {
  backgroundColor: '#259C72',
  color: '#fff',
};
