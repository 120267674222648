import { Divider, MenuItem } from '@mui/material';
import { companySelector, messengerSettingSelector } from 'company/state/companySelectors';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useGuestPortal } from 'redux/guestportal';
import { useAppSelector } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';

interface ReservationActionMenuItemsProps {
  reservation?: IReservation | null;
  showEdit?: boolean;
  verifyOnClick?: () => void;
  verifyDisabled?: boolean;
  isVerificationProviderConfirmable?: boolean;
  disableManualOverride?: boolean;
  isPaymentSystemEnabled?: boolean;
  SDLabel?: string;
  disableManualSD?: boolean;
  accessCode?: string;
  reservationCodeStatus?: string;
  setOpenRegrant?: (arg: boolean) => void;
  setOpenRevoke?: (arg: boolean) => void;
  setOpenCancel?: (arg: boolean) => void;
  onReservationEditClick?: () => void;
  onSendGPLinkClick?: (reservation: IReservation, arg1: string) => void;
  onMessageClicked?: (reservation: IReservation) => void;
  onVerifyClick?: () => void;
  onManualConfirmClick?: () => void;
  onManualSecurityDepositClick?: () => void;
  onCopyClick?: (arg: string) => void;
}

const ReservationActionMenuItems = ({
  reservation,
  showEdit,
  verifyOnClick,
  verifyDisabled,
  isVerificationProviderConfirmable,
  disableManualOverride,
  isPaymentSystemEnabled,
  SDLabel,
  disableManualSD,
  accessCode,
  reservationCodeStatus,
  setOpenRegrant,
  setOpenRevoke,
  setOpenCancel,
  onReservationEditClick,
  onSendGPLinkClick,
  onMessageClicked,
  onVerifyClick,
  onManualConfirmClick,
  onManualSecurityDepositClick,
  onCopyClick,
}: ReservationActionMenuItemsProps) => {
  const { settings } = useGuestPortal();
  const guestPortalURL = process.env.REACT_APP_GUEST_PORTAL_URL;
  const messengerSetting = useAppSelector(messengerSettingSelector());
  const guestMessengerEnabled = messengerSetting.mp_guest_messenger_enabled;
  const company = useAppSelector(companySelector());

  return (
    <>
      {showEdit && (
        <MenuItem onClick={onReservationEditClick} disabled={reservation === null}>
          Edit Reservation
        </MenuItem>
      )}
      {reservationCodeStatus === 'Blocked' ? (
        <MenuItem
          onClick={() => {
            setOpenRegrant(true);
          }}
          disabled={reservation === null}
        >
          Regrant Reservation
        </MenuItem>
      ) : (
        <MenuItem
          onClick={() => {
            setOpenRevoke(true);
          }}
          disabled={reservation === null}
        >
          Revoke Reservation
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          setOpenCancel(true);
        }}
        disabled={reservation?.source !== 'dashboard'}
      >
        Cancel Reservation
      </MenuItem>
      <Divider variant='middle' />
      {company.guestPortalStatus == 'active' && (
        <MenuItem
          onClick={() => {
            onSendGPLinkClick(
              reservation,
              `${guestPortalURL}/${company.code}_${reservation?.external_id}/?r=true`,
            );
          }}
        >
          Send Guest Portal Link to Guest
        </MenuItem>
      )}
      {guestMessengerEnabled && (
        <MenuItem
          onClick={() => {
            onMessageClicked(reservation);
          }}
        >
          Send Message to Guest
        </MenuItem>
      )}
      {guestMessengerEnabled && <Divider variant='middle' />}
      {verifyOnClick && settings?.guestVerification && !company.smwEnabled && (
        <MenuItem onClick={onVerifyClick} disabled={!verifyDisabled}>
          {reservation?.incident_payment_verified ? 'Hide Access Code' : 'Show Access Code'}
        </MenuItem>
      )}
      {isVerificationProviderConfirmable && (
        <MenuItem onClick={onManualConfirmClick} disabled={disableManualOverride}>
          Manually Confirm Verification
        </MenuItem>
      )}
      {isPaymentSystemEnabled && (
        <MenuItem onClick={onManualSecurityDepositClick} disabled={disableManualSD}>
          {SDLabel}
        </MenuItem>
      )}
      <Divider variant='middle' />
      {!company.smwEnabled && accessCode && (
        <CopyToClipboard
          onCopy={() => {
            onCopyClick('Access Code');
          }}
          text={accessCode}
        >
          <MenuItem>Copy Access Code</MenuItem>
        </CopyToClipboard>
      )}
      {company.smwEnabled && (
        <CopyToClipboard
          onCopy={() => {
            onCopyClick('Deeplink');
          }}
          text={reservation?.deeplink_url}
        >
          <MenuItem disabled={!reservation?.deeplink_url || reservation?.deeplink_url === ''}>
            Copy Deeplink
          </MenuItem>
        </CopyToClipboard>
      )}
      {company.guestPortalStatus == 'active' && (
        <CopyToClipboard
          onCopy={() => {
            onCopyClick('Guest Portal Link');
          }}
          text={`${guestPortalURL}/${company.id}_${reservation?.external_id}/?r=true`}
        >
          <MenuItem>Copy Guest Portal Link</MenuItem>
        </CopyToClipboard>
      )}
    </>
  );
};

export default ReservationActionMenuItems;
