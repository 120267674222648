import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getSnackBar } from 'shared/state/sharedSelectors';
import { SnackbarTypes } from 'types/ui';

const GlobalSnackBar = () => {
  const dispatch = useAppDispatch();
  const snackbarState = useAppSelector(getSnackBar);

  const snackbarAction: SnackbarTypes = Object.keys(snackbarState)[0] as SnackbarTypes;
  const snackbarMessage = snackbarState[snackbarAction]?.message;
  const snackbarAnchors = snackbarState[snackbarAction]?.anchorOrigin;
  const snackbarIcon = snackbarState[snackbarAction]?.icon;
  const snackbarRootStyles = snackbarState[snackbarAction]?.rootStyles;

  const [prevSnackbarState, setPrevSnackbarState] = React.useState(null);
  React.useEffect(() => {
    if (snackbarAction !== SnackbarTypes.CLOSE)
      setPrevSnackbarState({
        message: snackbarState[snackbarAction]?.message,
        variant: snackbarState[snackbarAction]?.variant,
        anchorOrigin: snackbarState[snackbarAction]?.anchorOrigin,
        icon: snackbarState[snackbarAction]?.icon,
        rootStyles: snackbarState[snackbarAction]?.rootStyles,
      });
  }, [snackbarAction, snackbarState]);

  const handleClose = () => {
    dispatch(toggleSnackbar(SnackbarTypes.CLOSE, prevSnackbarState));
  };
  const vertical = snackbarAnchors?.vertical ?? 'top';
  const horizontal = snackbarAnchors?.horizontal ?? 'center';
  return (
    <Snackbar
      open={snackbarAction === SnackbarTypes.OPEN}
      sx={{ width: isMobile ? '100%' : '75%', ...snackbarRootStyles }}
      anchorOrigin={{ vertical, horizontal }}
      onClose={handleClose}
      autoHideDuration={6000}
    >
      <Alert
        sx={{ color: 'white' }}
        severity={snackbarState[snackbarAction]?.variant}
        onClose={handleClose}
        elevation={6}
        variant='filled'
        icon={snackbarIcon}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default GlobalSnackBar;
