import { Link, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { sortLanguages } from 'helper/helper';
import { ChannelType, CommunicationType } from 'hooks/useCommunications';
import { languages } from 'hooks/useTranslation';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TableCell } from 'ui-library/Components/table/TableCell';
import { EmailChannelIcon, SmsChannelIcon } from './CommunicationsIcons';
import CommunicationsTableAction from './CommunicationsTableAction';

export default function useCommunicationsColumns(communications: CommunicationType[]) {
  const navigate = useNavigate();

  const handleLanguageClick = (
    selectedTemplate: string,
    selectedLanguage: string,
    channelType: ChannelType,
  ) => {
    navigate(
      '/communications/edit/' + channelType + '/' + selectedTemplate + '/' + selectedLanguage,
    );
  };

  const handleTemplateClick = (selectedTemplate: string, channelType: ChannelType) => {
    navigate('/communications/edit/' + channelType + '/' + selectedTemplate);
  };

  const renderTemplateName = ({ row }: GridRenderCellParams<unknown, CommunicationType>) => {
    return (
      <TableCell
        title={
          <Link
            onClick={() => handleTemplateClick(row.id, row.channelType)}
            sx={linkStyles}
            underline='none'
          >
            <Typography variant='body-sm-600'>{row.name}</Typography>
          </Link>
        }
      />
    );
  };

  const renderTemplateChannel = ({ row }: GridRenderCellParams<unknown, CommunicationType>) => {
    return (
      <TableCell
        title={
          row.channelType === 'email' ? (
            <EmailChannelIcon tooltip='Email' />
          ) : (
            <SmsChannelIcon tooltip='SMS' />
          )
        }
      />
    );
  };

  const renderTemplateLanguages = ({ row }: GridRenderCellParams<unknown, CommunicationType>) => {
    const sortedLocales = sortLanguages(row.locales);

    return (
      <TableCell
        flexDirection='row'
        title={sortedLocales.map((language, i) => (
          <Link
            key={row.id + language}
            onClick={() => handleLanguageClick(row.id, language, row.channelType)}
            sx={[linkStyles, languageStyles]}
            underline='none'
          >
            <Tooltip title={languages.find(l => l.locale === language)?.name}>
              <Typography variant='body-sm-600'>{`${i !== 0 ? ', ' : ''}${language}`}</Typography>
            </Tooltip>
          </Link>
        ))}
      />
    );
  };

  const renderTemplateActions = ({ row }: GridRenderCellParams<unknown, CommunicationType>) => {
    return (
      <TableCell
        title={
          <CommunicationsTableAction
            templateId={row.id}
            channelType={row.channelType}
            communications={communications}
          />
        }
      />
    );
  };

  return [
    {
      field: 'name',
      headerName: 'Template',
      flex: 1,
      sortable: true,
      renderCell: renderTemplateName,
    },
    {
      field: 'channel',
      headerName: 'Channel',
      flex: 1,
      sortable: true,
      renderCell: renderTemplateChannel,
    },
    {
      field: 'languages',
      headerName: 'Languages',
      flex: 1,
      sortable: true,
      renderCell: renderTemplateLanguages,
    },
    {
      field: 'action',
      headerName: '',
      renderCell: renderTemplateActions,
      type: 'number',
      sortable: false,
      resizable: false,
    },
  ];
}

const linkStyles = {
  cursor: 'pointer',
};

const languageStyles = {
  textTransform: 'uppercase',
};
