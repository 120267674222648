import LoadingButton from '@mui/lab/LoadingButton';
import { SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export interface ConfirmDialogProps {
  open: boolean;
  loading?: boolean;
  title?: string | React.ReactNode;
  children: React.ReactNode;
  submitButtonText?: string;
  submitButtonColor?: 'primary' | 'error';
  disabled?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  contentStyles?: SxProps;
  actionsStyles?: SxProps;
  rootStyles?: SxProps;
}

export const ConfirmDialog = ({
  onClose,
  open,
  loading,
  onSubmit,
  title,
  children,
  submitButtonText = 'CONFIRM',
  submitButtonColor = 'primary',
  disabled,
  contentStyles,
  actionsStyles,
  rootStyles,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog maxWidth='md' open={open} fullWidth sx={rootStyles}>
      {title ? typeof title === 'string' ? <DialogTitle>{title}</DialogTitle> : title : null}
      <DialogContent sx={contentStyles}>{children}</DialogContent>
      <DialogActions sx={{ padding: 2, ...actionsStyles }}>
        <Button data-testid='modal-cancel-btn' onClick={onClose}>
          {t('cancel')}
        </Button>
        <LoadingButton
          data-testid='modal-confirm-btn'
          autoFocus
          variant='contained'
          onClick={onSubmit}
          color={submitButtonColor}
          disabled={disabled}
          loading={loading}
        >
          {submitButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
