const getReleaseStage = () => {
  if (process.env.REACT_APP_GIT_BRANCH === 'main') {
    return 'production';
  }

  if (process.env.REACT_APP_GIT_BRANCH?.startsWith('release')) {
    return 'staging';
  }

  if (process.env.JEST_WORKER_ID !== undefined) {
    return 'test';
  }

  return 'development';
};

export const RELEASE_STAGE = getReleaseStage();
export const isProduction = RELEASE_STAGE === 'production';
export const isStaging = RELEASE_STAGE === 'staging';
export const isDevelopment = RELEASE_STAGE === 'development';
export const isTesting = RELEASE_STAGE === 'test';
