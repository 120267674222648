import { Button, Grid } from '@mui/material';
import React from 'react';

const TimeButton = ({ time }: { time: string }) => (
  <Grid item xs={4}>
    <Button variant='outlined' sx={{ color: 'black' }} fullWidth>
      {time}
    </Button>
  </Grid>
);

export default TimeButton;
