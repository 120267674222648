import { Box, SxProps, Tab, Tabs, Theme } from '@mui/material';
import { Text, useMedia } from '@operto/ui';
import React from 'react';
import DropDownMenu from './components/DropDownMenu';

export type TabItems = {
  label: string;
  value?: string | number;
  isDropDown?: boolean;
  childItems?: { label: string; value: string | number }[];
  icon?: React.ReactNode;
};

export type TabBarProps = {
  tabItems: TabItems[];
  tabIndex: number;
  onTabChange: (value: number) => void;
  onSubTabChange?: (value: string | number) => void;
  active?: boolean;
  tabItemsStyle?: SxProps<Theme>;
};

const TabBar = ({
  tabItems,
  onTabChange,
  onSubTabChange,
  tabIndex,
  active = true,
  tabItemsStyle,
}: TabBarProps) => {
  const { isMobile } = useMedia();
  const combinedTabItemsStyle: SxProps = { ...tabStyles(isMobile), ...tabItemsStyle };

  return (
    <Tabs
      sx={tabsStyles}
      value={tabIndex}
      onChange={(e, value) => onTabChange(value)}
      disabled
      variant='scrollable'
      scrollButtons='auto'
    >
      {tabItems.map((item: TabItems, index: number) => {
        if (item.isDropDown) {
          return (
            <DropDownMenu
              key={item.label}
              tabItemsStyle={combinedTabItemsStyle}
              data-testid={item.label}
              item={item}
              handleClick={onSubTabChange}
              setTabIndex={() => onTabChange(index)}
              active={active}
            />
          );
        }

        return (
          <Tab
            sx={combinedTabItemsStyle}
            data-testid={item.label}
            key={item.label}
            label={<TabLabel item={item} />}
            onClick={() => onTabChange(index)}
            disabled={!active}
          />
        );
      })}
    </Tabs>
  );
};

export const TabLabel = ({ item }: { item: TabItems }) => {
  return (
    <Box sx={labelStyles}>
      {item.icon}
      <Text variant='subtitle-sm-600' style={{ marginLeft: 0.5 }}>
        {item.label}
      </Text>
    </Box>
  );
};

export default TabBar;

const labelStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
};

const tabStyles = (isMobile: boolean): SxProps => ({
  textTransform: 'capitalize',
  minWidth: '160px',
  width: isMobile ? '50%' : 'auto',
});

const tabsStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 1,
  borderBottom: '1px solid',
  borderBottomColor: 'border',
};
