import { Stack } from '@mui/material';
import React from 'react';
import Cloudbeds from './Cloudbeds/Cloudbeds';
import GuestyIntegrationsCard from './Guesty/GuestyIntegrationsCard';
import { IntegrationsSections } from './IntegrationsSections';
import { StripeIntegrationCard } from './Stripe/StripeIntegrationCard';
import { YourKeyPlusIntegrationCard } from './YourKeyPlus/YourKeyPlusIntegrationCard';

const IntegrationsPage = () => {
  return (
    <Stack p={3}>
      <IntegrationsSections title='PMS'>
        <Cloudbeds />
        <GuestyIntegrationsCard />
      </IntegrationsSections>

      <IntegrationsSections title='Payment'>
        <StripeIntegrationCard />
      </IntegrationsSections>
      <IntegrationsSections title='Access'>
        <YourKeyPlusIntegrationCard />
      </IntegrationsSections>
    </Stack>
  );
};

export default IntegrationsPage;
